import React from 'react';
import { jsPDF } from 'jspdf';
import { defaultRequest } from '../../utils/request/request';
import 'jspdf-autotable';
import { formatPhoneNumber } from '../../utils/format';
import mini_logo from "../../data/login/af_acom_logo_versao_principal.png";
import dayjs from 'dayjs';

class LossesReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            company: {},
            defaultCompany: props.user.data.empresas.find(item => item.cd_empresa === props.user.data.cd_empresa)
        }
    }

    componentWillMount() {
        this.searchInfos()
    }

    generatePDF = () => {
        console.log(this.props)
        const { columns, rows, allInputs, inputColumns } = this.props
        const now = dayjs()

        const doc = new jsPDF({
            orientation: "landscape", // Define a folha como paisagem
            unit: "mm", // Unidade em milímetros
            format: "a4", // Formato A4
        })

        const pageWidth = doc.internal.pageSize.getWidth()

        const borderColor = "#000000"; // Cor do border
        const padding = 5; // Espaçamento interno
        const startX = 10; // Posição inicial X
        const startY = 10; // Posição inicial Y
        const width = pageWidth - 20; // Largura do cabeçalho
        const titleHeight = 10; // Altura do título
        const contentHeight = 30; // Altura da área de informações

        // Desenha a borda externa do cabeçalho
        doc.setDrawColor(borderColor);
        doc.rect(startX, startY, width, titleHeight + contentHeight);

        // Adiciona o título centralizado
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("CONTROLE DE ENTRADAS", pageWidth / 2, 9, { align: "center" });

        // Adiciona a logo no canto esquerdo
        const imageX = 12, imageY = 20, imageWidth = 40, imageHeight = 15;
        doc.addImage(this.state.defaultCompany.im_cadastro ? 'data:image/png;base64,' + this.state.defaultCompany.im_cadastro : mini_logo, 'PNG', imageX, imageY, imageWidth, imageHeight);
        
        // Adiciona os campos da esquerda
        const infoStartX = 55
        const infoStartY = 20
        doc.setFontSize(14);
        doc.text(this.state.company.fantasia, infoStartX, infoStartY);
        doc.setFontSize(10);
        doc.text(this.state.company.endereco + ', ' + this.state.company.nr_endereco, infoStartX, infoStartY + 5);
        doc.text(this.state.company.bairro + (this.state.company.cidade_estado ? ' - ' + this.state.company.cidade_estado : ''), infoStartX, infoStartY + 10);
        doc.text('Fone: ' + formatPhoneNumber(this.state.company.nr_fone) + ' - CEP: ' + this.state.company.cep, infoStartX, infoStartY + 15);
        doc.text('CPF/CNPJ: ' + this.state.company.cpf_cnpj + ' - IE: ' + this.state.company.inscricao_estadual, infoStartX, infoStartY + 20);

        // Adiciona os campos do meio
        doc.setFont("helvetica", "normal");

        doc.text("Depósito:", pageWidth - 160, infoStartY + 10);
        doc.text(this.props.depositList.find(item => item.cd_deposito === this.props.deposit).ds_deposito, pageWidth - 90, infoStartY + 10, { align: "right" });

        doc.text("Tipo Movimento:", pageWidth - 160, infoStartY + 15);
        doc.text(this.props.lossTypeList.find(item => item.id_movimentoestoquetipo === this.props.lossType).ds_movimentoestoquetipo, pageWidth - 90, infoStartY + 15, { align: "right" });

        // Adiciona os campos à direita
        doc.setFontSize(10);
        doc.text("Nº:", pageWidth - 70, infoStartY + 5);
        doc.setFont("helvetica", "bold");
        doc.text(this.props.documentNumber.toString(), pageWidth - 25,  infoStartY + 5, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text("Nr. Movimento:", pageWidth - 70, infoStartY + 10);
        doc.text(this.props.lossType.toString(), pageWidth - 25, infoStartY + 10, { align: "right" });

        doc.text("Dt. Lançamento:", pageWidth - 70, infoStartY + 15);
        doc.text(now.format('DD/MM/YYYY'), pageWidth - 25, infoStartY + 15, { align: "right" });

        const tableStartY = startY + titleHeight + contentHeight + 10

        const Columns = columns.map(col => ({  // Definindo as colunas dinamicamente com base nas props
            header: col[1], // Nome da coluna (segundo item)
            dataKey: col[0], // Nome do campo de dados (primeiro item)
        }))

        const InputColumns = inputColumns.map(col => ({  // Definindo as colunas dinamicamente com base nas props
            header: col[1], // Nome da coluna (segundo item)
            dataKey: col[0], // Nome do campo de dados (primeiro item)
        }))
        
        const processRows = (doc, rows, startY, indent = 0, main_item = true) => {
            let currentY = startY;
    
            rows.forEach(row => {
                console.log('------------------------')
                console.log(main_item)
                console.log(row)
                const rowData = {};
                if (main_item) {
                    columns.forEach(col => {
                        console.log(col)
                        rowData[col[0]] = row[col[0]]
                    });
                    console.log(columns)
                } else {
                    inputColumns.forEach(col => {
                        console.log(col)
                        rowData[col[0]] = row[col[0]]
                    });
                    console.log(inputColumns)
                }
                console.log(rowData)
                
                doc.autoTable({
                    startY: currentY,
                    margin: { left: 10 + indent }, // Ajusta o recuo horizontal com base no nível
                    head: main_item ? [Columns.map(col => col.header)] : [InputColumns.map(col => col.header)],
                    body: main_item ? [Columns.map(col => rowData[col.dataKey])] : [InputColumns.map(col => rowData[col.dataKey])],
                    headStyles: {
                        fillColor: false,
                        textColor: 0,
                        lineWidth: 0.5,
                        lineColor: 0,
                    },
                    bodyStyles: {
                        fillColor: [240, 240, 240],
                        textColor: 0,
                        lineWidth: 0.5,
                        lineColor: 0,
                    },
                    styles: {
                        cellPadding: 2,
                        fontSize: 10,
                        valign: 'middle',
                        halign: 'center',
                        lineWidth: 0.5,
                        lineColor: 0,
                    },
                });
    
                // Atualiza a posição vertical após a tabela
                currentY = doc.lastAutoTable.finalY + 2; // Adiciona espaçamento entre as tabelas
                
                // Processa os subItens, se existirem
                if (main_item && row.id_item in allInputs) {
                    currentY = processRows(doc, allInputs[row.id_item], currentY, indent + 10, false);
                } else if (row.subItens && Array.isArray(row.subItens)) {
                    currentY = processRows(doc, row.subItens, currentY, indent + 10, false);
                }
            });
    
            return currentY; // Retorna a última posição Y usada
        };
    
        processRows(doc, rows, tableStartY);

        doc.save('relatorio.pdf')   // Salva o PDF com o nome 'relatorio.pdf'
    }

    searchInfos = async () => {
        let config = {
            endpoint: 'sis/empresa/' + this.props.company,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        const r = await defaultRequest(config, form)
        var company = {}
        if (r.status) {
            company = r.data
        }

        if (company.cd_municipio_ibge) {
            const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${r.data.cd_municipio_ibge}`)
            const data = await response.json()
            company.cidade_estado = data?.['regiao-imediata']?.['regiao-intermediaria']?.nome + '/' + data?.['regiao-imediata']?.['regiao-intermediaria']?.UF?.sigla
        }

        this.setState({ isLoading: false, company: company })
    }

    render() {
        const { columns, rows, onClose } = this.props

        return (
            <div style={styles.overlayStyles}>
                <div style={styles.reportContainerStyles}>

                    {!this.state.isLoading ?
                        <>
                            {/* Tabela */}
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        {columns.map(([field, label]) => (
                                            <th key={field} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                                                {label}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {columns.map(([field]) => (
                                                <td key={field} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                    {field === 'dt_entrega' ?
                                                        row[field].format('DD/MM/YYYY')
                                                    : 
                                                        row[field]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <button onClick={this.generatePDF} style={styles.generateButtonStyles(this.props)}>
                                    Gerar PDF
                                </button>
                                <button onClick={onClose} style={styles.closeButtonStyles(this.props)}>
                                    Fechar
                                </button>
                            </div>
                        </>
                        : <></>}

                </div>
            </div>
        )
    }
}

export default LossesReport;

const styles = {
    overlayStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    reportContainerStyles: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '90%',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    closeButtonStyles: (props) => ({
        backgroundColor: props.colors.redAccent[500],
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '5px',
    }),
    generateButtonStyles: (props) => ({
        backgroundColor: props.colors.greenAccent[500],
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '5px',
    })
}