import React, { forwardRef } from "react";

import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, Select, TextField, Typography } from "@mui/material";


class MainSelectComboField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            variantName: [],
            searchText: ''
        }
        this.textFieldRef = React.createRef()
    }

    handleChange = (event) => {
        const { optionsList } = this.props
        let newVariantName = []

        if (event.target.value[event.target.value.length - 1] === "selectAll") {
            newVariantName = this.props.value.length === optionsList.length ? [] : [...optionsList]
        } else {

            const selectedItem = event.target.value[event.target.value.length - 1]
            if (this.props.value.some(item => item.value === selectedItem.value)) {
                newVariantName = this.props.value.filter(item => item.value !== selectedItem.value)
            } else {
                newVariantName = [...this.props.value, selectedItem]
            }
        }
        this.props.handleChange({
            target: {
                id: this.props.id,
                value: newVariantName
            }
        })
    }

    handleClose = () => {
        this.setState({ searchText: '' })
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.props.delayFunction(this.state.searchText)
        }
    }

    handleSearchChange = (event) => {
        this.setState({ searchText: event.target.value })
    }

    searchValue = () => {
        if (this.state.searchText !== '' && this.props.delayFunction) {
            this.props.delayFunction(this.state.searchText)
        } else {
            return
        }
    }

    handleSelectFocus = () => {
        if (this.textFieldRef.current) {
            this.textFieldRef.current.focus()
        }
    }


    render() {
        const isAllSelected = this.props.value.length === this.props.optionsList.length && this.props.value.length > 0
        const filteredOptions = this.props.optionsList.filter(option =>
            option.label && option.label.toLowerCase().includes(this.state.searchText.toLowerCase())
        )

        return (
            <Box sx={{ width: this.props.width ? this.props.width : '100%' }}>
                <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {this.props.required
                        ? <>{this.props.label}<span style={{ color: this.props.colors.redAccent[500] }}> *</span></> ?? ''
                        : this.props.label ?? ''
                    }
                </Typography>

                <FormControl
                    className="main-select-combo-field"
                    disabled={this.props.disabled ?? false}
                    onFocus={this.handleSelectFocus}
                    size={this.props.size ?? 'small'}
                    sx={{
                        '& .MuiFormLabel-root': {
                            display: this.props.value.length > 0 ? "none" : 'flow'
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                            display: 'none'
                        },
                        width: this.props.width ? this.props.width : '100%',
                        ...this.props.sx,
                    }}
                >
                    <InputLabel sx={{ '&.Mui-focused': { display: 'none' } }}>
                        {this.props.placeholder ?? ''}
                    </InputLabel>

                    <Select
                        multiple
                        inputRef={this.props.innerRef}
                        value={this.props.value}
                        onChange={this.handleChange}
                        onClose={this.handleClose}
                        renderValue={(selected) => selected.map((item) => item.label).join(', ')}
                        MenuProps={{
                            PaperProps: {
                                style: { maxHeight: 100 * 4 },
                            },
                        }}
                    >
                        {/* TextField de pesquisa */}
                        <ListSubheader sx={{ display: 'flex' }}>
                            <TextField
                                inputRef={this.textFieldRef}
                                placeholder="Procurar..."
                                fullWidth
                                variant="standard"
                                value={this.state.searchText}
                                onChange={this.handleSearchChange}
                                onKeyUp={this.props.delayFunction ? this.handleEnterKeyUp : null}
                                onKeyDown={e => e.stopPropagation()}
                            />

                            {this.props.delayFunction &&
                                (
                                    <Button sx={{ position: 'absolute', right: '15px' }} onClick={this.searchValue}>
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </Button>
                                )}

                        </ListSubheader>

                        <MenuItem value="selectAll">
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={this.props.value.length > 0 && !isAllSelected}
                            />
                            <ListItemText primary="Selecionar Tudo..." />
                        </MenuItem>

                        {filteredOptions.map(option => (
                            <MenuItem key={option.value} value={option}>
                                <Checkbox
                                    checked={
                                        this.props.value.findIndex(item => item.value === option.value) >= 0
                                    }
                                />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    }
}

const ForwardedMainSelectComboField = forwardRef((props, ref) => {
    return <MainSelectComboField {...props} innerRef={ref} />
})

export default ForwardedMainSelectComboField;