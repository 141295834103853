import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, keyframes, Typography } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { getDeposit, getMovementType, getUser } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class ItemsMovement extends React.Component {
    constructor(props) {
        super(props)

        const { t } = this.props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_Movimento',
            isLoading: true,
            isLoadingTable: false,
            isCloseOpen: false,

            isEditingTableItem: false,
            isDestinyEnabled: false,
            isDestinyAccordionEnabled: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['qt_item', t('Q. na Embalagem')],
                ['qt_quantidade', t('Quantidade')],         // digitado na tabela
                ['vl_ultima_compra', t('V. Embalagem')],
                ['vl_total', t('V. Total')],                // novo = qt_item * vl_ultima_compra
                ['qt_estoque', t('Q. Estoque')],          // novo = qt_solicitada (* ou /) qt_item
                ['vl_unitario', t('V. Unitário')],
                ['qt_saldo', t('Saldo Dispo.')],
                ['ds_embalagempadrao', t('Emb. Padrão')],
            ],
            tableTotalSize: 0,

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            responsible: this.props.user.data.cd_usuario,
            releaseDate: dayjs(),
            serviceDate: dayjs(),
            depositList: [],
            seriesList: [],
            userList: [],
            movementTypeList: [],
            movementTypeDestinyList: [],

            item: '',
            itemsList: [],

            itemPacking: '',
            itemPackagingList: [],

            releaseDateDestiny: dayjs(),

            status: 1,
            statusOptions: [
                { 'label': t('Ativo'), 'value': 1 },
                { 'label': t('Pendente'), 'value': 11 },
            ],

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            tableList: this.props.newTableList.map((item, index) => ({
                ...item,
                id_linha: index + 1, // Adiciona um id único para cada objeto
                ds_embalagempadrao: item.ds_embalagem,
            }))
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.item !== prevState.item) {
            this.state.item
                ? this.getItemsPackaging()
                : this.setState({ itemPackagingList: [], itemPacking: '', um: '', qtdRequested: '' })
        }
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }
    }

    calculateLastPurchaseValue = async () => {
        this.setState({ isIncludeDisabled: true })

        const item = this.state.itemsList.find(item => item.id_item === this.state.item)

        let config = {
            endpoint: `componente/valorultimacompra/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_deposito': this.props.deposit,
            'dt_selecionada': dayjs().format('YYYY-MM-YY'),
            'id_item': this.state.item,
            'id_embalagem': this.state.itemPacking,
            'id_embalagem_padrao': item.id_embalagempadrao,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data) {
                this.setState({ isIncludeDisabled: false })

                return r.data.vl_ultima_cpa
            } else {
                this.setState({ isIncludeDisabled: false })

                return 0
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return 0
        }
    }

    calculateTable = () => {
        const updateTable = this.state.tableList.map(item => {
            const operation = item.at_operacao

            const qtEmb = item.qt_item || 0
            const qtDigitado = item.qt_quantidade || 0
            const vlUnitario = item.vl_unitario || 0

            const qtEstoque = operation === 228 ? qtDigitado * qtEmb : qtDigitado / qtEmb || 0

            const vlTotal = vlUnitario * qtDigitado || 0

            return { ...item, qt_estoque: qtEstoque, vl_total: vlTotal }
        })

        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length })
            }, 100)
        }
    }

    checkButtonIsDisabled = () => {
        if (!this.state.item || !this.state.um || !this.state.itemPacking || !this.state.qtdRequested) {
            return true
        }
        return false
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const userData = getUser(this.props.user.entidade)
            const movementData = getMovementType(this.props.user.entidade)
            const seriesData = this.fetchSeries()

            const [depositList, seriesList, userList, movementTypeList] = await Promise.all([depositData, seriesData, userData, movementData])

            this.setState({
                depositList,
                seriesList,
                userList,
                movementTypeList,
                movementTypeDestinyList: movementTypeList,
                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                seriesList: [],
                userList: [],
                movementTypeList: [],
                movementTypeDestinyList: [],

                isLoading: false
            })
        }
    }

    fetchSeries = async () => {
        let config = {
            endpoint: `sis/usuariormi/usuario/${this.props.user.data.cd_usuario}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data) {
                if (Object.keys(r.data).length === 0 && r.data.constructor === Object) {
                    return []
                } else {
                    return r.data
                }
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar a série:', error)
            return []
        }
    }

    getItems = (item) => {
        const { t } = this.props
        let config = {
            endpoint: `componente/item/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'tx_busca': item,
            'x-Pagina': 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemsList: r.data
                })
            } else {
                this.setState({
                    itemsList: [],

                    alertMessage: t('Nenhum dado retornado'),
                    alertType: 'warning',
                    showAlert: true,
                })
            }
        })
    }

    getItemsPackaging = (page = 0) => {
        let config = {
            endpoint: `componente/itemembalagem/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'id_item': this.state.item,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemPacking: '',
                    itemPackagingList: r.data,
                })
            } else {
                this.setState({
                    itemPackagingList: []
                })
            }
        })
    }

    handleChangeMovementType = (event) => {
        const { id, value } = event.target

        const movement = this.state.movementTypeList.find(item => item.id_movimentoestoquetipo === value)
        this.setState({
            [id]: value,
            movement: movement.at_movimentacao === 142 ? 'Entrada' : 'Saída',
            movementDestiny: movement.at_movimentacao === 142 ? 'Saída' : 'Entrada',
            isDestinyEnabled: movement.fl_tranferencia_empresas === 'S' ? true : false,
            isDestinyAccordionEnabled: movement.fl_tranferencia_empresas === 'S' ? true : false,
            movementTypeDestinyList: this.state.movementTypeList.filter(item => item.at_movimentacao === (movement.at_movimentacao === 142 ? 143 : 142))
        })
    }

    handleChangePacking = (event) => {
        const itemPackingData = this.state.itemPackagingList.find(item => item.id_embalagem === event.target.value) || []

        const um = itemPackingData.sg_unidademedida || ''

        if (event.target.value) {
            this.setState({ [event.target.id]: event.target.value, um: um })
        } else {
            this.setState({ [event.target.id]: event.target.value, um: '' })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.includeItem()
        }
    }

    includeItem = async () => {
        const item = this.state.itemsList.find(item => item.id_item === this.state.item)
        const packing = this.state.itemPackagingList.find(itemPacking => itemPacking.id_embalagem === this.state.itemPacking)

        const lastPurchaseValue = await this.calculateLastPurchaseValue()

        if (item && packing) {
            const newItem = {
                id_linha: this.state.tableList.length + 1,  // cria um id único para poder adicionar duas ou + vezes o mesmo item
                id_item: item.id_item,
                cd_item: item.cd_item,
                ds_item: item.ds_item,
                id_embalagem: packing.id_embalagem,
                ds_embalagem: packing.ds_embalagem,
                sg_unidademedida: packing.sg_unidademedida,
                qt_item: packing.qt_item,
                qt_quantidade: this.state.qtdRequested,
                vl_ultima_compra: lastPurchaseValue,                // vem do Request
                vl_unitario: item.vl_unitario,                      // vem do Item
                vl_total: 0,                                        // Calculado
                qt_estoque: 0,                                      // Calculado
                qt_saldo: item.qt_saldo,                            // vem da Embalagem
                ds_embalagempadrao: item.ds_embalagempadrao,        // vem do Item
                at_operacao: packing.at_operacao,                   // vem da Embalagem
            }

            const itemExists = this.state.tableList.some(tableItem =>   // se já existir o mesmo item com a mesma embalagem na tabela
                tableItem.id_item === newItem.id_item && tableItem.id_embalagem === newItem.id_embalagem
            )

            if (itemExists) {
                this.showAlert('Já existe um registro para este item e embalagem na tabela.', 'error')
            } else {
                this.setState(prevState => ({
                    tableList: [...prevState.tableList, newItem],
                    item: '',
                    itemPacking: '',
                    qtdRequested: '',

                    itemsList: [],
                    itemPackagingList: [],
                }))
                this.showAlert('Item adicionado com sucesso.', 'success')
            }
        }
    }

    sendMovement = async () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        const { series, movementType, responsible, releaseDate, isDestinyEnabled, movementTypeDestiny, releaseDateDestiny, companyDestiny, depositDestiny } = this.state

        if (!series || !releaseDate || !movementType || !responsible) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        if (isDestinyEnabled && (!movementTypeDestiny || !releaseDateDestiny || !companyDestiny || !depositDestiny)) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = this.state.tableList.some(item => !('qt_quantidade' in item) || item.qt_quantidade === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let response = await checkSendDate(this.state.releaseDate, this.state.company, this.props.user.entidade)

        if (!response || !response.status) {
            this.setState({
                alertMessage: response.message ?? '',
                alertType: response.msg_type === 1 ? 'error' : 'warning',
                showAlert: true,

                isLoading: false,
            })
            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: `adm/movimentoestoque?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_serie': this.state.series,
            'dt_movimento': this.state.releaseDate,
            'id_movimentoestoquetipo_origem': this.state.movementType,
            'cd_deposito_origem': this.state.deposit,
            'id_movimentoestoquetipo_destino': this.state.movementTypeDestiny,
            'cd_deposito_destino': this.state.depositDestiny,
            'observacao': this.state.observation,
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_usuario_responsavel': this.state.responsible,
            'at_situacao': 1,
            'cd_empresa_destino': this.state.companyDestiny,
            'dt_movimento_destino': this.state.releaseDateDestiny,
            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map((item, index) => {
                    return {
                        'id_item': item.id_item,
                        'qt_embalagem': item.qt_quantidade,
                        'vl_embalagem_unitario': item.vl_ultima_compra,
                        'vl_convertido_unitario': item.vl_total,
                        'at_movimentacao': this.state.movementTypeList.find(item => item.id_movimentoestoquetipo === this.state.movementType).at_movimentacao,
                        'fl_movimento_custo': this.state.movementTypeList.find(item => item.id_movimentoestoquetipo === this.state.movementType).fl_movimento_custo,
                        'vl_embalagem_total': item.vl_total
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isDisabled: true,
                    isLoading: false,
                    isConfirmationOpen: true,
                    request: r.data.id_movimentoestoque,
                    documentNumber: r.data.nr_documento,
                    documentNumberDestiny: r.data.nr_documentodestino,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }


    render() {
        const { t } = this.props
        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendMovement}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Nr. Documento')}: ${this.state.documentNumber}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isItemsMovementVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Movimentação de Itens')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>
                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={`${t('Itens')}:`}
                                        content={
                                            <>

                                                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '100%', lg: '15%' } }}>
                                                    <MainButton
                                                        {...this.props}
                                                        sx={{ background: this.state.isIncludeItemTableVisible ? this.props.colors.grey[900] : this.props.colors.blueAccent[100] }}
                                                        title={t('Adicionar Item')}
                                                        onButtonClick={() => this.setState({ isIncludeItemTableVisible: true })}
                                                    />
                                                </Box>

                                                {this.state.isIncludeItemTableVisible &&
                                                    (
                                                        <Box sx={{ padding: '10px 30px', border: `1px solid ${this.props.colors.orangeAccent[800]}`, margin: '20px 0', borderRadius: '10px' }}>

                                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Adicionar Item')}:</Typography>

                                                                <Divider sx={{ flexGrow: 1, margin: '0 0 0 20px' }} />

                                                                <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isIncludeItemTableVisible: false })}>
                                                                    <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                                                </Button>
                                                            </Box>

                                                            {/* Campos de adição */}
                                                            <Box sx={{ display: 'grid', gap: '0', gridTemplateColumns: { xs: '100%', lg: '30% 10% 30% 15% 15%' } }}>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '20px 0 0 20px' }}>{t('Descrição do Item')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='item' value={this.state.item} placeholder={t('[digite para buscar as opções...]')} optionsList={this.state.itemsList.map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))} handleChange={this.handleChangeText} delayFunction={this.getItems} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('UM')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='um' value={this.state.um} placeholder={t('[unidade]')} handleChange={this.handleChangeText} disabled={true} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('Embalagem')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='itemPacking' value={this.state.itemPacking} placeholder={t('[embalagem...]')} optionsList={this.state.itemPackagingList.map(item => ({ label: item.ds_embalagem, value: item.id_embalagem }))} handleChange={this.handleChangePacking} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '0 20px 20px 0' }}>{t('Quantidade')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdRequested' value={this.state.qtdRequested} placeholder={t('[0,0000]')} handleChange={this.handleChangeText} type='number' maxDecimals={4} onKeyUp={this.handleEnterKeyUp} />
                                                                </Box>
                                                                <MainButton sx={styles.AddItemMainButton(this.props)} {...this.props} title={t('Inserir')} onButtonClick={this.includeItem} disabled={this.checkButtonIsDisabled() || this.state.isIncludeDisabled} />
                                                            </Box>

                                                            <Divider sx={{ flexGrow: 1, marginTop: '5px' }} />
                                                        </Box>
                                                    )
                                                }

                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    allowEditOnRow
                                                    id='table_items_movement'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    rowId='id_linha'
                                                    totalSize={this.state.tableTotalSize}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    isLoading={this.state.isLoadingTable}
                                                    onEditRow={this.handleEditRow}
                                                    editableFields={['qt_quantidade']}
                                                    extraColumnsConfig={
                                                        {
                                                            'qt_item': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_quantidade': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_ultima_compra': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_total': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_unitario': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_saldo': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                        }
                                                    }
                                                    customRowSize={
                                                        {
                                                            'cd_item': 80,
                                                            'ds_item': 210,
                                                            'sg_unidademedida': 60,
                                                            'qt_item': 130,
                                                        }
                                                    }
                                                />
                                            </>
                                        }
                                    />

                                    {/* Origem */}
                                    <Accordion
                                        sx={{ marginTop: '10px' }}
                                        title={`${t('Origem')}:`}
                                        startOpen
                                        content={
                                            <>
                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '40% 15% 15% 15% 15%' }, marginRight: { md: '0', lg: '90px' } }}>
                                                    <MainSelectField
                                                        required
                                                        {...this.props}
                                                        value={this.state.company}
                                                        label={t('Empresa')}
                                                        placeholder={t('[selecione...]')}
                                                        optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='documentNumber'
                                                        value={this.state.documentNumber}
                                                        label={t('Nr. Documento')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='deposit'
                                                        value={this.state.deposit}
                                                        label={t('Depósito Origem')}
                                                        placeholder={t('[selecione...]')}
                                                        optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='status'
                                                        value={this.state.status}
                                                        label={t('Situação')}
                                                        placeholder={t('[selecione...]')}
                                                        optionsList={this.state.statusOptions}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='movement'
                                                        value={this.state.movement}
                                                        label={t('Movimentação')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '24% 14% 31% 31%' }, marginRight: { md: '0', lg: '70px' }, marginTop: '10px' }}>
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='series'
                                                        value={this.state.series}
                                                        label={t('Série')}
                                                        placeholder={t('[digite...]')}
                                                        optionsList={this.state.seriesList.map(item => ({ label: item.ds_serie, value: item.cd_serie }))}
                                                        handleChange={this.handleChangeText}
                                                        required
                                                        ref={this.firstFieldRef}
                                                    />
                                                    <MainDateTimeInput
                                                        {...this.props}
                                                        id='releaseDate'
                                                        value={this.state.releaseDate}
                                                        label={t('D. Lançamento')}
                                                        handleChange={this.handleChangeText}
                                                        type='date'
                                                        required
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='movementType'
                                                        value={this.state.movementType}
                                                        label={t('Tipo de Movimento')}
                                                        handleChange={this.handleChangeMovementType}
                                                        optionsList={this.state.movementTypeList.map(item => ({ ...item, label: item.ds_movimentoestoquetipo, value: item.id_movimentoestoquetipo }))}
                                                        type='date'
                                                        required
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='responsible'
                                                        value={this.state.responsible}
                                                        label={t('Responsável')}
                                                        handleChange={this.handleChangeText}
                                                        optionsList={this.state.userList.map(item => ({ label: item.nm_usuario, value: item.cd_usuario }))}
                                                        type='date'
                                                        required
                                                    />
                                                </Box>
                                            </>
                                        }
                                    />

                                    {/* Destino */}
                                    <Accordion
                                        sx={{ marginTop: '10px' }}
                                        title={`${t('Destino')}:`}
                                        isOpen={this.state.isDestinyAccordionEnabled}
                                        onToggle={(isExpanded) => this.setState({ isDestinyAccordionEnabled: isExpanded })}
                                        content={
                                            <>
                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '29% 29% 28% 14%' }, marginRight: { md: '0', lg: '70px' } }}>
                                                    <MainSelectField
                                                        required
                                                        {...this.props}
                                                        id='companyDestiny'
                                                        value={this.state.companyDestiny}
                                                        label={t('Empresa')}
                                                        placeholder={t('[selecione...]')}
                                                        optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                                        handleChange={this.handleChangeText}
                                                        disabled={!this.state.isDestinyEnabled ? true : false}
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='depositDestiny'
                                                        value={this.state.depositDestiny}
                                                        label={t('Depósito Destino')}
                                                        placeholder={t('[selecione...]')}
                                                        optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                                        handleChange={this.handleChangeText}
                                                        disabled={!this.state.isDestinyEnabled ? true : false}
                                                        required
                                                    />
                                                    <MainSelectField
                                                        {...this.props}
                                                        id='movementTypeDestiny'
                                                        value={this.state.movementTypeDestiny}
                                                        label={t('Tipo de Movimento')}
                                                        handleChange={this.handleChangeText}
                                                        optionsList={this.state.movementTypeDestinyList.map(item => ({ ...item, label: item.ds_movimentoestoquetipo, value: item.id_movimentoestoquetipo }))}
                                                        type='date'
                                                        disabled={!this.state.isDestinyEnabled ? true : false}
                                                        required
                                                    />
                                                    <MainDateTimeInput
                                                        {...this.props}
                                                        id='releaseDateDestiny'
                                                        value={this.state.releaseDateDestiny}
                                                        label={t('D. Lançamento')}
                                                        handleChange={this.handleChangeText}
                                                        type='date'
                                                        disabled={!this.state.isDestinyEnabled ? true : false}
                                                        required
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '20% 20% 60%' }, marginRight: { md: '0', lg: '50px' }, marginTop: '10px' }}>
                                                    <MainTextField
                                                        required
                                                        {...this.props}
                                                        id='documentNumberDestiny'
                                                        value={this.state.documentNumberDestiny}
                                                        label={t('Nr. Documento')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='movementDestiny'
                                                        value={this.state.movementDestiny}
                                                        label={t('Movimentação')}
                                                        placeholder={t('[digite...]')}
                                                        handleChange={this.handleChangeText}
                                                        disabled
                                                    />
                                                    <MainTextField
                                                        {...this.props}
                                                        id='observation'
                                                        value={this.state.observation}
                                                        label={t('Observação')}
                                                        handleChange={this.handleChangeText}
                                                        type='date'
                                                        disabled={!this.state.isDestinyEnabled ? true : false}
                                                    />
                                                </Box>
                                            </>
                                        }
                                    />
                                </Box>

                                <Box sx={{ display: 'grid', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '15%' }, marginRight: { md: '0', lg: '40px' }, marginTop: '20px' }}>
                                    <MainButton
                                        {...this.props}
                                        sx={{ background: this.props.colors.orangeAccent[400] }}
                                        title={t('Gerar Movimentação')}
                                        onButtonClick={() => this.setState({ isDialogOpen: true })}
                                        disabled={this.state.isDisabled}
                                    />
                                </Box>
                            </>
                        }

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(ItemsMovement);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',  // adicionar padding na direita para espaçar
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    }),
    AddItemBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    addItemHeader: (props) => ({
        marginTop: '10px',
        color: props.colors.blueAccent[100],
        backgroundColor: props.colors.grey[900],
        padding: '5px 0 5px 10px',
        fontWeight: '600',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }),
    AddItemMainTextField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiInputBase-input': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root': {
            'fieldset': { borderColor: props.colors.orangeAccent[800] },
            '&.Mui-focused fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800] }
        },
        '& .MuiInputBase-input.Mui-disabled': { backgroundColor: 'transparent' }
    }),
    AddItemMainSelectField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800], boxShadow: 'none !important' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    }),
    AddItemMainButton: (props) => ({
        width: { xs: '100%', lg: '70%' },
        height: '30px',
        border: `1px solid ${props.colors.blueAccent[100]}`,
        textColor: props.colors.blueAccent[100],
        margin: { xs: '30px 0 10px 0', lg: '50px 0 0 15px' }
    })
}
