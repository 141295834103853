import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { Box, Button } from "@mui/material";
import { TabsContext } from './TabsContext'; // Importe seu contexto de abas

class TabBar extends React.Component {
   static contextType = TabsContext

   render() {
      const { tabs, switchTab, closeTab, activeTab } = this.context
      return (
         <Box
            sx={{
               display: 'flex',
               maxWidth: '100%',
               gap: 1,
               margin: '10px 0 0 0',
               overflowX: 'auto',
               objectFit: 'contain',
               whiteSpace: 'nowrap',
               '& > *': { // Coloca estilo em cada item dentro do contêiner
                  flexShrink: 0, // Não deixa os itens encolherem
                  marginRight: '8px',
               },

               // estilização scroll-bar - CHROME
               '&::-webkit-scrollbar-thumb': {
                  backgroundColor: this.props.colors.redAccent[300],
                  borderRadius: '10px',
               },
               '&::-webkit-scrollbar-track': {
                  background: this.props.colors.custom['colorWhite']
               },
               '&::-webkit-scrollbar': {
                  height: '10px',
                  marginTop: '10px',
               },
            }}
         >
            {tabs.map((tab, index) => (
               <Box
                  key={index}
                  sx={[
                     {
                        position: 'relative',
                        marginBottom: '10px',
                        borderRadius: '20px',
                        backgroundColor: this.props.colors.custom['white'],
                        '& > *': {
                           color: this.props.colors.grey[500],
                        },
                        ":hover": { borderRadius: '20px', backgroundColor: this.props.colors.grey[1000] },
                     },
                     activeTab === tab.key && {
                        backgroundColor: this.props.colors.blueAccent[100],
                        borderRadius: '20px',
                        '& > *': {
                           color: this.props.colors.custom['white'],
                        },
                        ":hover": { borderRadius: '20px' },
                     }
                  ]}
               >
               {index !== 0 ?
                  <>
                     <Button
                        sx={[{ paddingRight: '30px', width: '100%', fontWeight: '500', letterSpacing: '1px', textTransform: 'none', borderRadius: '20px' }, activeTab === tab.key && {color: this.props.colors.custom['white'],} ]}
                        key={tab.key}
                        onClick={() => {
                           switchTab(tab.key)
                           this.props.switchTab(tab.key)
                        }}
                     >
                        {tab.title}
                     </Button>
                     
                     <CloseIcon onClick={() => closeTab(tab.key)} sx={{ position: 'absolute', right: '5px', top: '6px', cursor: 'pointer' }} />
                  </>
               : 
                  <Button
                     sx={[{ minWidth: '0px', width: '100%', fontWeight: '500', letterSpacing: '1px', textTransform: 'none', borderRadius: '20px' }, activeTab === tab.key && {color: this.props.colors.custom['white'],} ]}
                     key={tab.key}
                     onClick={() => {
                        switchTab(tab.key)
                        this.props.switchTab(tab.key)
                     }}
                  >
                     {tab.title}
                  </Button>
               }
               </Box>
            ))}
         </Box>
      )
   }
}

export default TabBar