import React, { forwardRef } from "react";
import { Box, Button, Typography } from "@mui/material";

class MainButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { sx, onFocus, disabled, endIcon, startIcon } = this.props // Incluindo a propriedade disabled

        const borderRadius = sx?.borderRadius || '25px';
        const textColor = sx?.textColor || 'white';
        const width = sx?.width || '100%';

        return (
            <Box
                display='flex'
                justifyContent='center'
                onClick={(e) => {
                    if (disabled) {
                        e.stopPropagation()
                    } else {
                        this.props.onButtonClick()
                    }
                }}
                height='40px'
                sx={{
                    width: width,
                    borderRadius: borderRadius,
                    opacity: disabled ? 0.5 : 1,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    ...sx,
                }}
            >
                <Button
                    ref={this.props.innerRef}
                    sx={{ width: '100%', borderRadius: borderRadius }}
                    id={this.props.id}
                    onFocus={(params) => onFocus ? onFocus(params) : null}
                    disabled={disabled}
                    endIcon={endIcon ?? null}
                    startIcon={startIcon ?? null}
                >
                    <Typography
                        sx={{ color: textColor, fontWeight: '500', letterSpacing: '1px', textTransform: 'none' }}
                    >
                        {this.props.title}
                    </Typography>
                </Button>
            </Box>
        )
    }
}

const ForwardedMainButton = forwardRef((props, ref) => {
    return <MainButton {...props} innerRef={ref} />
})

export default ForwardedMainButton