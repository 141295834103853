import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import PurchaseRequestReport from "../../../components/reports/PurchaseRequestReport";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { getPurchaseOrigin, getRequestType } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class PurchaseRequest extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_SolicitacaoCompra',
            isLoading: true,
            isLoadingTable: false,
            isReportOpen: false,
            isEditingTableItem: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['qt_item', t('Q. na Embalagem')],
                ['qt_solicitada', t('Q. Solicitada')],    // digitado na tabela
                ['dt_entrega', t('D. Entrada')],
                ['vl_ultima_compra', t('V. Ult Compra')],
                ['qt_minima_estoque', t('Estoque Min.')],
                ['vl_total', t('V. Total')],                // novo = qt_item * vl_ultima_compra
                ['qt_estoque', t('Q. Estoque')],          // novo = qt_solicitada (* ou /) qt_item
                ['qt_saldo', t('Saldo Dispo.')],
                ['ds_embalagempadrao', t('Emb. Padrão')],
                ['status', t('')],
            ],
            tableTotalSize: 0,

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            automaticPurchaseOrderItems: false,
            daysForDelivery: '',
            requestDate: dayjs(),
            status: 1,
            observation: '',

            item: '',
            itemsList: [],

            itemPacking: '',
            itemPackagingList: [],

            requestTypeList: [],
            purchaseOriginList: [],
            statusList: [
                { 'value': 1, 'label': 'Ativo' }
            ],
            reportTypeList: [
                { 'value': 0, 'label': t('Padrão') },
                { 'value': 1, 'label': t('Visão Simplificada') }
            ],

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.setState({
            tableList: this.props.newTableList.map((item, index) => ({
                ...item,
                id_linha: index + 1, // Adiciona um id único para cada objeto
                dt_entrega: dayjs().add(1, 'day'),
                ds_embalagempadrao: item.ds_embalagem,
                status: item.fl_pedido_automatico === 'S' ? '#00dcff' : '#cfcfcf',
            }))
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.item !== prevState.item) {
            this.state.item
                ? this.getItemsPackaging()
                : this.setState({ itemPackagingList: [], itemPacking: '', um: '', qtdRequested: '' })
        }
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }
    }

    calculateTable = () => {
        const updateTable = this.state.tableList.map(item => {
            const operation = item.at_operacao

            const qtEmb = item.qt_item || 0
            const qtDigitado = item.qt_solicitada || 0
            const vlUltimaCompra = item.vl_ultima_compra || 0

            const qtEstoque = operation === 228 ? qtDigitado * qtEmb : qtDigitado / qtEmb || 0

            const vlTotal = vlUltimaCompra * qtEmb || 0

            return { ...item, qt_estoque: qtEstoque, vl_total: vlTotal }
        })

        let sum = updateTable.reduce((acc, item) => acc + item.vl_total, 0)


        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length, totalValue: sum.toFixed(4) })
            }, 100)
        }
    }

    calculateLastPurchaseValue = async () => {
        this.setState({ isIncludeDisabled: true })

        const item = this.state.itemsList.find(item => item.id_item === this.state.item)

        let config = {
            endpoint: `componente/valorultimacompra/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_deposito': this.props.deposit,
            'dt_selecionada': dayjs().format('YYYY-MM-YY'),
            'id_item': this.state.item,
            'id_embalagem': this.state.itemPacking,
            'id_embalagem_padrao': item.id_embalagempadrao,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data) {
                this.setState({ isIncludeDisabled: false })

                return r.data.vl_ultima_cpa
            } else {
                this.setState({ isIncludeDisabled: false })

                return 0
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return 0
        }
    }

    checkButtonIsDisabled = () => {
        if (!this.state.item || !this.state.um || !this.state.itemPacking || !this.state.qtdRequested) {
            return true
        }
        return false
    }

    fetchData = async () => {
        try {
            const requestTypeData = getRequestType(this.props.user.entidade)
            const purchaseOriginData = getPurchaseOrigin(this.props.user.entidade)

            const [requestTypeList, purchaseOriginList] = await Promise.all([requestTypeData, purchaseOriginData])

            const filteredRequestTypeList = requestTypeList.filter(item => item.at_situacao === 1)
            const filteredPurchaseOriginList = purchaseOriginList.filter(item => item.at_situacao === 1)

            this.setState({
                requestTypeList: filteredRequestTypeList,
                purchaseOriginList: filteredPurchaseOriginList,

                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                requestTypeList: [],
                purchaseOriginList: [],

                isLoading: false
            })
        }
    }

    getItems = (item) => {
        const { t } = this.props
        let config = {
            endpoint: `componente/item/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'tx_busca': item,
            'x-Pagina': 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemsList: r.data
                })
            } else {
                this.setState({
                    itemsList: [],

                    alertMessage: t('Nenhum dado retornado'),
                    alertType: 'warning',
                    showAlert: true,
                })
            }
        })
    }

    getItemsPackaging = (page = 0) => {
        let config = {
            endpoint: `componente/itemembalagem/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'id_item': this.state.item,
            'x-Pagina': page + 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    itemPacking: '',
                    itemPackagingList: r.data,
                })
            } else {
                this.setState({
                    itemPackagingList: []
                })
            }
        })
    }

    handleChangePacking = (event) => {
        const itemPackingData = this.state.itemPackagingList.find(item => item.id_embalagem === event.target.value) || []

        const um = itemPackingData.sg_unidademedida || ''

        if (event.target.value) {
            this.setState({ [event.target.id]: event.target.value, um: um })
        } else {
            this.setState({ [event.target.id]: event.target.value, um: '' })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.includeItem()
        }
    }

    includeItem = async () => {
        const item = this.state.itemsList.find(item => item.id_item === this.state.item)
        const packing = this.state.itemPackagingList.find(itemPacking => itemPacking.id_embalagem === this.state.itemPacking)

        const lastPurchaseValue = await this.calculateLastPurchaseValue()

        if (item && packing) {
            const newItem = {
                id_linha: this.state.tableList.length + 1,  // cria um id único para poder adicionar duas ou + vezes o mesmo item
                id_item: item.id_item,
                cd_item: item.cd_item,
                ds_item: item.ds_item,
                id_embalagem: packing.id_embalagem,
                ds_embalagem: packing.ds_embalagem,
                sg_unidademedida: packing.sg_unidademedida,
                qt_item: packing.qt_item,
                qt_solicitada: this.state.qtdRequested,
                dt_entrega: dayjs(),
                vl_ultima_compra: lastPurchaseValue,                                // vem do Request
                qt_minima_estoque: item.qt_minima_estoque,                          // vem da Embalagem
                vl_total: 0,                                                        // Calculado
                qt_estoque: 0,                                                      // Calculado
                qt_saldo: item.qt_saldo,                                            // vem da Embalagem
                ds_embalagempadrao: item.ds_embalagempadrao,                        // vem do Item
                at_operacao: packing.at_operacao,                                   // vem da Embalagem
                status: item.fl_liberar_compra === 'S' ? '#00dcff' : '#cfcfcf',     // vem do Item
            }

            const itemExists = this.state.tableList.some(tableItem =>   // se já existir o mesmo item com a mesma embalagem na tabela
                tableItem.id_item === newItem.id_item && tableItem.id_embalagem === newItem.id_embalagem
            )

            if (itemExists) {
                this.showAlert('Já existe um registro para este item e embalagem na tabela.', 'error')
            } else {
                this.setState(prevState => ({
                    tableList: [...prevState.tableList, newItem],
                    item: '',
                    itemPacking: '',
                    qtdRequested: '',

                    itemsList: [],
                    itemPackagingList: [],
                }))
                this.showAlert('Item adicionado com sucesso.', 'success')
            }
        }
    }

    sendPurchaseRequest = () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!this.state.purchaseOrigin || !this.state.requestType) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = this.state.tableList.some(item => !('qt_solicitada' in item) || item.qt_solicitada === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let config = {
            endpoint: `adc/solicitacaocompra?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_usuario_solicitante': this.props.user.data.cd_usuario,
            'dt_solicitacao': this.state.requestDate.format('YYYY-MM-YY'),
            'id_solicitacaocompratipo': this.state.requestType,
            'cd_origemcompra': this.state.purchaseOrigin,
            'at_situacao': this.state.status,
            'observacao': this.state.observation,
            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map((item, index) => {
                    return {
                        'nr_ordem': index + 1,
                        'cd_item': item.cd_item,
                        'id_item': item.id_item,
                        'id_embalagem': item.id_embalagem,
                        'qt_solicitada': item.qt_solicitada,
                        'qt_atendida': 0,
                        'qt_convertido': item.qt_estoque || 0,
                        'vl_ultima_compra': item.vl_ultima_compra,
                        'dt_entrega': item.dt_entrega
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                    request: r.data.id_solicitacaocompra,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    updateTableDates = () => {
        this.setState((prevState) => ({
            tableList: prevState.tableList.map(item => ({
                ...item,
                dt_entrega: dayjs().add(this.state.daysForDelivery || 0, 'day')
            }))
        }))
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendPurchaseRequest}                        // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}      // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Solicitação')}: ${this.state.request}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isPurchaseRequestVisible')}
                        />

                        {this.state.isReportOpen ?
                            <PurchaseRequestReport
                                {...this.state}
                                {...this.props}
                                columns={this.state.tableColumns}
                                rows={this.state.tableList}
                                onClose={() => this.setState({ isReportOpen: false })}
                            /> : <></>}

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Solicitação de Compra')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={`${t('Itens')}:`}
                                        content={
                                            <>

                                                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '100%', lg: '15%' } }}>
                                                    <MainButton
                                                        {...this.props}
                                                        sx={{ background: this.state.isIncludeItemTableVisible ? this.props.colors.grey[900] : this.props.colors.blueAccent[100] }}
                                                        title={t('Adicionar Item')}
                                                        onButtonClick={() => this.setState({ isIncludeItemTableVisible: true })}
                                                    />
                                                </Box>

                                                {this.state.isIncludeItemTableVisible &&
                                                    (
                                                        <Box sx={{ padding: '10px 30px', border: `1px solid ${this.props.colors.orangeAccent[800]}`, margin: '20px 0', borderRadius: '10px' }}>

                                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Adicionar Item')}:</Typography>

                                                                <Divider sx={{ flexGrow: 1, margin: '0 0 0 20px' }} />

                                                                <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isIncludeItemTableVisible: false })}>
                                                                    <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                                                </Button>
                                                            </Box>

                                                            {/* Campos de adição */}
                                                            <Box sx={{ display: 'grid', gap: '0', gridTemplateColumns: { xs: '100%', lg: '30% 10% 30% 15% 15%' } }}>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '20px 0 0 20px' }}>{t('Descrição do Item')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='item' value={this.state.item} placeholder={t('[digite para buscar as opções...]')} optionsList={this.state.itemsList.map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))} handleChange={this.handleChangeText} delayFunction={this.getItems} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('UM')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='um' value={this.state.um} placeholder={t('[unidade]')} handleChange={this.handleChangeText} disabled={true} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={styles.addItemHeader(this.props)}>{t('Embalagem')}</Typography>
                                                                    <MainSelectField sx={styles.AddItemMainSelectField(this.props)} {...this.props} id='itemPacking' value={this.state.itemPacking} placeholder={t('[embalagem...]')} optionsList={this.state.itemPackagingList.map(item => ({ label: item.ds_embalagem, value: item.id_embalagem }))} handleChange={this.handleChangePacking} />
                                                                </Box>
                                                                <Box sx={styles.AddItemBox}>
                                                                    <Typography sx={{ ...styles.addItemHeader(this.props), borderRadius: '0 20px 20px 0' }}>{t('Quantidade')}</Typography>
                                                                    <MainTextField sx={styles.AddItemMainTextField(this.props)} {...this.props} id='qtdRequested' value={this.state.qtdRequested} placeholder={t('[0,0000]')} handleChange={this.handleChangeText} type='number' maxDecimals={4} onKeyUp={this.handleEnterKeyUp} />
                                                                </Box>
                                                                <MainButton sx={styles.AddItemMainButton(this.props)} {...this.props} title={t('Inserir')} onButtonClick={this.includeItem} disabled={this.checkButtonIsDisabled() || this.state.isIncludeDisabled} />
                                                            </Box>

                                                            <Divider sx={{ flexGrow: 1, marginTop: '5px' }} />
                                                        </Box>
                                                    )
                                                }

                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    allowEditOnRow
                                                    id='table_purchase_request'
                                                    rowId='id_linha'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    totalSize={this.state.tableTotalSize}
                                                    isLoading={this.state.isLoadingTable}
                                                    onRowDoubleClick={() => { }}
                                                    onPageChange={() => { }}
                                                    onEditRow={this.handleEditRow}
                                                    editableFields={['qt_solicitada', 'dt_entrega']}
                                                    extraColumnsConfig={
                                                        {
                                                            'qt_item': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_solicitada': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'dt_entrega': {
                                                                'type': 'date',
                                                            },
                                                            'vl_ultima_compra': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_minima_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_total': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_saldo': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'status': {
                                                                'type': 'status',
                                                            },
                                                        }
                                                    }
                                                    customRowSize={
                                                        {
                                                            'cd_item': 80,
                                                            'ds_item': 210,
                                                            'qt_item': 130,
                                                            'qt_solicitada': 110,
                                                            'dt_entrega': 110,
                                                            'status': 40
                                                        }
                                                    }
                                                />
                                            </>
                                        }
                                    />


                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '32% 22% 22% 12% 12%' }, margin: { md: '10px 0 0 0', lg: '10px 80px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='company'
                                            value={this.state.company}
                                            label={t('Empresa')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            required
                                            {...this.props}
                                            id='requestType'
                                            value={this.state.requestType}
                                            label={t('Tipo de Solicitação')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.state.requestTypeList.map(item => ({ label: item.ds_solicitacaocompratipo, value: item.id_solicitacaocompratipo }))}
                                            ref={this.firstFieldRef}
                                        />
                                        <MainSelectField
                                            required
                                            {...this.props}
                                            id='purchaseOrigin'
                                            value={this.state.purchaseOrigin}
                                            label={t('Origem de Compra')}
                                            placeholder={t('[selecione...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.state.purchaseOriginList.map(item => ({ label: item.ds_origemcompra, value: item.cd_origemcompra }))}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='daysForDelivery'
                                            value={this.state.daysForDelivery}
                                            label={t('Dias para Entrega')}
                                            placeholder={t('[00]')}
                                            handleChange={this.handleChangeText}
                                            type='number'
                                        />
                                        <MainButton
                                            {...this.props}
                                            onButtonClick={this.updateTableDates}
                                            title={t('Atualizar Data')}
                                            sx={{ background: this.props.colors.blueAccent[100], marginTop: '20px' }}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '15% 15% 15% 30% 25%' }, margin: { md: '10px 0 0 0', lg: '10px 80px 0 0' } }}>
                                        <MainTextField
                                            {...this.props}
                                            id='request'
                                            value={this.state.request}
                                            label={t('Solicitação')}
                                            placeholder={t('[digite...]')}
                                            disabled={true}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='requestDate'
                                            value={this.state.requestDate}
                                            label={t('D. Solicitação')}
                                            placeholder={t('[00/00/0000]')}
                                            type='date'
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='status'
                                            value={this.state.status}
                                            label={t('Situação')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.statusList}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='observation'
                                            value={this.state.observation}
                                            label={t('Observação')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                        />
                                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0 10px', border: `1px solid ${this.props.colors.grey[800]}`, borderRadius: '10px' }}>
                                            <i style={{ fontSize: '22px', color: '#00dcff' }} class="fa-solid fa-circle-check" />
                                            <Typography
                                                sx={{ fontSize: '16px', fontWeight: '400' }}
                                            >
                                                {t('Itens configurados para pedido de compra automático')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                {this.state.isDisabled ?
                                    (
                                        <Box sx={{ display: 'grid', gap: '20px', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '22% 3% 9% 13%' }, margin: { md: '0', lg: '20px 40px 20px 0' } }}>
                                            <MainSelectField
                                                {...this.props}
                                                id='reportType'
                                                value={this.state.reportType}
                                                label={t('Tipo de Relatório')}
                                                placeholder={t('[selecione...]')}
                                                optionsList={this.state.reportTypeList}
                                                handleChange={this.handleChangeText}
                                            />
                                            <Button className='main-icon-button' sx={{ marginTop: '20px !important', margin: 'auto', height: '40px' }} onClick={() => this.setState({ isReportOpen: true })}>
                                                <i style={{ fontSize: '20px' }} className="fa-solid fa-print"></i>
                                            </Button>
                                            <MainTextField
                                                {...this.props}
                                                id='totalValue'
                                                value={this.state.totalValue}
                                                label={t('V. Total')}
                                                placeholder={t('[0,00]')}
                                                type="number"
                                                maxDecimals={4}
                                                disabled={true}
                                            />
                                            <MainButton
                                                {...this.props}
                                                sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                title={t('Gerar Solicitação')}
                                                onButtonClick={() => this.setState({ isDialogOpen: true })}
                                                disabled={this.state.isDisabled}
                                            />
                                        </Box>
                                    )
                                    :
                                    (
                                        <Box sx={{ display: 'grid', gap: '20px', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '9% 13%' }, margin: { md: '0', lg: '20px 40px 20px 0' } }}>
                                            <MainTextField
                                                {...this.props}
                                                id='totalValue'
                                                value={this.state.totalValue}
                                                label={t('V. Total')}
                                                placeholder={t('[0,00]')}
                                                type="number"
                                                maxDecimals={4}
                                                disabled={true}
                                            />
                                            <MainButton
                                                {...this.props}
                                                sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                title={t('Gerar Solicitação')}
                                                onButtonClick={() => this.setState({ isDialogOpen: true })}
                                                disabled={this.state.isDisabled}
                                            />
                                        </Box>
                                    )
                                }
                            </>
                        }

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(PurchaseRequest);

const right = keyframes` from {right: -100%; } to {right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    }),
    AddItemBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    addItemHeader: (props) => ({
        marginTop: '10px',
        color: props.colors.blueAccent[100],
        backgroundColor: props.colors.grey[900],
        padding: '5px 0 5px 10px',
        fontWeight: '600',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }),
    AddItemMainTextField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiInputBase-input': { boxShadow: 'none !important' },
        '& .MuiOutlinedInput-root': {
            'fieldset': { borderColor: props.colors.orangeAccent[800] },
            '&.Mui-focused fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800] }
        },
        '& .MuiInputBase-input.Mui-disabled': { backgroundColor: 'transparent' }
    }),
    AddItemMainSelectField: (props) => ({
        width: `calc(100% - 10px)`,
        '& fieldset': { borderWidth: '0 0 1px 0', borderColor: props.colors.orangeAccent[800], boxShadow: 'none !important' },
        '& .MuiInputBase-root': { borderRadius: '0' },
        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { border: 'none' } }
    }),
    AddItemMainButton: (props) => ({
        width: { xs: '100%', lg: '70%' },
        height: '30px',
        border: `1px solid ${props.colors.blueAccent[100]}`,
        textColor: props.colors.blueAccent[100],
        margin: { xs: '30px 0 10px 0', lg: '50px 0 0 15px' }
    })
}