import React from "react";

import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Grid, keyframes, Typography } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { getSupplier } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.firstFieldRef = React.createRef()

        this.state = {
            isLoading: false,

            itemsList: [],
            supplier: [],
        }
    }

    componentDidMount() {
        if (this.firstFieldRef.current) {
            this.firstFieldRef.current.focus()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company !== this.props.company) {
            this.props.fetchData()
        }
    }

    fetchSupplier = async (supplier) => {
        try {
            const supplierData = await getSupplier(this.props.user.entidade, supplier)
            this.props.setList('supplierList', supplierData)
        } catch (error) {
            console.error('Error fetching suppliers:', error)
            this.setState({ supplier: [] })
        }
    }

    fetchItems = (item) => {
        const { t } = this.props

        let config = {
            endpoint: `componente/item/busca/${this.props.user.entidade}`,
            method: 'get'
        }
        let form = {
            'tx_busca': item,
            'x-Pagina': 1,
        }
        defaultRequest(config, form).then((r) => {
            if (r.data && Object.keys(r.data).length > 0) {
                this.props.setList('itemsList', r.data)
            } else {
                this.setState({
                    alertMessage: t('Nenhum dado retornado'),
                    alertType: 'warning',
                    showAlert: true,
                })
            }
        })
    }

    verifyRequiredFields = () => {
        const { t } = this.props

        if (!this.props.company || !this.props.deposit) {
            this.setState({
                alertMessage: t('Preencha todos os campos obrigatórios (*)'),
                alertType: 'error',
                showAlert: true,
            })
        } else {
            this.props.getData()
        }
    }

    // Necessário adicionar position: 'relative' na Box por fora fo  filtro
    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props)}>

                    {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <i style={{ fontSize: '20px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-sliders"></i>
                                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px' }}>{t('Filtro avançado:')}</Typography>
                                    </Box>
                                    <Button sx={{ minWidth: '0', borderRadius: '50%' }} onClick={() => this.props.toggleVisiblePage('isFilterVisible')}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ marginRight: { xs: '0', lg: '40px' } }}>
                                    <Grid container columnSpacing={3} rowSpacing={2} sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
                                        <Grid item lg={4}><MainSelectField required {...this.props} id='company' value={this.props.company} label={t('Empresa')} placeholder={t('[selecione...]')} optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))} handleChange={this.props.handleChangeText} ref={this.firstFieldRef}/></Grid>
                                        <Grid item lg={4}><MainSelectField required {...this.props} id='deposit' value={this.props.deposit} label={t('Depósito')} placeholder={t('[selecione...]')} optionsList={this.props.depositList.map(item => ({ label: `${item.cd_deposito} - ${item.ds_deposito}`, value: item.cd_deposito }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainTextField {...this.props} id='descriptionItem' value={this.props.descriptionItem} label={t('Descrição do Item')} placeholder={t('[digite...]')} handleChange={this.props.handleChangeText} /></Grid>

                                        <Grid item lg={4}><MainSelectField {...this.props} id='item' value={this.props.item} label={t('Item')} placeholder={t('[digite para buscar as opções...]')} optionsList={this.props.itemsList.map(item => ({ label: `${item.cd_item} - ${item.ds_item}`, value: item.id_item }))} handleChange={this.props.handleChangeText} delayFunction={this.fetchItems} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='family' value={this.props.family} label={t('Família')} placeholder={t('[selecione...')} optionsList={this.props.familyList.map(item => ({ label: item.ds_familia, value: item.cd_familia }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='largeGroup' value={this.props.largeGroup} label={t('Grande Grupo')} placeholder={t('[selecione...]')} optionsList={this.props.largeGroupList.map(item => ({ label: item.ds_grandegrupo, value: item.cd_grandegrupo }))} handleChange={this.props.handleChangeText} /></Grid>

                                        <Grid item lg={4}><MainSelectField {...this.props} id='group' value={this.props.group} label={t('Grupo')} placeholder={t('[selecione...]')} optionsList={this.props.groupList.map(item => ({ label: item.ds_grupo, value: item.cd_grupo }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='subGroup' value={this.props.subGroup} label={t('Sub Grupo')} placeholder={t('[selecione...]')} optionsList={this.props.subGroupList.map(item => ({ label: item.ds_subgrupo, value: item.cd_subgrupo }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='manufacturer' value={this.props.manufacturer} label={t('Fabricante')} placeholder={t('[selecione...]')} optionsList={this.props.manufacturerList.map(item => ({ label: item.ds_fabricante, value: item.id_fabricante }))} handleChange={this.props.handleChangeText} /></Grid>

                                        <Grid item lg={4}><MainSelectField {...this.props} disabled id='' value={this.props.locator} label={t('Localizador')} placeholder={t('[selecione...]')} optionsList={[]} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='itemType' value={this.props.itemType} label={t('Tipo de Item')} placeholder={t('[selecione...]')} optionsList={this.props.itemTypeList.map(item => ({ label: item.ds_itemtipo, value: item.cd_itemtipo }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='inventoryGroup' value={this.props.inventoryGroup} label={t('Grupo Inventário')} placeholder={t('[selecione...]')} optionsList={this.props.inventoryGroupList.map(item => ({ label: item.ds_grupoinventario, value: item.cd_grupoinventario }))} handleChange={this.props.handleChangeText} /></Grid>

                                        <Grid item lg={4}><MainSelectField {...this.props} id='requestGroup' value={this.props.requestGroup} label={t('Grupo Solicitação')} placeholder={t('[selecione...]')} optionsList={this.props.requestGroupList.map(item => ({ label: item.ds_gruposolicitacao, value: item.cd_gruposolicitacao }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='productList' value={this.props.productList} label={t('Lista de Produtos')} placeholder={t('[selecione...]')} optionsList={this.props.productListList.map(item => ({ label: item.ds_listainventario, value: item.id_listainventario }))} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainSelectField {...this.props} id='supplier' value={this.props.supplier} label={t('Fornecedor')} placeholder={t('[digite para buscar as opções...]')} optionsList={this.props.supplierList.map(item => ({ label: item.fantasia, value: item.cd_cadastro }))} handleChange={this.props.handleChangeText} delayFunction={this.fetchSupplier} /></Grid>

                                        <Grid item lg={4}><MainCheckBoxInput {...this.props} id='productionItem' value={this.props.productionItem} label={t('Itens para Produção')} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={8}><MainCheckBoxInput {...this.props} id='pendingItem' value={this.props.pendingItem} label={t('Itens para Compra')} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainCheckBoxInput {...this.props} id='stockItem' value={this.props.stockItem} label={t('Itens com Estoque Mínimo')} handleChange={this.props.handleChangeText} /></Grid>
                                        <Grid item lg={4}><MainCheckBoxInput {...this.props} id='balanceItem' value={this.props.balanceItem} label={t('Itens com Saldo')} handleChange={this.props.handleChangeText} /></Grid>

                                        <Grid item lg={2}><MainButton {...this.props} onButtonClick={this.props.clearFields} title={t("Limpar pesquisa")} sx={{ width: '100%', border: `1px solid ${this.props.colors.grey[500]}`, textColor: this.props.colors.grey[500] }} /></Grid>
                                        <Grid item lg={2}><MainButton {...this.props} onButtonClick={this.verifyRequiredFields} title={t("Consultar")} sx={{ width: '100%', background: this.props.colors.orangeAccent[400] }} disabled={this.props.isDisabled}/></Grid>
                                    </Grid>


                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Filter);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '90%',
        height: '100%',
        borderRadius: '20px 10px 10px 20px',
        padding: '10px 20px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        animation: `${right} 0.4s`,
    })
}

