import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MainButton from "../inputs/MainButton";

import { Box, Dialog, Typography } from "@mui/material";


class DialogAlert extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { t } = this.props

        return (
            <Dialog className='dialog-box' open={this.props.isOpen} onClose={this.handleClose} onTransitionExited={this.props.onExit ?? null}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '15px',
                        minWidth: '300px'
                    }}
                >
                    {this.props.type == 'confirm' ?
                        <CheckCircleIcon style={{ color: this.props.colors.greenAccent[500], fontSize: 60 }} />
                        :
                        this.props.type == 'cancel' ?
                            <CancelIcon style={{ color: this.props.colors.redAccent[500], fontSize: 60 }} />
                            :
                            <></>
                    }

                    <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '10px 0 20px 0' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{this.props.title}</Typography>
                        <Typography sx={{ fontSize: '16px', textAlign: 'center' }}> {this.props.body}</Typography>
                    </Box>

                    <Box width='70%' display='flex' justifyContent='space-around' alignItems='center' gap='20px'>
                        {this.props.onClose ?
                            <MainButton title={t('Voltar')} onButtonClick={this.props.onClose} sx={{textColor: this.props.colors.grey[500], background: this.props.colors.custom['white'], border: `1px solid ${this.props.colors.grey[500]}` }} />
                            :
                            <></>
                        }
                        {this.props.onConfirm ?
                            <MainButton title={t('Confirmar')} onButtonClick={this.props.onConfirm} sx={{ width: '100%', backgroundColor: this.props.colors.blueAccent[100] }} />
                            :
                            <></>
                        }
                    </Box>
                </Box>
            </Dialog>
        )
    }
}

export default DialogAlert