import { defaultRequest } from "../../utils/request/request";

const defaultGetRequest = async (endpoint, entity, company, cd_company, search, type) => {
    let config = {
        endpoint,
        method: 'get'
    }
    let form = {
        'x-Entidade': entity,
        'x-Pagina': 1,
        ...(search && { 'tx_busca': search }),
        ...(company && { 'x-Empresa': company }),
        ...(cd_company && { 'cd_empresa': cd_company }),
        ...(type && { 'at_tipo_aplicacao': type }),
    }
    const response = await defaultRequest(config, form)
    if (response.status) {
        return Array.isArray(response.data) ? response.data : [] // se response.data for um array, senão retorna []
    } else {
        throw new Error('Failed')
    }
}

export const getAttribute = (entity) => defaultGetRequest(`gco/atributo`, entity)
export const getCostCenter = (entity, company, search) => defaultGetRequest(`componente/centrocustoconta/busca/${entity}`, null, null, company, search)
export const getDeposit = (company, entity) => defaultGetRequest(`sis/deposito/empresa/${company}`, entity)
export const getFamily = (entity) => defaultGetRequest(`adm/familia`, entity)
export const getGroup = (entity) => defaultGetRequest(`adm/grupo`, entity)
export const getInventoryGroup = (company, entity) => defaultGetRequest(`sis/grupoinventario/empresa/${company}`, entity)
export const getItemType = (entity) => defaultGetRequest(`adm/itemtipo`, entity)
export const getLargeGroup = (entity) => defaultGetRequest(`adm/grandegrupo`, entity)
export const getLocator = (entity) => defaultGetRequest(`everlojas/localizador`, entity)
export const getManufacturer = (entity) => defaultGetRequest(`adm/fabricante`, entity)
export const getMovementType = (entity) => defaultGetRequest(`adm/movimentoestoquetipo`, entity)
export const getNonCompliance = (entity) => defaultGetRequest('everlojas/naoconformidade', entity)
export const getPaymentConditions = (entity, type) => defaultGetRequest(`componente/condicaovendacompra/busca/${entity}`, entity, null, null, null, type)
export const getPurchaseOrigin = (entity) => defaultGetRequest(`adc/origemcompra`, entity)
export const getProductList = (entity) => defaultGetRequest(`gir/listainventario`, entity)
export const getRequestGroup = (company, entity) => defaultGetRequest(`sis/gruposolicitacao/empresa/${company}`, entity)
export const getRequestType = (entity) => defaultGetRequest(`adc/solicitacaocompratipo`, entity)
export const getSettings = (entity) => defaultGetRequest(`sis/configuracao`, entity)
export const getSubGroup = (entity) => defaultGetRequest(`adm/subgrupo`, entity)
export const getSupplier = (entity, search) => defaultGetRequest(`componente/fornecedor/busca/${entity}`, entity, null, null, search)
export const getSupplierByCompany = (company, entity) => defaultGetRequest(`sis/fornecedor/empresa/${company}`, entity)
export const getUser = (entity) => defaultGetRequest(`ads/usuario`, entity)