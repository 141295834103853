import React from 'react';
import { jsPDF } from 'jspdf';
import { defaultRequest } from '../../utils/request/request';
import 'jspdf-autotable';
import { formatPhoneNumber } from '../../utils/format';
import mini_logo from "../../data/login/af_acom_logo_versao_principal.png";
import dayjs from 'dayjs';

class EntryReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            company: {},
            defaultCompany: props.user.data.empresas.find(item => item.cd_empresa === props.user.data.cd_empresa)
        }
    }

    componentWillMount() {
        this.searchInfos()
    }

    generatePDF = () => {
        const { columns, rows } = this.props
        const now = dayjs()

        const doc = new jsPDF({
            orientation: "landscape", // Define a folha como paisagem
            unit: "mm", // Unidade em milímetros
            format: "a4", // Formato A4
        })

        const pageWidth = doc.internal.pageSize.getWidth()

        const borderColor = "#000000"; // Cor do border
        const padding = 5; // Espaçamento interno
        const startX = 10; // Posição inicial X
        const startY = 10; // Posição inicial Y
        const width = pageWidth - 20; // Largura do cabeçalho
        const titleHeight = 10; // Altura do título
        const contentHeight = 30; // Altura da área de informações

        // Desenha a borda externa do cabeçalho
        doc.setDrawColor(borderColor);
        doc.rect(startX, startY, width, titleHeight + contentHeight);

        // Adiciona o título centralizado
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("CONTROLE DE ENTRADAS", pageWidth / 2, 9, { align: "center" });

        // Adiciona a logo no canto esquerdo
        const imageX = 12, imageY = 20, imageWidth = 40, imageHeight = 15;
        doc.addImage(this.state.defaultCompany.im_cadastro ? 'data:image/png;base64,' + this.state.defaultCompany.im_cadastro : mini_logo, 'PNG', imageX, imageY, imageWidth, imageHeight);
        
        // Adiciona os campos da esquerda
        const infoStartX = 55
        const infoStartY = 20
        doc.setFontSize(14);
        doc.text(this.state.company.fantasia, infoStartX, infoStartY);
        doc.setFontSize(10);
        doc.text(this.state.company.endereco + ', ' + this.state.company.nr_endereco, infoStartX, infoStartY + 5);
        doc.text(this.state.company.bairro + (this.state.company.cidade_estado ? ' - ' + this.state.company.cidade_estado : ''), infoStartX, infoStartY + 10);
        doc.text('Fone: ' + formatPhoneNumber(this.state.company.nr_fone) + ' - CEP: ' + this.state.company.cep, infoStartX, infoStartY + 15);
        doc.text('CPF/CNPJ: ' + this.state.company.cpf_cnpj + ' - IE: ' + this.state.company.inscricao_estadual, infoStartX, infoStartY + 20);

        // Adiciona os campos do meio
        doc.setFont("helvetica", "normal");
        doc.text("Situação:", pageWidth - 160, infoStartY + 5);
        doc.text("CONCLUÍDO", pageWidth - 80, infoStartY + 5, { align: "right" });

        doc.text("Recebedor:", pageWidth - 160, infoStartY + 10);
        doc.text(this.props.receivedBy, pageWidth - 80, infoStartY + 10, { align: "right" });

        doc.text("Fornecedor:", pageWidth - 160, infoStartY + 15);
        doc.text(this.props.supplierList.find(item => item.cd_cadastro === this.props.supplier).fantasia, pageWidth - 80, infoStartY + 15, { align: "right" });
        
        doc.text("Usuário:", pageWidth - 160, infoStartY + 20);
        doc.text(this.props.launchUserList.find(item => item.cd_usuario === this.props.launchUser).nm_usuario, pageWidth - 80, infoStartY + 20, { align: "right" });

        // Adiciona os campos à direita
        doc.setFontSize(10);
        doc.text("Nº:", pageWidth - 70, infoStartY + 5);
        doc.setFont("helvetica", "bold");
        doc.text(this.props.documentNumber.toString(), pageWidth - 25,  infoStartY + 5, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text("Dt. Emissão:", pageWidth - 70, infoStartY + 10);
        doc.text(now.format('DD/MM/YYYY'), pageWidth - 25, infoStartY + 10, { align: "right" });

        doc.text("Dt. Entrada:", pageWidth - 70, infoStartY + 15);
        doc.text(this.props.entryDate.format('DD/MM/YYYY'), pageWidth - 25, infoStartY + 15, { align: "right" });

        doc.text("Documento:", pageWidth - 70, infoStartY + 20);
        doc.text("", pageWidth - 25, infoStartY + 20, { align: "right" });

        const tableStartY = startY + titleHeight + contentHeight + 10

        const Columns = columns.map(col => ({  // Definindo as colunas dinamicamente com base nas props
            header: col[1], // Nome da coluna (segundo item)
            dataKey: col[0], // Nome do campo de dados (primeiro item)
        }))

        const Rows = rows.map(row => { // Definindo as linhas dinamicamente com base nas props
            const rowData = {}
            columns.forEach(col => {
                if(col[0] === 'dt_entrega') {
                    rowData[col[0]] = row[col[0]].format('DD/MM/YYYY') // Adiciona os dados para cada campo
                } else {
                    rowData[col[0]] = row[col[0]] // Adiciona os dados para cada campo
                }
            })
            return rowData
        })

        doc.autoTable({
            startY: tableStartY, // Define o ponto inicial da tabela abaixo do cabeçalho
            head: [Columns.map(col => col.header)], // Cabeçalhos da tabela
            body: Rows.map(row => Columns.map(col => row[col.dataKey])), // Linhas da tabela
            headStyles: {
                fillColor: false, // Remove fundo do cabeçalho
                textColor: 0, // Texto preto
                lineWidth: 0.5, // Borda preta fina
                lineColor: 0 // Cor da borda preta
            },
            bodyStyles: {
                fillColor: [240, 240, 240], // Fundo cinza claro
                textColor: 0, // Texto preto
                lineWidth: 0.5, // Borda preta fina
                lineColor: 0 // Cor da borda preta
            },
            styles: {
                cellPadding: 2, // Espaçamento interno
                fontSize: 10, // Tamanho da fonte
                valign: 'middle', // Alinhamento vertical
                halign: 'center', // Alinhamento horizontal
                lineWidth: 0.5, // Borda preta fina
                lineColor: 0 // Cor da borda preta
            }
        })

        doc.save('relatorio.pdf')   // Salva o PDF com o nome 'relatorio.pdf'
    }

    searchInfos = async () => {
        let config = {
            endpoint: 'sis/empresa/' + this.props.company,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        const r = await defaultRequest(config, form)
        var company = {}
        if (r.status) {
            company = r.data
        }

        if (company.cd_municipio_ibge) {
            const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${r.data.cd_municipio_ibge}`)
            const data = await response.json()
            company.cidade_estado = data?.['regiao-imediata']?.['regiao-intermediaria']?.nome + '/' + data?.['regiao-imediata']?.['regiao-intermediaria']?.UF?.sigla
        }

        this.setState({ isLoading: false, company: company })
    }

    render() {
        const { columns, rows, onClose } = this.props

        return (
            <div style={styles.overlayStyles}>
                <div style={styles.reportContainerStyles}>

                    {!this.state.isLoading ?
                        <>
                            {/* Tabela */}
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        {columns.map(([field, label]) => (
                                            <th key={field} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                                                {label}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {columns.map(([field]) => (
                                                <td key={field} style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                    {field === 'dt_entrega' ?
                                                        row[field].format('DD/MM/YYYY')
                                                    : 
                                                        row[field]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <button onClick={this.generatePDF} style={styles.generateButtonStyles(this.props)}>
                                    Gerar PDF
                                </button>
                                <button onClick={onClose} style={styles.closeButtonStyles(this.props)}>
                                    Fechar
                                </button>
                            </div>
                        </>
                        : <></>}

                </div>
            </div>
        )
    }
}

export default EntryReport;

const styles = {
    overlayStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    reportContainerStyles: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '90%',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    closeButtonStyles: (props) => ({
        backgroundColor: props.colors.redAccent[500],
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '5px',
    }),
    generateButtonStyles: (props) => ({
        backgroundColor: props.colors.greenAccent[500],
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '5px',
    })
}