import React from "react";

import Loading from '../../data/loading.gif';

class LoadingGif extends React.Component {

    render() {
        return ( 
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#00000000',
                zIndex: '100',
            }}>
                <img style={{
                    width: '100px',
                    height: '100px',
                }} src={Loading} alt="Carregando..." />
            </div>
        )
    }
}

export default LoadingGif