import React from "react";

import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectComboField from "../../../components/inputs/MainSelectComboField";
import MainTextField from "../../../components/inputs/MainTextField";

import { Box, Button, Grid, Typography, keyframes } from "@mui/material";
import { getSupplier } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.firstFieldRef = React.createRef()

        this.state = {
            supplier: [],
        }
    }

    componentDidMount() {
        if (this.firstFieldRef.current) {
            this.firstFieldRef.current.focus()
        }
    }

    fetchSupplier = async (supplier) => {
        try {
            const supplierData = await getSupplier(this.props.user.entidade, supplier)
            this.setState({ supplier: supplierData })
        } catch (error) {
            console.error('Error fetching suppliers:', error)
            this.setState({ supplier: [] })
        }
    }
    
    // Necessário adicionar position: 'relative' na Box por fora fo  filtro
    render() {
        const right = keyframes` from {right: -100%; } to { right: 0; }`
        const { t } = this.props
        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', flexDirection: { xs: 'column', lg: 'row' } }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <i style={{ fontSize: '20px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-sliders"></i>
                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px' }}>{t('Filtro avançado:')}</Typography>
                            </Box>
                            <Button sx={{ minWidth: '0', borderRadius: '50%' }} onClick={() => this.props.toggleVisiblePage('isFilterVisible')}>
                                <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                            </Button>
                        </Box>

                        <Grid container columnSpacing={2} rowSpacing={2} sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
                            <Grid item md={12}><MainSelectComboField {...this.props} id='company' value={this.props.company} label={t('Empresa')} placeholder={t('[selecione...]')} optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))} handleChange={this.props.handleChangeText} ref={this.firstFieldRef} /></Grid>
                            <Grid item md={12}><MainSelectComboField {...this.props} id='supplier' value={this.props.supplier} label={t('Fornecedor')} placeholder={t('[digite para buscar as opções...]')} optionsList={this.state.supplier.map(item => ({ label: item.fantasia, value: item.cd_cadastro }))} handleChange={this.props.handleChangeText} delayFunction={this.fetchSupplier} /></Grid>
                            <Grid item lg={6}><MainTextField {...this.props} id='queryCode' value={this.props.queryCode} label={t('Número')} placeholder={t('[0000000]')} handleChange={this.props.handleChangeText} type='number' /></Grid>
                            <Grid item lg={6}></Grid>
                            <Grid item lg={6}><MainDateTimeInput {...this.props} id='inicialDate' value={this.props.inicialDate} label={t('D. Inicial')} placeholder={t('[00/00/0000]')} handleChange={this.props.handleChangeText} type='date' /></Grid>
                            <Grid item lg={6} sx={{ marginBottom: '10px'}}><MainDateTimeInput {...this.props} id='finalDate' value={this.props.finalDate} label={t('D. Final')} placeholder={t('[00/00/0000]')} handleChange={this.props.handleChangeText} type='date' /></Grid>

                            <Grid item md={12} className="main-checkbox-input-filter"><MainCheckBoxInput {...this.props} id='danfe' value={this.props.danfe} label={t('DANFE')} handleChange={this.props.handleChangeText} /></Grid>
                            <Grid item md={12} className="main-checkbox-input-filter"><MainCheckBoxInput {...this.props} id='romaneio' value={this.props.romaneio} label={t('Romaneio')} handleChange={this.props.handleChangeText} /></Grid>
                            <Grid item md={12} className="main-checkbox-input-filter"><MainCheckBoxInput {...this.props} id='pedidoCompra' value={this.props.pedidoCompra} label={t('Pedido de Compra')} handleChange={this.props.handleChangeText} /></Grid>
                            <Grid item md={12} className="main-checkbox-input-filter"><MainCheckBoxInput {...this.props} id='nfse' value={this.props.nfse} label={t('NFS-e')} handleChange={this.props.handleChangeText} /></Grid>
                        </Grid>

                        <Box sx={{ display: 'grid', justifyContent: 'space-between', gap: '50px', gridTemplateColumns: { md: '1fr', lg: '1fr 1fr' }, marginTop: '40px' }}>
                            <MainButton {...this.props} onButtonClick={this.props.clearFields} title={t('Limpar pesquisa')} sx={{ border: `1px solid ${this.props.colors.grey[500]}`, textColor: this.props.colors.grey[500] }} />
                            <MainButton {...this.props} onButtonClick={this.props.getData} title={t('Consultar')} sx={{ background: this.props.colors.orangeAccent[400] }} disabled={this.props.isDisabled} />
                        </Box>

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Filter);

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '450px',
        height: '100%',
        borderRadius: '20px 10px 10px 20px',
        padding: '10px 20px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        animation: `${right} 0.4s`,
    })
}
