import React, { Component } from 'react';

class OnScreenVisible extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.observer = null
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(entries => {
      const [entry] = entries
      if (entry.isIntersecting) {
        this.props.onVisible()
      }
    })

    if (this.ref.current) {
      this.observer.observe(this.ref.current)
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  render() {
    return <div ref={this.ref}></div>
  }
}

export default OnScreenVisible;
