import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import EditableTable from "../../../components/tables/EditableTable";
import DialogAlert from "../../../components/alerts/DialogAlert";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { getAttribute, getDeposit, getMovementType } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class EventTransfer extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.firstFieldRef = React.createRef()

        this.state = {
            screenId: 'Suprimentos_TransferenciaEventos',
            isLoading: true,
            isLoadingTable: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['qt_item', t('Q. na Embalagem')],
                ['qt_transferir', t('Q. Transferir')],
                ['vl_ultima_compra', t('V. Ult Compra')],
                ['vl_total', t('V. Total')],
                ['qt_estoque', t('Q. Estoque')],
                ['vl_unitario', t('V. Unitário')],
            ],
            tableTotalSize: 0,

            depositList: [],
            eventList: [],
            eventRFPList: [],
            seriesList: [],
            movementTypeList: [],
            attributeList: [],

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            releaseDate: dayjs(),

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchCompanyStock()
        this.setState({
            tableList: this.props.newTableList,
            tableTotalSize: this.props.newTableList.length,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.event && prevState.event !== this.state.event) {
            this.fetchEventRFP()
        }
        if (prevState.event !== this.state.event && !this.state.event) {
            this.setState({ eventRFPList: [] })
        }
        if (prevState.tableList !== this.state.tableList) {
            this.calculateTable()
        }

    }

    calculateTable = () => {
        const updateTable = this.state.tableList.map(item => {
            const operation = item.at_operacao

            const qtEmb = item.qt_item || 0
            const qtDigitado = item.qt_transferir || 0
            const vlUnitario = item.vl_unitario || 0

            const qtEstoque = operation === 228 ? qtDigitado * qtEmb : qtDigitado / qtEmb || 0

            const vlTotal = vlUnitario * qtDigitado || 0

            return { ...item, qt_estoque: qtEstoque, vl_total: vlTotal }
        })

        if (JSON.stringify(this.state.tableList) !== JSON.stringify(updateTable)) {
            setTimeout(() => {
                this.setState({ tableList: updateTable, tableTotalSize: updateTable.length })
            }, 100)
        }
    }

    fetchCompanyStock = () => {
        let config = {
            endpoint: `everlojas/transferenciaestoque/${this.state.company}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data) {
                this.setState({
                    serie: r.data.cd_serie,
                    movementType: r.data.id_movimentoestoquetipo,
                    originMovementTypeId: r.data.id_moviestoquetipo_romaneio,
                }, () => this.fetchStockMovementType())
            } else {
                console.error('Erro ao retornar Série e Tipo de Movimento')
            }
        })
    }

    fetchData = async () => {
        try {
            const depositData = getDeposit(this.state.company, this.props.user.entidade)
            const eventData = this.fetchEvent()
            const seriesData = this.fetchSeries()
            const movementTypeData = getMovementType(this.props.user.entidade)
            const attributeData = getAttribute(this.props.user.entidade)

            const [depositList, eventList, seriesList, movementTypeList, attributeList] = await Promise.all([depositData, eventData, seriesData, movementTypeData, attributeData])

            const filteredMovementTypeList = movementTypeList.filter(item => item.at_situacao === 1 && item.at_movimentacao === 143)

            const foundMovementType = filteredMovementTypeList.find(item => item.id_movimentoestoquetipo === this.state.movementType)
            const foundAttribute = foundMovementType ? attributeList.find(item => item.cd_atributo === foundMovementType.at_movimentacao) : null

            this.setState({
                depositList: depositList,
                eventList: Array.isArray(eventList) ? eventList : [],
                seriesList: seriesList,
                movementTypeList: filteredMovementTypeList,
                attributeList: attributeList,

                movement: foundAttribute ? foundAttribute.cd_atributo : '',

                isLoading: false
            }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                depositList: [],
                eventList: [],
                seriesList: [],
                movementTypeList: [],
                attributeList: [],

                isLoading: false
            })
        }
    }

    fetchEvent = async () => {
        let config = {
            endpoint: `componente/proposta`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'cd_empresa': this.state.company,
            'at_situacao_lista': 891,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return []
        }
    }

    fetchEventRFP = () => {
        let config = {
            endpoint: `componente/propostarfp`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'id_proposta': this.state.event,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    eventRFPList: r.data,
                })
            } else {
                this.setState({
                    eventRFPList: [],
                })
            }
        })
    }

    fetchSeries = async () => {
        let config = {
            endpoint: `componente/usuarioserie/classe`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'at_classe': 290,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o serie:', error)
            return []
        }
    }

    fetchStockMovementType = () => {
        let config = {
            endpoint: `adm/movimentoestoquetipo/${this.state.movementType}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.setState({
                    stockMovementType: r.data,
                })
            } else {
                console.error('Erro ao retornar Tipo de Movimento de Estoque')
            }
        })
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEditRow = (updatedList, method, id) => {
        if (method === 'edit') {
            this.setState({
                tableList: updatedList
            })
        }
        if (method === 'delete') {
            this.setState({
                tableList: updatedList,
                tableTotalSize: updatedList.length
            })
        }
    }

    sendEventTransfer = () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!this.state.event || !this.state.eventRFP || !this.state.releaseDate) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        const hasInvalidItem = this.state.tableList.some(item => !('qt_transferir' in item) || item.qt_transferir === 0)

        if (hasInvalidItem) {
            this.showAlert('Há items não preenchidos na tabela.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let config = {
            endpoint: `adm/movimentoestoque?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'cd_empresa': this.state.company,
            'cd_serie': 'MOV',
            'dt_movimento': this.state.releaseDate,
            'cd_deposito_origem': this.state.deposit,
            'observacao': this.state.observation,
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_usuario_responsavel': this.props.user.data.cd_usuario,
            'at_situacao': 1,
            'id_movimentoestoquetipo_origem': this.state.originMovementTypeId || null, // vai vir originMovementTypeId

            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map((item, index) => {
                    return {
                        'id_item': item.id_item,
                        'id_embalagem': item.id_embalagem,
                        'qt_embalagem': item.qt_transferir,
                        'vl_embalagem_unitario': item.vl_ultima_compra,
                        'vl_embalagem_total': item.vl_total,
                        'qt_convertido': item.qt_estoque,
                        'vl_convertido_unitario': item.vl_unitario,
                        'at_movimentacao': 142,
                        'fl_movimento_custo': this.state.stockMovementType?.fl_movimento_custo || 0,    // recebe do fetchStockMovementType
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({ eventNumber: r.data.nr_documento }, () =>
                    this.sendEvent(r.data.nr_documento)
                )
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    sendEvent = (nr_documento) => {
        let sum = this.state.tableList.reduce((acc, item) => acc + item.vl_total, 0)
        let dreConta = this.state.eventRFPList.find(item => item.nr_propostarfp === this.state.eventRFP).cd_dreconta

        let config = {
            endpoint: `gpro/propostarfpdespesa?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'dt_lancamento': this.state.releaseDate,
            'at_origem': 935,
            'dt_documento': this.state.releaseDate,
            'nr_documento': nr_documento,   // nr_documento que vem do adm - movimentoestoque
            'cd_dreconta': dreConta,   // vem na proposta RFP
            'cd_serie': this.state.serie,
            'vl_documento': sum, // soma do vl_total das linhas da tabela
            'cd_fornecedor': this.state.company,
            'at_movimentaestoque': 142,
            'ds_observacao': this.state.observation,
            'id_propostarfp': this.state.eventRFP,
            'cd_usuario': this.props.user.data.cd_usuario,
            'cd_empresa': null,
            'nr_parcela': null,
            'cd_propostaPDV': null,
            'id_documento': null,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendEventTransfer}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Nr. Documento')}: ${this.state.eventNumber}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isEventTransferVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Transferência de Eventos')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={`${t('Itens')}:`}
                                        content={
                                            <>
                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    allowEditOnRow
                                                    id='table_event_transfer'
                                                    rowId='id_item'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    totalSize={this.state.tableTotalSize}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    onEditRow={this.handleEditRow}
                                                    isLoading={this.state.isLoadingTable}
                                                    editableFields={['qt_transferir']}
                                                    extraColumnsConfig={
                                                        {
                                                            'qt_item': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_transferir': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_ultima_compra': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_total': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'qt_estoque': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                            'vl_unitario': {
                                                                'type': 'number',
                                                                'maxDecimals': '4',
                                                            },
                                                        }
                                                    }
                                                    customRowSize={
                                                        {
                                                            'cd_item': 80,
                                                            'ds_item': 210,
                                                            'sg_unidademedida': 60,
                                                        }
                                                    }
                                                />
                                            </>
                                        }
                                    />

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 15% 25% 24%' }, margin: { md: '10px 0 0 0', lg: '10px 40px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='company'
                                            value={this.state.company}
                                            label={t('Empresa')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                            disabled={true}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id='releaseDate'
                                            value={this.state.releaseDate}
                                            label={t('D. Lançamento')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeText}
                                            type='date'
                                            required
                                            ref={this.firstFieldRef}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='event'
                                            value={this.state.event}
                                            label={t('Evento')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.eventList.map(item => ({ label: item.ds_proposta, value: item.id_proposta }))}
                                            handleChange={this.handleChangeText}
                                            disabled={this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false}
                                            required
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='eventRFP'
                                            value={this.state.eventRFP}
                                            label={t('RFP Evento')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.eventRFPList.map(item => ({ label: `${item.nr_propostarfp} - ${item.ds_rfpatividade} - ${item.ds_rfptipo}`, value: item.nr_propostarfp }))}
                                            handleChange={this.handleChangeText}
                                            disabled={this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false}
                                            required
                                        />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '28% 28% 28% 15%' }, margin: { md: '0', lg: '10px 40px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='deposit'
                                            value={this.state.deposit}
                                            label={t('Depósito')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.props.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='serie'
                                            value={this.state.serie}
                                            label={t('Série')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.seriesList.map(item => ({ label: item.ds_serie, value: item.cd_serie }))}
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='movementType'
                                            value={this.state.movementType}
                                            label={t('Tipo de Movimento')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.movementTypeList.map(item => ({ label: item.ds_movimentoestoquetipo, value: item.id_movimentoestoquetipo }))}
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='movement'
                                            value={this.state.movement}
                                            label={t('Movimentação')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                            optionsList={this.state.attributeList.map(item => ({ label: item.pt_br, value: item.cd_atributo }))}
                                            disabled={true}
                                        />
                                    </Box>

                                    <Box sx={{ marginTop: '10px' }}>
                                        <MainTextField
                                            {...this.props}
                                            id='eventDescription'
                                            value={(this.state.eventList.find(item => item.id_proposta === this.state.event) || {}).ds_proposta || ''}
                                            label={t('Descrição do Evento')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                            disabled={true}
                                        />
                                    </Box>
                                </Box>

                                {this.props.user.configs[0].fl_utiliza_gestao_eventos === "N"
                                    ? <Typography sx={{ width: '100%', textDecoration: 'underline', fontSize: '14px', color: 'red' }}>A Entidade está com a transferência de eventos desabilitada</Typography>
                                    : <></>
                                }

                                <Box sx={{ display: 'grid', gap: '20px', alignItems: 'end', gridTemplateColumns: { md: '100%', lg: '90% 10%' }, margin: { md: '10px 0 10px 0', lg: '10px 60px 10px 0' } }}>
                                    <MainTextField
                                        {...this.props}
                                        id='observation'
                                        value={this.state.observation}
                                        label={t('Observação')}
                                        placeholder={t('[digite...]')}
                                        handleChange={this.handleChangeText}
                                    />
                                    <MainButton
                                        {...this.props}
                                        sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                        title={t('Transferir')}
                                        onButtonClick={() => this.setState({ isDialogOpen: true })}
                                        disabled={this.state.isDisabled || this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false}
                                    />
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(EventTransfer);

const right = keyframes` from {right: -100%; } to {right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}