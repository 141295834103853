import React, { forwardRef } from "react";
import { Button, Grid, TextField, Box } from '@mui/material';

class MainNumericKeyboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '0', '00'],
        }
    }

    componentDidUpdate() {
        if (this.props.shouldSelectAllValue && this.props.value && !this.props.focusedInput) {
            this.props.innerRef.current.select()
        }
    }

    handleNumberClick = (number) => () => {
        let newValue

        if (this.props.shouldSelectAllValue === true) {
            newValue = ''
        } else {
            newValue = this.props.value || ''
        }

        newValue += number
        newValue = newValue.replace('.', ',') // Substitui ponto por vírgula para exibição

        this.props.handleChange(newValue)
    }


    handleClear = () => {
        this.props.handleChange('')
    }

    handleInsert = () => {
        this.props.onButtonClick(this.props.value)
        // this.props.handleChange('')
    }

    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            this.handleInsert()
        }
    }


    render() {
        const { t } = this.props

        return (
            <Box sx={{ maxWidth: 250, margin: 'auto' }} {...this.props}>
                <TextField
                    onClick={() => this.setState({ shouldSelectAllValue: false })}
                    onKeyUp={this.handleKeyPress}
                    inputRef={this.props.innerRef ?? undefined}
                    fullWidth
                    onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace('.', ',') // Substitui ponto por vírgula para exibição
                        // Permite digitar apenas números e vírgulas
                        if (/^\d*,?\d{0,4}$/.test(value)) {
                            this.props.handleChange(value.replace(',', '.')) // Substitui vírgula por ponto internamente
                        }
                    }}
                    value={this.props.value.replace('.', ',')} // Mostra a vírgula ao invés de ponto
                    variant="outlined"
                    placeholder="000"
                    inputProps={{
                        style: {
                            textAlign: 'center',
                            fontSize: '16px',
                            boxShadow: `2px 2px 2px ${this.props.colors.custom['boxShadow']}`,
                            borderRadius: '5px'
                        }
                    }}
                    sx={{ "& fieldset": { borderRadius: '5px' } }}
                />

                <Grid container spacing={1} mt={1}>
                    {this.state.numbers.map((number, index) => (
                        <Grid item xs={4} key={index}>
                            <Button
                                sx={{ height: '55px', fontSize: '14px', boxShadow: `2px 2px 1px ${this.props.colors.custom['boxShadow']}` }} // botões numéricos
                                fullWidth
                                variant="outlined"
                                onClick={this.handleNumberClick(number)}
                            >
                                {number}
                            </Button>
                        </Grid>
                    ))}
                    <Grid item xs={6}>
                        <Button sx={{ height: '40px', boxShadow: `2px 2px 1px ${this.props.colors.custom['boxShadow']}`, color: this.props.colors.blueAccent[500], borderColor: 'blue' }} fullWidth variant="outlined" onClick={this.handleClear}>
                            C
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button sx={{ height: '40px', boxShadow: `2px 2px 1px ${this.props.colors.custom['boxShadow']}`, color: this.props.colors.custom['white'] }} fullWidth variant="contained" onClick={this.handleInsert}>
                            {t('Inserir')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const ForwardMainNumericKeyboard = forwardRef((props, ref) => {
    return <MainNumericKeyboard {...props} innerRef={ref} />;
})

export default ForwardMainNumericKeyboard;