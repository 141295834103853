import React from "react";

import MainSelectField from "../../../../components/inputs/MainSelectField";
import MainTextField from "../../../../components/inputs/MainTextField";

import { Box, Button, Divider, Typography } from "@mui/material";


class TablePopup extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        const { t } = this.props

        const styleTitle = { borderBottom: `1px solid ${this.props.colors.grey[800]}`, padding: '5px', fontSize: '16px', fontWeight: '500' }
        const styleInput = { borderBottom: `1px solid ${this.props.colors.grey[800]}`, padding: '5px', fontSize: '16px', fontWeight: '300' }

        return (
            <Box style={styles.popUpOverlay}>
                <Box style={styles.popUpContainer(this.props)}>

                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                        <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                            {`${t('Memória de Cálculo')} - 0000`}:
                        </Typography>

                        <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                        <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.props.closePopup()}>
                            <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                        </Button>
                    </Box>

                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '35% 30% 10% 25%' }, margin: { md: '0', lg: '5px 100px 0 0' } }}>
                        <MainSelectField
                            {...this.props}
                            id=''
                            value={this.props.company}
                            label={t('Empresa')}
                            placeholder={t('[selecione...]')}
                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                            disabled={true}
                        />
                        <MainSelectField
                            {...this.props}
                            id=''
                            value={this.props.deposit}
                            label={t('Depósito')}
                            placeholder={t('[selecione...]')}
                            optionsList={this.props.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                            disabled={true}
                        />
                        <MainTextField
                            {...this.props}
                            id=''
                            value={this.props.tableItem.cd_item}
                            label={t('Item')}
                            placeholder={t('[digite...]')}
                            type='number'
                            disabled={true}
                        />
                        <MainTextField
                            {...this.props}
                            id=''
                            value={this.props.tableItem.ds_item}
                            label={t('Descrição do Item')}
                            placeholder={t('[digite...]')}
                            disabled={true}
                        />
                    </Box>

                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            padding: '5px 15px',
                            borderRadius: '20px',
                            background: this.props.colors.primary[400],
                            margin: { md: '0', lg: '20px 40px 10px 0' }
                        }}
                    >
                        {t('Detalhes')}:
                    </Typography>

                    <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: { xs: '100%', lg: '25% 25% 25% 25%' }, margin: { md: '0', lg: '0 80px 0 0' } }}>
                        <Typography sx={styleTitle}>{t('Item')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.cd_item || '[0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Consumo Médio')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_consumo_medio || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Descrição do Item')}:</Typography>
                        <Typography sx={{ ...styleInput, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.props.tableItem.ds_item || `[${t('Descrição do Item')}]`}</Typography>

                        <Typography sx={styleTitle}>{t('Consumo Pico')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_consumo_pico || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('UM')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.sg_unidademedida || '[UM]'}</Typography>

                        <Typography sx={styleTitle}>{t('Previsão de Consumo')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_prev_consumo || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Embalagem Aceita Decimal')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.fl_decimal_quantidade ? (this.props.tableItem.fl_decimal_quantidade === 'S' ? 'Sim' : 'Não') : '[Sim/Não]'}</Typography>

                        <Typography sx={styleTitle}>{t('Calculada')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_calculada || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Período de Análise')}:</Typography>
                        <Typography sx={styleInput}>{`[${this.props.initialDate ? this.props.initialDate.format('DD/MM/YYYY') : '00/00/0000'}] - [${this.props.finalDate ? this.props.finalDate.format('DD/MM/YYYY') : '00/00/0000'}]`}</Typography>

                        <Typography sx={styleTitle}>{t('Estoque Futuro')} (=):</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_estoque_futuro || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Dias p/ Programação de Estoque')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_dias_compra || '[000]'}</Typography>

                        <Typography sx={styleTitle}>% {t('Variação')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.pr_variacao || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Dias p/ Análise de Consumo')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_dias_analise || '[000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Perdas')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_perdas || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Q. Pedida')} (+):</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_pedida || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Vendida')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_vendida || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Saldo Atual')} (+):</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_saldo_atual || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Estoque Min.')}:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_minima_estoque || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Q. Solicitada')} (+):</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_solicitada || '[00,0000]'}</Typography>

                        <Typography sx={styleTitle}>{t('Sugestão')}/UM:</Typography>
                        <Typography sx={styleInput}>{this.props.tableItem.qt_sugerida.toFixed(4).replace('.', ',') || '[00,0000]'}</Typography>
                    </Box>

                </Box>
            </Box>
        )
    }
}

export default TablePopup;

const styles = {
    popUpOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popUpContainer: (props) => ({
        backgroundColor: 'white',
        borderRadius: '10px',
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        padding: '20px 30px',
        width: '70%',
        height: '70vh',
        maxHeight: '90vh',
        overflowY: 'auto',
    }),
}