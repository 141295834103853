import React from "react";
import dayjs from "dayjs";

import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import { Box, Button, Divider, Grid, Typography, keyframes } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { getCostCenter, getPaymentConditions } from "../../../utils/request/requestSelects";
import { handleChangeText } from "../../../utils/handleChange";
import { withTranslation } from "react-i18next";


class Nfse extends React.Component {
    constructor(props) {
        super(props)

        const { t } = this.props

        this.selectRef = React.createRef()

        this.state = {
            screenId: 'ProgramacaoEntrada_Nfse',
            isLoading: true,
            isLoadingTable: false,

            alertMessage: '',
            alertType: '',
            showAlert: false,

            apportionmentByCostCenter: true,

            costCenterList: [],
            paymentConditonsList: [],

            apportionmentValue: '',
            apportionmentPercent: '',
            costCenter: null,

            perform: 0,
            accumulated: 0,
            difference: 0,
            percentPerformed: 0,

            tableList: [],
            tableColumns: [
                ['cd_centrocustoconta', t('Centro de Custo Conta')],
                ['pt_rateio', t('% Rateio')],
                ['vl_rateio', t('V. Rateio')],
            ],
            tableTotalSize: '',
        }
    }

    componentDidMount() {
        this.fetchPaymentConditions()

        let config = {
            endpoint: 'fae/nfserecebida/' + this.props.lineId,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {

                r.data.dt_vencimento = dayjs()

                this.setState({ nfse: r.data },
                    () => this.getSituation()
                )
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTable: false,
                })
            }
        })
    }

    addApportionment = () => {
        if (!this.state.costCenter || !this.state.apportionmentPercent || !this.state.apportionmentValue) {
            this.showAlert("Preencha todos os campos obrigatórios (*)", "error")
            return
        }

        if (this.state.tableList.find((option) => this.state.costCenter === option.id_centrocustoconta)) {
            this.showAlert("Centro de custo já escolhido", "error")
            return
        }
        const costCenterOption = this.state.costCenterList.find((option) => option.cd_centrocustoconta === this.state.costCenter)
        let newRow = {
            'cd_centrocustoconta': this.state.costCenter + ' - ' + costCenterOption.ds_centrocustoconta,
            'id_centrocustoconta': this.state.costCenter,
            'vl_rateio': Number(this.state.apportionmentValue).toFixed(6),
            'pt_rateio': Number(this.state.apportionmentPercent).toFixed(6)
        }
        this.setState({
            tableList: [...this.state.tableList, newRow],
            costCenter: '',
            apportionmentPercent: '',
            apportionmentValue: ''
        }, () => this.updateApportionmentValues())
    }

    fetchCostCenter = async (search = '') => {
        try {
            const costCenterData = await getCostCenter(this.props.user.entidade, this.state.nfse.cd_empresa, search)
            this.setState({ costCenterList: costCenterData })
        } catch (error) {
            console.error('Error fetching suppliers:', error)
            this.setState({ costCenterList: [] })
        }
    }

    fetchPaymentConditions = async () => {
        try {
            const paymentConditons = await getPaymentConditions(this.props.user.entidade, 24)
            this.setState({ paymentConditonsList: paymentConditons })
        } catch (error) {
            console.log('Error fetching paymentConditonsList:', error)
            this.setState({ paymentConditonsList: [] })
        }
    }

    getSituation = () => {
        let config = {
            endpoint: `gco/atributo/${this.state.nfse.at_situacao}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const language = this.props.user.data.cd_idioma.toLowerCase()
                this.setState({
                    status: r.data[language],

                    isLoading: false
                }, () => this.updateApportionmentValues())
            } else {
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    handleChangeApportionmentValue = (event) => {
        const totalValue = parseFloat(this.state.nfse.vl_servico)
        let numberValue = 0
        let percentValue = 0
        let value = event.target.value.replace(',', '.')

        if (event.target.id === 'apportionmentValue') {
            numberValue = parseFloat(value) || 0
            percentValue = (numberValue / totalValue) * 100
            this.setState({
                apportionmentValue: value,
                apportionmentPercent: percentValue.toFixed(6)
            })
        } else {
            percentValue = parseFloat(value) || 0
            numberValue = (percentValue / 100) * totalValue
            this.setState({
                apportionmentValue: numberValue.toFixed(6),
                apportionmentPercent: value
            })
        }
    }

    handleChangeText = (event) => {
        const { id, value } = event.target

        if (id === 'apportionmentByCostCenter') {
            this.fetchCostCenter()
        }
        if (id === 'dh_emissao' || id === 'cd_condicaovendacompra' || id === 'ds_servico' || id === 'observacao') {
            handleChangeText(this.state.nfse, id, value, () => this.forceUpdate())
        }
        this.setState({ [id]: value })
    }

    handleChangeTextObject = (event) => {
        handleChangeText(this.state.nfse, event.target.id, event.target.value, () => this.forceUpdate())
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.addApportionment()
        }
    }

    onCostCenterTableEdit = (updatedList, method, id) => {
        if (method === 'delete') {
            // Atualiza a tableList com os valores numéricos originais (com pontos)
            const originalUpdatedList = updatedList.map(row => ({
                ...row,
                pt_rateio: parseFloat(row.pt_rateio.replace(',', '.')),
                vl_rateio: parseFloat(row.vl_rateio.replace(',', '.')),
            }))

            this.setState({
                tableList: originalUpdatedList
            }, () => this.updateApportionmentValues());
        }
    }

    sendCostCenter = () => {
        this.setState({ isLoading: true, isDialogOpen: false })

        if (!this.state.nfse.dt_vencimento || !this.state.nfse.cd_condicaovendacompra) {
            this.showAlert("Preencha todos os campos obrigatórios (*)", "error")
            this.setState({ isLoading: false })
            return
        }

        this.state.tableList.map((row, index) => {
            let config = {
                endpoint: 'fae/nfserecebidacentrocusto?x-Entidade=' + this.props.user.entidade,
                method: 'post'
            }
            let form = {
                "id_nfserecebida": this.state.nfse.id_nfserecebida,
                "cd_centrocusto": this.state.nfse.cd_centrocusto,
                "cd_centrocustoconta": row.id_centrocustoconta,
                "pr_rateio": Number(row.pt_rateio),
                "vl_rateio": Number(row.vl_rateio)

            }
            defaultRequest(config, form).then((r) => {
                if (r.status) {
                    this.sendNfse('A')
                } else {
                    const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                    this.showAlert(cleanMessage, "error")
                    this.setState({ isLoading: false })
                }
            })
        })
    }

    sendNfse = async (status) => {
        this.setState({ isCancelDialogOpen: false, isLoading: true })

        if (status === 'D' && !this.state.nfse.observacao) {
            this.showAlert('O campo observação precisa ser preenchido.', 'error')
            this.setState({ isLoading: false })
            return
        }

        let response = await checkSendDate(this.state.nfse.dt_vencimento, this.state.nfse.cd_empresa, this.props.user.entidade)

        if (!response || !response.status) {
            this.showAlert(response.message ?? '', response.msg_type === 1 ? 'error' : 'warning')
            this.setState({ isLoading: false })

            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: 'fae/processarnfse?x-Entidade=' + this.props.user.entidade,
            method: 'post'
        }
        let form = {
            "ds_origem_registro": this.state.screenId,
            "cd_usuario": this.props.user.data.cd_usuario,
            "id_nfserecebida": this.state.nfse.id_nfserecebida,
            "tipo": status, // A = Aprovação ou D = Denegação
            "dt_vencimento": this.state.nfse.dt_vencimento,
            "cd_condicaovendacompra": this.state.nfse.cd_condicaovendacompra,
            "cd_centrocusto": this.state.nfse.cd_centrocusto,
            "cd_centrocustoconta": this.state.nfse.cd_centrocustoconta,
            "observacao": this.state.nfse.observacao,
            "tp_mensagem": null,
            "mensagem": null
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({ isLoading: false, isConfirmationOpen: true })
            } else {
                this.showAlert(r.message ?? '', r.msg_type === 1 ? 'error' : 'warning')
                this.setState({ isLoading: false })
                return
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    updateApportionmentValues = () => {
        this.fetchCostCenter()
        var perform = this.state.nfse.vl_servico
        var accumulated = 0
        var difference = 0
        var percentPerformed = 0

        this.state.tableList.forEach(element => {
            accumulated += Number(element.vl_rateio)
        })

        difference = perform - accumulated
        percentPerformed = (accumulated / perform) * 100
        if (percentPerformed.toFixed(2) === 100) {
            var table = this.state.tableList
            table[table.length - 1].vl_rateio = (parseFloat(table[table.length - 1].vl_rateio) + parseFloat(difference)).toFixed(6)
            accumulated += difference
            difference = 0
        }
        this.setState({
            perform: perform.toFixed(2),
            accumulated: accumulated.toFixed(2),
            difference: difference.toFixed(2),
            percentPerformed: percentPerformed.toFixed(2)
        }, () => this.selectRef.current.focus())
    }

    // Necessário adicionar position: 'relative' na Box por fora do filtro
    render() {
        const { t } = this.props

        const formattedTableList = this.state.tableList.map(row => ({
            ...row,
            pt_rateio: Number(row.pt_rateio).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
            vl_rateio: Number(row.vl_rateio).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
        }))

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendCostCenter}                          // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type='cancel'
                            isOpen={this.state.isCancelDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={() => this.sendNfse('D')}
                            onClose={() => this.setState({ isCancelDialogOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('NFS-e')}: ${this.state.nfse?.nr_nota}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => { this.props.toggleVisiblePage('isNFSEVisible'); this.props.onEntrySchedulingTableChange() }}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isNFSEVisible')}
                        />

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Recebimento de NFS-e Nota Fiscal de Serviço') + ':'}</Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 60px 0 0' } }}>
                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '9% 6% 15% 22% 20% 15% 13%' }, marginRight: { md: '0', lg: '120px' } }}>
                                        <MainTextField
                                            {...this.props}
                                            id='nr_nota'
                                            value={this.state.nfse.nr_nota}
                                            label={t('NFS-e')}
                                            placeholder={t('[0000000]')}
                                            type='number'
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='cd_serie'
                                            value={this.state.nfse.cd_serie}
                                            label={t('Série')}
                                            placeholder={t('[XX]')}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='cd_verificador'
                                            value={this.state.nfse.cd_verificador}
                                            label={t('Autorização')}
                                            placeholder={t('[autorização]')}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id=''
                                            value={this.state.nfse.razao_tomador}
                                            label={t('Destinatário')}
                                            placeholder={t('[destinatário]')}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id=''
                                            value={this.state.nfse.razao_prestador}
                                            label={t('Prestador de Serviço')}
                                            placeholder={t('[prestador de serviço]')}
                                            disabled={true}
                                        />
                                        <MainDateTimeInput
                                            {...this.props}
                                            id=''
                                            value={this.state.nfse.dh_emissao}
                                            label={t('D. Emissão')}
                                            placeholder={t('[00/00/0000]')}
                                            type='date'
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='status'
                                            value={this.state.status}
                                            label={t('Situação')}
                                            placeholder={t('[status]')}
                                            disabled={true}
                                        />
                                    </Box>

                                    <Typography {...this.props} sx={{ color: this.props.colors.blueAccent[100], fontSize: '16px', fontWeight: '600', margin: '30px 0 15px 0' }}>{t('Conferência de Entrada')}</Typography>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: '45% 55%' }}>

                                        <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { xs: '100%', lg: '40% 60%' }, marginRight: '50px' }}>
                                            <MainTextField
                                                {...this.props}
                                                id=''
                                                value={this.state.nfse.cd_item_lista_servico}
                                                label={t('Serviço')}
                                                placeholder={t('[0000]')}
                                                disabled={true}
                                            />
                                            <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { xs: '100%', lg: '50% 50%' }, marginRight: { xs: '0', lg: '20px' } }}>
                                                <MainTextField
                                                    {...this.props}
                                                    id=''
                                                    value={this.state.nfse.vl_servico}
                                                    label={t('V. Serviço')}
                                                    placeholder={t('[00,00]')}
                                                    type='number'
                                                    disabled={true}
                                                />
                                                <MainTextField
                                                    {...this.props}
                                                    id=''
                                                    value={this.state.nfse.vl_ir_servico}
                                                    label={t('V. Imposto Retido')}
                                                    placeholder={t('[00,00]')}
                                                    type='number'
                                                    disabled={true}
                                                />
                                            </Box>
                                            <MainDateTimeInput
                                                {...this.props}
                                                id='dt_vencimento'
                                                value={this.state.nfse.dt_vencimento}
                                                label={t('D. Vencimento')}
                                                placeholder={t('[00/00/0000]')}
                                                handleChange={this.handleChangeTextObject}
                                                type='date'
                                                required
                                            />
                                            <MainSelectField
                                                {...this.props}
                                                id='cd_condicaovendacompra'
                                                value={this.state.nfse.cd_condicaovendacompra}
                                                label={t('Condições de Pagamento')}
                                                placeholder={t('[selecione...]')}
                                                optionsList={this.state.paymentConditonsList.map(item => ({ label: item.ds_condicaovendacompra, value: item.cd_condicaovendacompra }))}
                                                handleChange={this.handleChangeTextObject}
                                                required
                                            />
                                        </Box>

                                        <MainTextField
                                            {...this.props}
                                            id='ds_servico'
                                            value={this.state.nfse.ds_servico}
                                            label={t('Descrição')}
                                            placeholder={t('[digite...]')}
                                            multiline={true}
                                            minRows='4'
                                            disabled={true}
                                        />

                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '20px' }}>
                                        <MainTextField {...this.props} id='observacao' value={this.state.nfse.observacao} label={t('Observação')} placeholder={t('[digite...]')} handleChange={this.handleChangeTextObject} />

                                        <Box sx={{ display: 'flex', marginTop: '10px', flexDirection: { xs: 'column', lg: 'row' } }}>
                                            <Divider sx={{ flexGrow: 1, margin: 'auto' }} />
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '20px', display: 'grid', gap: '50px', gridTemplateColumns: { xs: '100%', lg: '75% 25%' }, marginRight: '50px' }}>
                                        <Grid container columnSpacing={2} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                            <Grid item md={6}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='costCenter'
                                                    ref={this.selectRef}
                                                    value={this.state.costCenter}
                                                    label={t('Centro de Custo Conta')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.costCenterList.map(item => ({ label: item.ds_centrocustoconta, value: item.cd_centrocustoconta }))}
                                                    handleChange={this.handleChangeText}
                                                    delayFunction={this.fetchCostCenter}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <MainTextField
                                                    {...this.props}
                                                    id='apportionmentPercent'
                                                    value={this.state.apportionmentPercent}
                                                    label={t('% Rateio')}
                                                    placeholder={t('[000%]')}
                                                    handleChange={this.handleChangeApportionmentValue}
                                                    type='number'
                                                    maxValue={100}
                                                    maxDecimals={6}
                                                    onKeyUp={this.handleEnterKeyUp}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <MainTextField
                                                    {...this.props}
                                                    id='apportionmentValue'
                                                    value={this.state.apportionmentValue}
                                                    label={t('V. Rateio')}
                                                    placeholder={t('[000,00]')}
                                                    handleChange={this.handleChangeApportionmentValue}
                                                    type='number'
                                                    maxDecimals={6}
                                                    onKeyUp={this.handleEnterKeyUp}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item md={2}><MainButton {...this.props} title={t('Incluir')} onButtonClick={this.addApportionment} sx={{ textColor: this.props.colors.blueAccent[100], border: `1px solid ${this.props.colors.blueAccent[100]}`, marginTop: '20px' }} /></Grid>

                                            <Grid item md={12}>
                                                <EditableTable
                                                    {...this.props}
                                                    allowEdit
                                                    noEditButton
                                                    id='centro'
                                                    height='30vh'
                                                    data={formattedTableList}
                                                    columns={this.state.tableColumns}
                                                    rowId='cd_centrocustoconta'
                                                    totalSize={this.state.tableTotalSize}
                                                    onEditRow={this.onCostCenterTableEdit}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    isLoading={this.state.isLoadingTable}
                                                    extraColumnsConfig={
                                                        {
                                                            'pt_rateio': {
                                                                'type': 'number',
                                                            },
                                                            'vl_rateio': {
                                                                'type': 'number'
                                                            }
                                                        }
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ marginTop: '20px' }}>
                                            <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: 'bold', fontSize: '16px', background: this.props.colors.primary[400], padding: '10px 10px', borderRadius: '25px' }}>{t('Resumo') + ':'}</Typography>
                                            <Box sx={{ marginTop: '10px', display: 'grid', gap: '10px', gridTemplateColumns: '50% 50%', justifyContent: 'center', padding: '0 40px' }}>
                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '15px', margin: 'auto' }}>{t('A realizar:')}</Typography>
                                                <MainTextField {...this.props} id='' value={this.state.perform} label='' placeholder={t('[000,00]')} type='number' disabled={true} />

                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '15px', margin: 'auto' }}>{t('Acumulado') + ':'}</Typography>
                                                <MainTextField {...this.props} id='' value={this.state.accumulated} label='' placeholder={t('[000,00]')} type='number' disabled={true} />

                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '15px', margin: 'auto' }}>{t('Diferença') + ':'}</Typography>
                                                <MainTextField {...this.props} id='' value={this.state.difference} label='' placeholder={t('[000,00]')} type='number' disabled={true} />

                                                <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '15px', margin: 'auto' }}>{t('% Realizada:')}</Typography>
                                                <MainTextField {...this.props} id='' value={this.state.percentPerformed} label='' placeholder={t('[000,00]')} type='number' disabled={true} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                        <MainButton
                                            {...this.props}
                                            title={t('Recusar Pagamento')}
                                            onButtonClick={() => this.setState({ isCancelDialogOpen: true })}
                                            sx={{ background: this.props.colors.redAccent[700], marginTop: '20px', width: '200px' }}
                                        />
                                        <MainButton
                                            {...this.props}
                                            sx={{ background: this.props.colors.greenAccent[200], marginTop: '20px', width: '200px' }}
                                            title={t('Confirmar Recebimento')}
                                            onButtonClick={() => this.setState({ isDialogOpen: true })}
                                            disabled={(this.state.apportionmentByCostCenter && Number(this.state.percentPerformed) !== 100.00) || this.state.isDisabled ? true : false}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Nfse);

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '10px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}