export const menuList = [
    {
        idmenu: '1',
        descmenu: 'Programação de Entrada',
        icone: <i style={{ color: '#011F3A'}} className="fa-solid fa-box-open"></i>
    },
    {
        idmenu: '2',
        descmenu: 'Suprimentos',
        icone: <i style={{ color: '#011F3A'}} className="fa-solid fa-cart-flatbed"></i>
    },
    // {
    //     idmenu: '3',
    //     descmenu: 'Ordem de Porcionamento',
    //     icone: <i className="fa-solid fa-shop"></i>
    // },
    // {
    //     idmenu: '4',
    //     descmenu: 'Lançamentos',
    //     icone: <DescriptionIcon />,
    //     submenus: [
    //         {
    //             idmenu: '7',
    //             descmenu: 'Submenu 1'
    //         }
    //     ]
    // },
    // {
    //     idmenu: '5',
    //     descmenu: 'Consultas',
    //     icone: <VisibilityIcon />,
    //     submenus: [
    //         {
    //             idmenu: '8',
    //             descmenu: 'Submenu 2'
    //         }
    //     ]
    // },
    // {
    //     idmenu: '6',
    //     descmenu: 'Fechamento',
    //     icone: <RequestQuoteIcon />,
    //     submenus: [
    //         {
    //             idmenu: '9',
    //             descmenu: 'Submenu 3'
    //         }
    //     ]
    // },
]

export const menuRelation = {
    '0': {
        idmenu: '0',
        descmenu: 'Dashboard'
    },
    '1': {
        idmenu: '1',
        descmenu: 'Ordem de Porcionamento'
    },
    '2': {
        idmenu: '2',
        descmenu: 'Programação de Entrada'
    },
    '3': {
        idmenu: '3',
        descmenu: 'Suprimentos'
    }
}