import React, { forwardRef } from "react";

import dayjs from 'dayjs';
import i18n from "../../i18n";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import 'dayjs/locale/pt-br'

dayjs.extend(utc)
dayjs.extend(timezone)

class MainDateTimeInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { wrongFormat: false }
    }

    handleChange(event) {
        if (this.props.type === 'time') {
            const input = event.target.value

            let cleanedInput = input.replace(/[^\d]/g, '').substring(0, 4)

            if (cleanedInput.length >= 3) {
                cleanedInput = cleanedInput.substring(0, 2) + ':' + cleanedInput.substring(2, 4)
            }
            event.target.value = cleanedInput

            if (event.target.value.length !== 5 && event.target.value.length !== 0) {
                this.setState({ wrongFormat: true })
            } else {
                this.setState({ wrongFormat: false })
            }
            this.props.handleChange(event)
        }
    }

    handleChangeDate = (date) => {
        const event = {
            target: {
                id: this.props.id,
                value: date
            }
        }
        this.props.handleChange(event)
    }


    render() {
        if (this.props.type === 'time') {
            return (
                <Box sx={{ width: this.props.width ? this.props.width : '100%' }}>
                    <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: this.state.wrongFormat ? 'red' : '' }}>
                        {this.props.required
                            ? <>{this.props.label}<span style={{ color: this.props.colors.redAccent[600] }}> *</span></> ?? ''
                            : this.props.label ?? ''
                        }
                    </Typography>
                    <TextField
                        className="main-time-input"
                        id={this.props.id ?? undefined}
                        sx={{
                            width: this.props.width ? this.props.width : '100%',
                            ...this.props.sx,
                        }}
                        variant={this.props.borderless ? 'standard' : 'outlined'}
                        disabled={this.props.disabled ?? false}
                        multiline={this.props.minRows ? true : false}
                        rows={this.props.minRows ?? 1}
                        value={this.props.value ?? undefined}
                        label={''}
                        size={this.props.size ?? 'small'}
                        placeholder='HH:MM'
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: this.props.inputProps ?? undefined,
                        }}
                        inputRef={this.props.innerRef}
                    />
                    <div style={{ color: this.props.colors.redAccent[500], fontWeight: 'bold' }} >{this.state.errorMessage}</div>
                </Box>
            )
        } else if (this.props.type === 'date') {
            return (
                <Box sx={{ width: this.props.width ? this.props.width : '100%' }}>
                    <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {this.props.required
                            ? <>{this.props.label}<span style={{ color: this.props.colors.redAccent[600] }}> *</span></> ?? ''
                            : this.props.label ?? ''
                        }
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language.toLowerCase().replace('_', '-')}>
                        <Box onKeyUp={this.props.onKeyUp ?? null}>
                            <DatePicker
                                inputRef={this.props.innerRef}
                                className={`main-date-input ${this.props.disabled ? 'no-calendar-icon' : ''}`}
                                sx={{
                                    width: this.props.width ? this.props.width : '100%',
                                    ...this.props.sx,
                                }}
                                disabled={this.props.disabled ?? false}
                                value={this.props.value ? dayjs(this.props.value) : null}
                                onChange={this.handleChangeDate}
                                format={this.props.formatInput ? this.props.formatInput : "DD/MM/YYYY"} // formatar o input que aparece na tela
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>
            )
        }
    }
}

const ForwardedMainDateTimeField = forwardRef((props, ref) => {
    return <MainDateTimeInput {...props} innerRef={ref} />
})

export default ForwardedMainDateTimeField