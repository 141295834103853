import axios from 'axios';
import dayjs from 'dayjs';

export const defaultRequest = async (config, form, bringRegisterNumber=false) => {
    
    // var url = new URL('http://homologacao.acomsistemas.com.br:80/api/' + config.endpoint)
    var url = new URL('https://producao.acomsistemas.com.br/api/' + config.endpoint)
    // var url = new URL('http://webapps01.acom.net.br:8091/' + config.endpoint)

    var data = {}

    const username = 'admin'
    const password = 'evers'

    const basicAuth = 'Basic ' + btoa(username + ':' + password)

    var headers = {
        'Content-Type': 'application/json'
    }

    if (basicAuth){
        headers['Authorization'] = basicAuth
    }
    
    if (config.method === 'get' || config.method === 'delete') {
        url.search = new URLSearchParams(form)
    } 
    else if (config.method === 'post' || config.method === 'put') {
        data = form
    }

    var funcResponse = {}

    try {
        const response = await axios({
            headers: headers,
            method: config.method.toLowerCase(),
            url: url.toString(),
            data: data
        })
        if (response.status >= 200 && response.status < 300) {
            funcResponse = {
                status: true,
                data: response.data,
            }
            if (response.headers['x-registros'] && bringRegisterNumber) {
                funcResponse.data['totalSize'] = response.headers['x-registros']
            }
        } else {
            funcResponse = {
                status: false,
                data: response.data,
                message: response.data?.mensagem ?? 'Erro ao executar função',
                msg_type: response.data?.tp_mensagem ?? 1
            }
        }
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userToken')
        } else {
            funcResponse = {
                status: false,
                data: error.response && error.response.data ? error.response.data : '' ,
                message: 'Erro ao executar função',
                msg_type: 1
            }
        }
    }    
    // Se dar certo o request
    // {status: true, data: 'retorno do request'}

    // Se der errado o request
    // {status: false, data: 'retorno do request', message: 'mensagem do sistema', msg_type: '1 ou 2, sendo 1 error e 2 warning '}
    return funcResponse   
}

export const checkSendDate = async (date, company, entity) => {
    var config = {
        endpoint: `sis/dia/${company}`,
        method: 'get'
    }
    var form = {
        'tp_calendario': 'C',
        'dt_calendario': date ? dayjs(date).format('YYYY-MM-DD') : '',
        'x-Entidade': entity
    }
    try {
        let response = await defaultRequest(config, form)
        if (response.status) {
            form['tp_calendario'] = 'O'
            response = await defaultRequest(config, form)
            return response
        } else if (response.data.tp_mensagem === 1 && response.data.mensagem) {
            return response
        }
    } catch {
        return {'status': false, 'message': 'Erro ao executar requisição'}
    }
}

export const stockTransaction = async (origin, type, screenId, entity) => {
    const relationTable = {
        'id_ItemAjusteCusto': 'TADM_ItemAjusteCusto',
        'id_inventario': 'TADM_Inventario',
        'id_MovimentoEstoque': 'TADM_MovimentoEstoque',
        'id_NotaEmitida': 'TFAE_NotaEmitida',
        'id_Notarecebida': 'TFAE_NotaRecebida',
        'id_ordemporcionamento': 'TOPO_OrdemPorcionamento',
        'id_ordemporcionamentoproducao': 'TOPO_OrdemPorcionamentoProducao',
        'id_ordemproducao': 'TOPR_OrdemProducao',
        'id_inventariorotativo': 'TGIR_InventarioRotativo',
        'id_fretepagardocumentocusto': 'TFAE_FretePagarDocumentoCusto'
    }

    var config = {
        endpoint: `sis/transacaoestoque?x-Entidade=${entity}`,
        method: 'post'
    }
    var form = {
        'TransacaoEstoqueSistema': {
            "ds_origem_registro": screenId,
            "tabela_origem": relationTable[origin],
            "id_origem": origin,
            "tp_operacao": type, // I = Incluir  ou A = Alterar
            "tp_mensagem": null,
            "mensagem": null
          } 
    }

    let response = await defaultRequest(config, form)
    return response

}