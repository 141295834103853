import React from "react";

// Icons
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

// Images
import acom_logo from "../../data/everlojas_light.png";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { withTranslation } from "react-i18next";


class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isCollapsed: false,
            hoverSidebar: false,
            anchorElement: null,
            open: false,

            userInfos: this.props.user,
            imageBase64: this.props.avatarMiniature || '',
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.avatarMiniature !== this.props.avatarMiniature) {
            this.setState({ imageBase64: this.props.avatarMiniature })
        }
    }

    displayImage = (hexString) => {
        const formatedImage = 'data:image/png;base64,' + hexString
        return formatedImage
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleClose = () => {
        this.setState({
            anchorElement: null,
            open: false
        })
    }

    handleOpen = (e) => {
        this.setState({
            anchorElement: e.currentTarget,
            open: this.state.open ? false : true
        })
    }

    logOff = () => {
        localStorage.removeItem('userToken')
        window.location.reload()
    }

    render() {
        // imagem em branco quando não há thumbnail
        const blankThumbnail = 'iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAPklEQVR42u3OMQEAMBADob5/0amNG0ABt+31nJaWVoCWllaBlpZWgZaWVoGWllaBlpZWgZaWVoGWllZBtPUBvVeVnZOZWt8AAAAASUVORK5CYII='
        
        const { t } = this.props

        return (
            <Box className='navbar' sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column', lg: 'row' }, gap: '15px', background: this.props.colors.custom['white'], boxShadow: `5px 2px 5px ${this.props.colors.custom['boxShadow']}` }}>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems='center'
                    style={{
                        margin: '10x 0 50x 0',
                        color: this.props.colors.grey[100]
                    }}
                >
                    <Box
                        component='img'
                        src={acom_logo}
                        onClick={() => { this.props.handleUserEditScreen(false) }}
                        sx={{
                            maxHeight: '45px',
                            cursor: 'pointer',
                        }}
                    />

                    <Box sx={{ height: '70px', width: '1px', backgroundColor: "#F9841980", margin: '0 15px' }} />

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: this.props.colors.blueAccent[100] }}>
                            {t('Portal da Loja')}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: this.props.colors.blueAccent[100] }}>
                            {t('Cliente') + ': ' + this.props.user?.data?.empresas[this.props.user.data.cd_empresa - 1].fantasia}
                        </Typography>
                    </Box>
                </Box>

                {/* ICONS */}
                <Box
                    display='flex'
                    alignItems='center'
                    mr='10px'
                >

                    {/* Alerta */}
                    <IconButton sx={{ margin: '0 15px 0 20px', backgroundColor: this.props.colors.grey[800], height: '45px', width: '45px', borderRadius: '100%' }}>
                        <NotificationsOutlinedIcon sx={{ color: this.props.colors.blueAccent[100], fontSize: '30px' }} />
                    </IconButton>

                    {/* Avatar */}
                    <Box onClick={this.handleOpen} sx={{ display: 'flex', gap: '15px', alignItems: 'center', padding: '5px' }}>
                        <Avatar sx={{ height: '45px', width: '45px', border: `1px solid ${this.props.colors.custom['white']}`, ':hover': { border: `1px solid ${this.props.colors.orangeAccent[800]}` } }} src={this.state.imageBase64 && this.state.imageBase64 !== blankThumbnail ? this.displayImage(this.state.imageBase64) : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"} />

                        <Menu
                            sx={{ '& .MuiPaper-root': { borderRadius: '20px 0 20px !important' }, '& .MuiButtonBase-root': { ':hover': { textDecoration: 'underline', background: 'transparent' } } }}
                            anchorEl={this.state.anchorElement}
                            open={this.state.open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={() => this.props.handleUserEditScreen(true)} ><i style={{ marginRight: '8px' }} className="fa-solid fa-user-pen"></i>{t('Editar Perfil')}</MenuItem>
                            <MenuItem onClick={this.logOff} style={{ color: 'red' }}><PowerSettingsNewIcon style={{ marginRight: '5px' }} />{t('Sair')}</MenuItem>
                        </Menu>

                        {/* Usuário e entidade */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '700', color: this.props.colors.blueAccent[100], cursor: 'pointer' }}>{this.props.user.data.nm_usuario}</Typography>
                            <Typography sx={{ fontSize: '13px', fontWeight: '500', color: this.props.colors.blueAccent[100], cursor: 'pointer' }}>| {this.state.userInfos.entidade} |</Typography>
                        </Box>
                    </Box>


                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Navbar);