import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";

class Accordions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: props.isOpen !== undefined ? props.isOpen : !!props.startOpen,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen !== undefined) {
            this.setState({ isOpen: this.props.isOpen })
        }
    }

    handleToggle = (event, isExpanded) => {
        if (this.props.isOpen === undefined) {
            this.setState({ isOpen: isExpanded })
        } else {
            this.props.onToggle && this.props.onToggle(isExpanded)
        }
    };

    render() {
        return (
            <>
                <Accordion
                    className="accordion"
                    expanded={this.state.isOpen}
                    onChange={this.handleToggle}
                    disableGutters={true}
                    sx={{
                        '&:before': {
                            display: 'none',
                        },
                        ...this.props.sx
                    }}
                >
                    <AccordionSummary
                        className="accordion-summary"
                        expandIcon={<i className="fa-solid fa-chevron-down" />}
                        aria-controls="panel1a-content"
                    >
                        <Typography className="accordion-title">{this.props.title}</Typography>
                        <Divider className="accordion-divider" />
                    </AccordionSummary>

                    <AccordionDetails>
                        {this.props.content}
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
}

export default Accordions;