import React from "react";

import { Box, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

import MainButton from "../../../components/inputs/MainButton";


class PortionOrderHeader extends React.Component {

    render() {
        const { t } = this.props

        return (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column', lg: 'row' }, alignItems: 'center', justifyContent: 'space-between', backgroundColor: this.props.colors.blueAccent[100], padding: '5px 20px', borderRadius: '5px' }}>

                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <img src={this.props.company.im_cadastro ? 'data:image/png;base64,' + this.props.company.im_cadastro : ''} style={{ maxHeight: '80px', maxWidth: '200px' }} />
                    <Box sx={{ height: '30px', backgroundColor: 'white', width: '1px', margin: '0 40px' }} />
                    <Typography sx={{ fontSize: '17px', color: 'white', fontWeight: '500' }}>{t('Abertura e Apontamento de Ordem de Porcionamento')}</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column', lg: 'row' }, gap: '20px' }} >
                    <MainButton {...this.props} title={!this.props.isFull ? t("Tela Cheia") : t("Fechar Tela")} onButtonClick={this.props.handleFullScreen} sx={{ border: `1px solid white`, borderRadius: '5px' }} />
                    <MainButton {...this.props} title={t("Abrir Nova OPO")} onButtonClick={this.props.onClicked} sx={{ border: `1px solid white`, borderRadius: '5px' }} />
                    <MainButton {...this.props} title={t("Sair")} onButtonClick={this.props.onClose} sx={{ width: '100px' }} />
                </Box>

            </Box>
        )
    }
}

export default withTranslation()(PortionOrderHeader);