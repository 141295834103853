import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    PT_BR: {
        translation: {
            "Editar Perfil": "Editar Perfil",
            "Dados Pessoais": "Dados Pessoais",
            "Nome Completo": "Nome Completo",
            "Nome Sobrenome": "Nome Sobrenome",
            "CPF": "CPF",
            "E-mail Profissional": "E-mail Profissional",
            "empresa@empresa.com.br": "empresa@empresa.com.br",
            "Telefone (Inválido)": "Telefone (Inválido)",
            "Telefone inválido.": "Telefone inválido.",
            "Telefone": "Telefone",
            "Idioma da Plataforma": "Idioma da Plataforma",
            "Alterar Senha": "Alterar Senha",
            "Senha Atual": "Senha Atual",
            "Insira sua Senha Atual": "Insira sua Senha Atual",
            "Nova Senha": "Nova Senha",
            "Insira sua Nova Senha": "Insira sua Nova Senha",
            "Confirme sua Nova Senha": "Confirme sua Nova Senha",
            "Mínimo 200px por 200px, nos": "Mínimo 200px por 200px, nos",
            "formatos .png e .jpg": "formatos .png e .jpg",
            "Trocar Foto": "Trocar Foto",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Buscar na tabela [origem, número, fornecedor, etc]",
            "Portal da Loja": "Portal da Loja",
            "Cliente": "Cliente",
            "Item": "Item",
            "Descrição": "Descrição",
            "Embalagem": "Embalagem",
            "UM": "UM",
            "Inventário": "Inventário",
            "Lançamento RMI": "Lançamento RMI",
            "Solicitação de Compra": "Solicitação de Compra",
            "Compra por Sugestão": "Compra por Sugestão",
            "Perdas": "Perdas",
            "Movimentação": "Movimentação",
            "Contagem": "Contagem",
            "Entradas": "Entradas",
            "Produção": "Produção",
            "Q. Saldo": "Q. Saldo",
            "V. Saldo": "V. Saldo",
            "Voltar": "Voltar",
            "Salvar": "Salvar",
            "Incluir": "Incluir",
            "Confirmar": "Confirmar",

            // Mensagens de Alerta
            "Dados retornados": "Dados retornados",
            "Nenhum dado retornado": "Nenhum dado retornado",
            "Deseja Cancelar ?": "Deseja Cancelar ?",
            "As informações adicionadas serão perdidas.": "As informações adicionadas serão perdidas.", 
            "Dados atualizados com sucesso!": "Dados atualizados com sucesso!",
            "Necessário preencher todos os campos para alterar a senha atual.": "Necessário preencher todos os campos para alterar a senha atual.",
            "A Senha atual informada está incorreta.": "A Senha atual informada está incorreta.",
            "As senhas não atendem aos requisitos necessários.": "As senhas não atendem aos requisitos necessários.",
            "As senhas devem ter entre 8 e 20 caracteres.": "As senhas devem ter entre 8 e 20 caracteres.",
            "As senhas devem conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.": "As senhas devem conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
            "As senhas devem coincidir.": "As senhas devem coincidir.",

            "Centro de Custo Conta": "Centro de Custo Conta",
            "Centro de custo já escolhido": "Centro de custo já escolhido",
            "Conta": "Conta",
            "% Rateio": "% Rateio",
            "V. Rateio": "V. Rateio",
            "NFS-e": "NFS-e",
            "Série": "Série",
            "Autorização": "Autorização",
            "[autorização]": "[autorização]",
            "Destinatário": "Destinatário",
            "[destinatário]": "[destinatário]",
            "Prestador de Serviço": "Prestador de Serviço",
            "[prestador de serviço]": "[prestador de serviço]",
            "Situação": "Situação",
            "Agência": "Agência",
            "Banco": "Banco",
            "Serviço": "Serviço",
            "V. Serviço": "V. Serviço",
            "V. Imposto Retido": "V. Imposto Retido",
            "Recebimento de NFS-e Nota Fiscal de Serviço": "Recebimento de NFS-e Nota Fiscal de Serviço",
            "Conferência de Entrada": "Conferência de Entrada",
            "D. Vencimento": "D. Vencimento",
            "Condições de Pagamento": "Condições de Pagamento",
            "Código de Barras/Chave Pix": "Código de Barras/Chave Pix",
            "Rateio por Centro de Custo": "Rateio por Centro de Custo",
            "Resumo": "Resumo",
            "A realizar:": "A realizar:",
            "Acumulado": "Acumulado",
            "Diferença": "Diferença",
            "% Realizada:": "% Realizada:",
            "Recusar Pagamento": "Recusar Pagamento",
            "Confirmar Recebimento": "Confirmar Recebimento",
            "Confirmar Recebimento Sem Conferência": "Confirmar Recebimento Sem Conferência",
            "V. Unitário": "V. Unitário",
            "Q. Pedido": "Q. Pedido",
            "Q. Atendido": "Q. Atendido",
            "V. Total": "V. Total",
            "Pedido de Compra:": "Pedido de Compra:",
            "Pedido": "Pedido",
            "D. Entrega": "D. Entrega",
            "Observação": "Observação",
            "Item Porcionado": "Item Porcionado",
            "Quantidade": "Quantidade",
            "Peso (Kg)": "Peso (Kg)",
            "Peso Médio (Kg)": "Peso Médio (Kg)",
            "Preencha todos os campos obrigatórios (*)": "Preencha todos os campos obrigatórios (*)",
            "Pelo menos 1 item na grade deve ter quantidade e peso informado.": "Pelo menos 1 item na grade deve ter quantidade e peso informado.",
            "Existe(m) item(ns) informado(s) na grade sem peso ou quantidade, informe os dois campos ou informe zero para a quantidade e peso.": "Existe(m) item(ns) informado(s) na grade sem peso ou quantidade, informe os dois campos ou informe zero para a quantidade e peso.",
            "Ordem de Porcionamento Criada com Sucesso": "Ordem de Porcionamento Criada com Sucesso",
            "O peso total dos itens produzidos não pode ser maior que o peso do item a porcionar.": "O peso total dos itens produzidos não pode ser maior que o peso do item a porcionar.",
            "N°:": "N°:",
            "Serie:": "Serie:",
            "Falha ao criar OPO": "Falha ao criar OPO",
            "Dados Retornados!": "Dados Retornados!",
            "Erro ao executar a operação.": "Erro ao executar a operação.",
            "Abertura do Porcionamento": "Abertura do Porcionamento",
            "Usuário:": "Usuário:",
            "Setor:": "Setor:",
            "Entidade:": "Entidade:",
            "Item a ser Porcionado": "Item a ser Porcionado",
            "Un. Medida": "Un. Medida",
            "Refugo/Perdas (Kg)": "Refugo/Perdas (Kg)",
            "Lote": "Lote",
            "Dias de Validade": "Dias de Validade",
            "Validade": "Validade",
            "Cancelar": "Cancelar",
            "Finalizar": "Finalizar",
            "Item da Nota": "Item da Nota",
            "Cód. Fab DANFE": "Cód. Fab DANFE",
            "Q. Caixa": "Q. Caixa",
            "Q. Solicitada": "Q. Solicitada",
            "Q. Pedida": "Q. Pedida",
            "Q. Atendida": "Q. Atendida",
            "D. Emissão": "D. Emissão",
            "D. Entrada": "D. Entrada",
            "V. Pedido": "V. Pedido", 
            "SIM": "SIM",
            "NÃO": "NÃO",
            "DANFE": "DANFE",
            "Pedidos": "Pedidos",
            "Itens": "Itens",
            "D. Lançamento": "D. Lançamento",
            "Recebimento sem Conferência": "Recebimento sem Conferência",
            "Confirmar itens com conferência": "Confirmar itens com conferência",
            "Observação DANFE": "Observação DANFE",
            "Motivo Autorização": "Motivo Autorização",
            "Não Conformidade DANFE": "Não Conformidade DANFE",
            "Não Conformidade Item": "Não Conformidade Item",
            "Não Conformidade": "Não Conformidade",
            "Recusar DANFE": "Recusar DANFE",
            "Observação de Não Conformidade": "Observação de Não Conformidade",
            "[observação de não conformidade]": "[observação de não conformidade]",
            "Itens da Nota:": "Itens da Nota:",
            "Itens do Pedido:": "Itens do Pedido:",
            "Q. Requisitada": "Q. Requisitada",
            "Q. Estoque": "Q. Estoque",
            "Série da RMI": "Série da RMI",
            "RMI - Requisição de Materiais e Insumos": "RMI - Requisição de Materiais e Insumos",
            "A data não pode ser nula ou posterior à data atual.": "A data não pode ser nula ou posterior à data atual.",
            "Falha ao confirmar recebimento.": "Falha ao confirmar recebimento.",
            "Requisitante": "Requisitante",
            "Depósito Origem": "Depósito Origem",
            "Depósito Destino": "Depósito Destino",
            "D. Requisição": "D. Requisição",
            "D. Atendimento": "D. Atendimento",
            "Número RMI": "Número RMI",
            "Evento": "Evento",
            "RFP Evento": "RFP Evento",
            "Q. na Embalagem": "Q. na Embalagem",
            "Código de Barras ou Linha Digitável": "Código de Barras ou Linha Digitável",
            "Ponto de Venda (PDV)": "Ponto de Venda (PDV)",
            "Movimento de Perda": "Movimento de Perda",
            "Motivo da Perda": "Motivo da Perda",
            "Adicionar Item": "Adicionar Item",
            "Gravar RMI": "Gravar RMI",
            "Inserir": "Inserir",
            "AÇÕES": "AÇÕES",
            "Itens da RMI:": "Itens da RMI:",
            "[000000000]": "[000000000]",
            "[buscar item...]": "[buscar item...]",
            "[embalagem...]": "[embalagem...]",
            "[0,00]": "[0,00]",
            "Romaneio de Transferência": "Romaneio de Transferência",
            "Romaneio": "Romaneio",
            "[0]": "[0]",
            "D. Transferência": "D. Transferência",
            "[00/00/0000]": "[00/00/0000]",
            "Origem": "Origem",
            "Número": "Número",
            "Fornecedor - Fantasia": "Fornecedor - Fantasia",
            "Fornecedor - Razão": "Fornecedor - Razão",
            "Data": "Data",
            "Valor": "Valor",
            "Valor Boleto": "Valor Boleto",
            "Loja": "Loja",
            "Pedidos Vinculados": "Pedidos Vinculados",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Buscar na tabela [origem, número, fornecedor, etc]...",
            "R$ 000,00": "R$ 000,00",
            "Filtro avançado:": "Filtro avançado:",
            "D. Inicial": "D. Inicial",
            "D. Final": "D. Final",
            "Pedido de Compra": "Pedido de Compra",
            "Limpar pesquisa": "Limpar pesquisa",
            "Consultar": "Consultar",
            "Depósito": "Depósito",
            "Família": "Família",
            "Grande Grupo": "Grande Grupo",
            "Grupo": "Grupo",
            "Sub Grupo": "Sub Grupo",
            "Fabricante": "Fabricante",
            "Localizador": "Localizador",
            "Tipo de Item": "Tipo de Item",
            "Grupo Inventário": "Grupo Inventário",
            "Grupo Solicitação": "Grupo Solicitação",
            "Lista de Produtos": "Lista de Produtos",
            "Itens para Produção": "Itens para Produção",
            "Itens para Compra": "Itens para Compra",
            "Itens com Estoque Mínimo": "Itens com Estoque Mínimo",
            "Itens com Saldo": "Itens com Saldo",
            "Tela Cheia": "Tela Cheia",
            "Fechar Tela": "Fechar Tela",
            "Abrir Nova OPO": "Abrir Nova OPO",
            "Abertura e Apontamento de Ordem de Porcionamento": "Abertura e Apontamento de Ordem de Porcionamento",
            "Ordem de Porcionamento": "Ordem de Porcionamento",
            "Programação de Entrada": "Programação de Entrada",
            "Suprimentos": "Suprimentos",
            "Sair": "Sair",
            "Necessário adicionar pelo menos um item.": "Necessário adicionar pelo menos um item.",
            "Ao informar o Evento, é necessário informar o campo RFP Evento.": "Ao informar o Evento, é necessário informar o campo RFP Evento.",
            "Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.": "Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.",
            "Q. Recebida": "Q. Recebida",
            "Ordem": "Ordem",
            "Há itens sem conferência na tabela.": "Há itens sem conferência na tabela.",
            "Nenhum item selecionado.": "Nenhum item selecionado.",
            "O campo 'Q. Recebida' é obrigatório.": "O campo 'Q. Recebida' é obrigatório.",
            "Não é necessário selecionar 'Não Conformidade Item' quando não há diferença.": "Não é necessário selecionar 'Não Conformidade Item' quando não há diferença.",
            "O campo 'Não Conformidade Item' é obrigatório quando há diferença.": "O campo 'Não Conformidade Item' é obrigatório quando há diferença.",
            "Item Conferido.": "Item Conferido.",
            "Ação realizada com com sucesso!": "Ação realizada com com sucesso!",
            "Conferência dos Itens": "Conferência dos Itens",
            "[digite para buscar as opções...]": "[digite para buscar as opções...]",
            "[digite...]": "[digite...]",
            "[selecione...]": "[selecione...]",
            "Gerar Solicitação": "Gerar Solicitação",
            "Tipo de Solicitação": "Tipo de Solicitação",
            "Origem de Compra": "Origem de Compra",
            "Dias para Entrega": "Dias para Entrega",
            "Solicitação": "Solicitação",
            "D. Solicitação": "D. Solicitação",
            "V. Ult Compra": "V. Ult Compra",
            "Estoque Min.": "Estoque Min.",
            "Saldo Dispo.": "Saldo Dispo.",
            "Emb. Padrão": "Emb. Padrão",
            "Itens configurados para pedido de compra automático": "Itens configurados para pedido de compra automático",
            "D. Inventário (Saldo Até)": "D. Inventário (Saldo Até)",
            "Nr. Localizadores": "Nr. Localizadores",
            "Nr. Inventário": "Nr. Inventário",
            "Contagem por Embalagem": "Contagem por Embalagem",
            "Atualizar Data": "Atualizar Data",
            "Selecione ao menos um item.": "Selecione ao menos um item.",
            "Clique em Confirmar para continuar.": "Clique em Confirmar para continuar.",
            "Deseja prosseguir com a ação ?": "Deseja prosseguir com a ação ?",
            "Transferência de Eventos": "Transferência de Eventos",
            "Preencha todos os campos para atualizar o item.": "Preencha todos os campos para atualizar o item.",
            "Descrição do Evento": "Descrição do Evento",
            "Tipo de Movimento": "Tipo de Movimento",
            "Q. Transferir": "Q. Transferir",
            "V. Embalagem": "V. Embalagem",
            "Período para Análise": "Período para Análise",
            "Sugestão de Dias": "Sugestão de Dias",
            "Análise": "Análise",
            "Compra": "Compra",
            "Variação": "Variação",
            "Opções de Cálculo": "Opções de Cálculo",
            "Saldo Atual": "Saldo Atual",
            "Q. Solicitação": "Q. Solicitação",
            "Q. Pedidos": "Q. Pedidos",
            "Calcular Sugestão": "Calcular Sugestão",
            "Q. Sugerida (Emb. Compra)": "Q. Sugerida (Emb. Compra)",
            "Q. Compra (Emb. Compra)": "Q. Compra (Emb. Compra)",
            "Ativo": "Ativo",
            "Pendente": "Pendente",
            "Há items não preenchidos na tabela.": "Há items não preenchidos na tabela.",
            "Transferir": "Transferir",
            "Tipo de Movimento de Perda": "Tipo de Movimento de Perda",
            "Q. Perdida": "Q. Perdida",
            "Q. Aplicada": "Q. Aplicada",
            "Q. Total": "Q. Total",
            "Selecionar": "Selecionar",
            "Selecionar Tudo": "Selecionar Tudo",
            "Excluir Insumos": "Excluir Insumos",
            "Recarregar Insumos": "Recarregar Insumos", 
            "Lançar Perda": "Lançar Perda",
            "Tipo de Relatório": "Tipo de Relatório",
            "Relatório": "Relatório",
            "Itens Para Contagem": "Itens Para Contagem",
            "Itens Para Contagem Por Estrutura": "Itens Para Contagem Por Estrutura",
            "Padrão": "Padrão",
            "Visão Simplificada": "Visão Simplificada",
            "Gerar Entrada": "Gerar Entrada",
            "Lançamento": "Lançamento",
            "Nr. Entrada": "Nr. Entrada",
            "Nr. Documento": "Nr. Documento",
            "Usuário Lançamento": "Usuário Lançamento",
            "Recebido Por": "Recebido Por",
            "Gerar Inventário": "Gerar Inventário",
            "Dias Análise": "Dias Análise",
            "Dias Compra": "Dias Compra",
            "Responsável": "Responsável",
            "Movimentação de Itens": "Movimentação de Itens",
            "Dados da RMI": "Dados da RMI",
            "Controle de Entradas": "Controle de Entradas",
            "Embalagem Aceita Decimal": "Embalagem Aceita Decimal",
            "Período de Análise": "Período de Análise",
            "Dias p/ Programação de Estoque": "Dias p/ Programação de Estoque",
            "Dias p/ Análise de Consumo": "Dias p/ Análise de Consumo",
            "Consumo Médio": "Consumo Médio",
            "Consumo Pico": "Consumo Pico",
            "Previsão de Consumo": "Previsão de Consumo",
            "Calculada": "Calculada",
            "Estoque Futuro": "Estoque Futuro",
            "Vendida": "Vendida",
            "Sugestão": "Sugestão",
            "Detalhes": "Detalhes",
            "Memória de Cálculo": "Memória de Cálculo",
            "Nr. Ordem": "Nr. Ordem",
            "Depósito do Consumo de Insumos": "Depósito do Consumo de Insumos",
            "Depósito de Destino da Produção": "Depósito de Destino da Produção",
            "V. Custo Médio": "V. Custo Médio",
            "V. Custo Produção": "V. Custo Produção",
            "Tempo": "Tempo",
            "D. Validade": "D. Validade",
            "Q. Receita": "Q. Receita",
            "Q. Produção": "Q. Produção",
            "Total Produção": "Total Produção",
            "Produção Teórica": "Produção Teórica",
            "Q. Baixa Estoque": "Q. Baixa Estoque",
            "Q. Baixa Teórica": "Q. Baixa Teórica",
            "Q. Apontada": "Q. Apontada",
            "V. Custo": "V. Custo",
            "V. Custo Total": "V. Custo Total",
            "Gerar Ordem": "Gerar Ordem",
            "Nr. Contagem": "Nr. Contagem",
            "D. Contagem": "D. Contagem",
            "Descrição Contagem": "Descrição Contagem",
            "Gerar Contagem": "Gerar Contagem",
            "Após a confirmação de recebimento desse romaneio, a operação não poderá ser estornada. Confirma o recebimento do romaneio ?": "Após a confirmação de recebimento desse romaneio, a operação não poderá ser estornada. Confirma o recebimento do romaneio ?",
            "Todos os items devem ser de produção.": "Todos os items devem ser de produção.",
            "Todos os items devem ser de compra.": "Todos os items devem ser de compra.",
            "O campo observação precisa ser preenchido.": "O campo observação precisa ser preenchido.",
            "Já existe um registro para este item e embalagem na tabela.": "Já existe um registro para este item e embalagem na tabela.",
            "Item adicionado com sucesso.": "Item adicionado com sucesso.",
            "Q. Contada": "Q. Contada",
            "Necessário validar todos os itens.": "Necessário validar todos os itens.",
            "Solicitação gerada com sucesso, n.:": "Solicitação gerada com sucesso, n.:",
            "Calculando...": "Calculando...",
            "gravado com sucesso.": "gravado com sucesso.",
            "recusada com sucesso.": "recusada com sucesso.",
            "Deseja Sair ?": "Deseja Sair ?",
            "Todas as alterações serão perdidas.": "Todas as alterações serão perdidas."
        }
    },
    EN_US: {
        translation: {
            "Editar Perfil": "Edit Profile",
            "Dados Pessoais": "Personal Data",
            "Nome Completo": "Full Name",
            "CPF": "CPF",
            "E-mail Profissional": "Professional Email",
            "empresa@empresa.com.br": "comapny@company.com",
            "Telefone (Inválido)": "Telephone (Invalid)",
            "Telefone inválido.": "Invalid telephone.",
            "Telefone": "Telephone",
            "Idioma da Plataforma": "Platform Language",
            "Voltar": "Return",
            "Salvar": "Save",
            "Incluir": "Include",
            "Confirmar": "Confirm",
            "Alterar Senha": "Change Password",
            "Senha Atual": "Your Password",
            "Insira sua Senha Atual": "Insert Your Password",
            "Nova Senha": "New Password",
            "Insira sua Nova Senha": "Insert Your New Password",
            "Confirme sua Nova Senha": "Confirm Your New Password",
            "Mínimo 200px por 200px, nos": "Minimum 200px by 200px, in",
            "formatos .png e .jpg": ".png and .jpg formats",
            "Trocar Foto": "Change Photo",
            "Portal da Loja": "Store Portal",
            "Cliente": "Customer",
            "Item": "Item",
            "Descrição": "Description",
            "Embalagem": "Packaging",
            "Q. Saldo": "Qty. Balance",
            "V. Saldo": "V. Balance",
            "Inventário": "Inventory",
            "Lançamento RMI": "RMI Launch",
            "Solicitação de Compra": "Purchase Request",
            "Compra por Sugestão": "Purchase by Suggestion",
            "Perdas": "Losses",
            "Movimentação": "Movement",
            "Transferência de Eventos": "Events Transfer",
            "Contagem": "Count",
            "Entradas": "Entries",
            "Produção": "Production",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Search on Table [origin, number, supplier, etc]",
            "Dados retornados": "Data received",
            "Nenhum dado retornado": "No data returned",
            "Deseja Cancelar ?": "Do you want to cancel ?",
            "As informações adicionadas serão perdidas.": "Added information will be lost.",
            "Centro de Custo Conta": "Cost Center Account",
            "Centro de custo já escolhido": "Cost Center already chosen",
            "Conta": "Account",
            "% Rateio": "% Apportionment",
            "V. Rateio": "Apportionment Value",
            "NFS-e": "NFS-e",
            "Autorização": "Authorization",
            "[autorização]": "[authorization]",
            "Destinatário": "Recipient",
            "[destinatário]": "[recipient]",
            "Prestador de Serviço": "Service Provider",
            "[prestador de serviço]": "[service provider]",
            "Situação": "Status",
            "Agência": "Agency",
            "Banco": "Bank",
            "Serviço": "Service",
            "V. Serviço": "V. Service",
            "V. Imposto Retido": "V. Withheld Tax",
            "Recebimento de NFS-e Nota Fiscal de Serviço": "Receipt of NFS-e Service Invoice",
            "Conferência de Entrada": "Entry Check",
            "D. Vencimento": "Due Date",
            "Condições de Pagamento": "Payment Conditions",
            "Código de Barras/Chave Pix": "Barcode/Pix Key",
            "Rateio por Centro de Custo": "Apportionment by Cost Center",
            "Resumo": "Summary",
            "A realizar:": "To Perform:",
            "Acumulado": "Accumulated",
            "Diferença": "Difference",
            "% Realizada:": "% Performed:",
            "Recusar Pagamento": "Reject Payment",
            "Confirmar Recebimento": "Confirm Receipt",
            "Confirmar Recebimento Sem Conferência": "Confirm Receipt Without Checking",
            "UM": "UM",
            "V. Unitário": "V. Unit",
            "Q. Pedido": "Qty. Ordered",
            "Q. Atendido": "Qty. Answered",
            "V. Total": "V. Total",
            "Pedido de Compra:": "Purchase Order:",
            "Pedido": "Order",
            "Empresa": "Company",
            "Fornecedor": "Supplier",
            "D. Entrega": "Delivery Date",
            "Observação": "Observation",
            "Item Porcionado": "Portioned Item",
            "Quantidade": "Quantity",
            "Peso (Kg)": "Weight (Kg)",
            "Peso Médio (Kg)": "Average Weight (Kg)",
            "Preencha todos os campos obrigatórios (*)": "Fill in all required fields (*)",
            "Pelo menos 1 item na grade deve ter quantidade e peso informado.": "At least one item in the grid must have quantity and weight informed.",
            "Existe(m) item(ns) informado(s) na grade sem peso ou quantidade, informe os dois campos ou informe zero para a quantidade e peso.": "There are item(s) in the grid without weight or quantity, please provide both fields or set zero for quantity and weight.",
            "Ordem de Porcionamento Criada com Sucesso": "Portion Order Created Successfully",
            "O peso total dos itens produzidos não pode ser maior que o peso do item a porcionar.": "The total weight of the items produced cannot be greater than the weight of the item to be portioned.",
            "N°:": "No:",
            "Serie:": "Series:",
            "Falha ao criar OPO": "Failed to create PO",
            "Dados Retornados!": "Data Returned!",
            "Erro ao executar a operação.": "Error executing operation.",
            "Abertura do Porcionamento": "Portioning Opening",
            "Usuário:": "User:",
            "Setor:": "Sector:",
            "Entidade:": "Entity:",
            "Item a ser Porcionado": "Item to be Portioned",
            "Un. Medida": "Unit of Measure",
            "Refugo/Perdas (Kg)": "Waste/Loss (Kg)",
            "Lote": "Batch",
            "Dias de Validade": "Shelf Life (Days)",
            "Validade": "Expiry Date",
            "Cancelar": "Cancel",
            "Finalizar": "Finish",
            "Item da Nota": "Invoice Item",
            "Cód. Fab DANFE": "Mfr. Code DANFE",
            "Q. Caixa": "Qty Box",
            "V. Unitário": "Unit Price",
            "Q. Solicitada": "Requested Qty",
            "Q. Pedida": "Qty. Ordered",
            "Q. Atendida": "Qty. Served",
            "D. Emissão": "Issue Date",
            "D. Entrada": "Entry Date",
            "V. Pedido": "Order Price",
            "Total": "Total",
            "SIM": "YES",
            "NÃO": "NO",
            "Danfe": "Danfe",
            "Pedidos": "Orders",
            "Itens": "Items",
            "Destinatário": "Recipient",
            "D. Entrada": "Entry Date",
            "D. Lançamento": "Posting Date",
            "Recebimento sem Conferência": "Receipt without Verification",
            "Confirmar itens com conferência": "Confirm items with verification",
            "Observação DANFE": "DANFE Observation",
            "Motivo Autorização": "Authorization Reason",
            "Não Conformidade DANFE": "DANFE Non-Conformity",
            "Não Conformidade Item": "Item Non-Conformity",
            "Não Conformidade": "Non-Conformity",
            "Recusar DANFE": "Reject DANFE",
            "Observação de Não Conformidade": "Non-Conformity Observation",
            "[observação de não conformidade]": "[non-conformity observation]",
            "Itens da Nota:": "Invoice Items:",
            "Itens do Pedido:": "Order Items:",
            "Descrição do Item": "Item Description",
            "Q. na Embalagem": "Qty. per Packaging",
            "Q. Requisitada": "Qty. Requested",
            "Q. Estoque": "Qty. Stock",
            "Série da RMI": "RMI Series",
            "RMI - Requisição de Materiais e Insumos": "RMI - Requisition of Materials and Supplies",
            "A data não pode ser nula ou posterior à data atual.": "The date cannot be null or later than the current date.",
            "Falha ao confirmar recebimento.": "Failed to confirm receipt.",
            "Requisitante": "Requester",
            "Depósito Origem": "Origin Deposit",
            "Depósito Destino": "Destination Deposit",
            "D. Requisição": "Request Date",
            "D. Atendimento": "Service Date",
            "Número RMI": "RMI Number",
            "Evento": "Event",
            "RFP Evento": "RFP Event",
            "Código de Barras ou Linha Digitável": "Barcode or Scannable Line",
            "Ponto de Venda (PDV)": "Point of Sale (POS)",
            "Movimento de Perda": "Loss Movement",
            "Motivo da Perda": "Reason for Loss",
            "Adicionar Item": "Add Item",
            "Gravar RMI": "Save RMI",
            "Inserir": "Insert",
            "AÇÕES": "ACTIONS",
            "Itens da RMI:": "RMI Items:",
            "[000000000]": "[000000000]",
            "[buscar item...]": "[search item...]",
            "[embalagem...]": "[packaging...]",
            "[0,00]": "[0.00]",
            "Romaneio de Transferência": "Packing List Transfer",
            "Romaneio": "Manifest",
            "[0]": "[0]",
            "D. Transferência": "Transfer Date",
            "[00/00/0000]": "[00/00/0000]",
            "Origem": "Origin",
            "Número": "Number",
            "Fornecedor - Fantasia": "Supplier - Fantasy Name",
            "Fornecedor - Razão": "Supplier - Corporate Name",
            "Data": "Date",
            "Valor": "Value",
            "Valor Boleto": "Bank Slip Value",
            "Série": "Series",
            "Loja": "Store",
            "Pedidos Vinculados": "Linked Orders",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Search in table [origin, number, supplier, etc]...",
            "R$ 000,00": "R$ 000,00",
            "Filtro avançado:": "Advanced Filter:",
            "D. Inicial": "Start Date",
            "D. Final": "End Date",
            "Romaneio": "Packing List",
            "Pedido de Compra": "Purchase Order",
            "Limpar pesquisa": "Clear Search",
            "Consultar": "Consult",
            "Depósito": "Deposit",
            "Família": "Family",
            "Grande Grupo": "Large Group",
            "Grupo": "Group",
            "Sub Grupo": "Sub Group",
            "Fabricante": "Manufacturer",
            "Localizador": "Locator",
            "Tipo de Item": "Item Type",
            "Grupo Inventário": "Inventory Group",
            "Grupo Solicitação": "Request Group",
            "Lista de Produtos": "Product List",
            "Itens para Produção": "Production Items",
            "Itens para Compra": "Purchase Items",
            "Itens com Estoque Mínimo": "Items with Minimum Stock",
            "Itens com Saldo": "Items with Balance",
            "Tela Cheia": "Full Screen",
            "Fechar Tela": "Close Screen",
            "Abrir Nova OPO": "Open New OPO",
            "Abertura e Apontamento de Ordem de Porcionamento": "Opening and Registration of Portion Order",
            "Ordem de Porcionamento": "Portion Order",
            "Programação de Entrada": "Entry Scheduling",
            "Suprimentos": "Supplies",
            "Dados atualizados com sucesso!": "Data updated successfully!",
            "Necessário preencher todos os campos para alterar a senha atual.": "You must fill in all fields to change the current password.",
            "A Senha atual informada está incorreta.": "The current password entered is incorrect.",
            "As senhas não atendem aos requisitos necessários.": "Passwords do not meet the necessary requirements.",
            "As senhas devem ter entre 8 e 20 caracteres.": "Passwords must be between 8 and 20 characters long.",
            "As senhas devem conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.": "Passwords must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
            "As senhas devem coincidir.": "Passwords must match.",
            "Sair": "Exit",
            "Necessário adicionar pelo menos um item.": "You must add at least one item.",
            "Ao informar o Evento, é necessário informar o campo RFP Evento.": "When reporting the Event, it is necessary to inform the RFP Event field.",
            "Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.": "The fields 'DANFE Non-Conformity' and 'DANFE Observation' must be informed.",
            "Q. Recebida": "Qty. Received",
            "Ordem": "Order",
            "Há itens sem conferência na tabela.": "There are items without checking in the table.",
            "Nenhum item selecionado.": "No items selected.",
            "O campo 'Q. Recebida' é obrigatório.": "The 'Received Quantity' field is mandatory.",
            "Não é necessário selecionar 'Não Conformidade Item' quando não há diferença.": "It is not necessary to select 'Item Non-Conformity' when there is no difference.",
            "O campo 'Não Conformidade Item' é obrigatório quando há diferença.": "The 'Item Non-Conformity' field is mandatory when there is a difference",
            "Item Conferido.": "Item Checked.",
            "Ação realizada com com sucesso!": "Action carried out successfully!",
            "Conferência dos Itens": "Items Check",
            "[digite para buscar as opções...]": "[type to search for options...]",
            "[digite...]": "[type...]",
            "[selecione...]": "[select...]",
            "Gerar Solicitação": "Generate Request",
            "Tipo de Solicitação": "Request Type",
            "Origem de Compra": "Purchase Source",
            "Dias para Entrega": "Days to Delivery",
            "Solicitação": "Request",
            "D. Solicitação": "D. Request",
            "V. Ult Compra": "V. Last Purchase",
            "Estoque Min.": "Min. Stock",
            "Saldo Dispo.": "Balance on Hand",
            "Emb. Padrão": "Standard Pack",
            "Itens configurados para pedido de compra automático": "Items configured for automatic purchase order",
            "D. Inventário (Saldo Até)": "D. Inventory (Balance Up To)",
            "Nr. Localizadores": "No. Locators",
            "Nr. Inventário": "No. Inventory",
            "Contagem por Embalagem": "Count by Package",
            "Atualizar Data": "Update Date",
            "Selecione ao menos um item.": "Please select at least one item.",
            "Clique em Confirmar para continuar.": "Click Confirm to continue.",
            "Deseja prosseguir com a ação ?": "Do you want to proceed with the action ?",
            "Preencha todos os campos para atualizar o item.": "Fill in all fields to update the item.",
            "Descrição do Evento": "Event Description",
            "Tipo de Movimento": "Movement Type",
            "Q. Transferir": "Qty. Transfer",
            "V. Embalagem": "V. Packaging",
            "Período para Análise": "Analysis Period",
            "Sugestão de Dias": "Suggested Days",
            "Análise": "Analysis",
            "Compra": "Purchase",
            "Variação": "Variation",
            "Opções de Cálculo": "Calculation Options",
            "Saldo Atual": "Current Balance",
            "Q. Solicitação": "Qty. Request",
            "Q. Pedidos": "Qty. Orders",
            "Calcular Sugestão": "Calculate Suggestion",
            "Q. Sugerida (Emb. Compra)": "Qty. Suggested (Purchase Pack)",
            "Q. Compra (Emb. Compra)": "Qty. Purchase (Purchase Pack)",
            "Ativo": "Active",
            "Pendente": "Pending",
            "Há items não preenchidos na tabela.": "There are unfilled items in the table.",
            "Transferir": "Transfer",
            "Tipo de Movimento de Perda": "Loss Movement Type",
            "Q. Perdida": "Lost Quantity",
            "Q. Aplicada": "Qty. Applied",
            "Q. Total": "Qty. Total",
            "Selecionar": "Select",
            "Selecionar Tudo": "Select All",
            "Excluir Insumos": "Delete Inputs",
            "Recarregar Insumos": "Reload Inputs", 
            "Lançar Perda": "Launch loss",
            "Tipo de Relatório": "Report Type",
            "Relatório": "Report",
            "Itens Para Contagem": "Items to Count",
            "Itens Para Contagem Por Estrutura": "Items to Count by Structure",
            "Padrão": "Default",
            "Visão Simplificada": "Simplified View",
            "Gerar Entrada": "Generate Entry",
            "Lançamento": "launch",
            "Nr. Entrada": "No. Entry",
            "Nr. Documento": "No. Document",
            "Usuário Lançamento": "Release User",
            "Recebido Por": "Received By",
            "Gerar Inventário": "Generate Inventory",
            "Dias Análise": "Analysis Days",
            "Dias Compra": "Purchase Days",
            "Responsável": "Responsible",
            "Movimentação de Itens": "Item Movement",
            "Dados da RMI": "RMI Data",
            "Controle de Entradas": "Entries Control",
            "Embalagem Aceita Decimal": "Packaging Accepted Decimal",
            "Período de Análise": "Analysis Period",
            "Dias p/ Programação de Estoque": "Days for Inventory Scheduling",
            "Dias p/ Análise de Consumo": "Days for Consumption Analysis",
            "Consumo Médio": "Average Consumption",
            "Consumo Pico": "Peak Consumption",
            "Previsão de Consumo": "Consumption Forecast",
            "Calculada": "Calculated",
            "Estoque Futuro": "Future Stock",
            "Vendida": "Sold",
            "Sugestão": "Suggestion",
            "Detalhes": "Details",
            "Memória de Cálculo": "Calculation Memory",
            "Nr. Ordem": "No. Order",
            "Depósito do Consumo de Insumos": "Input Consumption Warehouse",
            "Depósito de Destino da Produção": "Production Destination Warehouse",
            "V. Custo Médio": "V. Average Cost",
            "V. Custo Produção": "V. Production Cost",
            "Tempo": "Time",
            "D. Validade": "D. Validity",
            "Q. Receita": "Qty. Revenue",
            "Q. Produção": "Qty. Production",
            "Total Produção": "Total Production",
            "Produção Teórica": "Theoretical Production",
            "Q. Baixa Estoque": "Qty. Stock Reduction",
            "Q. Baixa Teórica": "Qty. Theoretical Reduction",
            "Q. Apontada": "Qty. Reported",
            "V. Custo": "V. Cost",
            "V. Custo Total": "V. Total Cost",
            "Gerar Ordem": "Generate Order",
            "Nr. Contagem": "No. Count",
            "D. Contagem": "D. Count",
            "Descrição Contagem": "Count Description",
            "Gerar Contagem": "Generate Count",
            "Após a confirmação de recebimento desse romaneio, a operação não poderá ser estornada. Confirma o recebimento do romaneio ?": "After confirmation of receipt of this manifest, the transaction cannot be reversed. Do you confirm receipt of the manifest ?",
            "Todos os items devem ser de produção.": "All items must be for production.",
            "Todos os items devem ser de compra.": "All items must be for purchase.",
            "O campo observação precisa ser preenchido.": "The observation field needs to be filled.",
            "Já existe um registro para este item e embalagem na tabela.": "A record for this item and packaging already exists in the table.",
            "Item adicionado com sucesso.": "Item added successfully.",
            "Q. Contada": "Qty. Counted",
            "Necessário validar todos os itens.": "Need to validate all items.",
            "Solicitação gerada com sucesso, n.:": "Request generated successfully, n.:",
            "Calculando...": "Calculating...",
            "gravado com sucesso.": "successfully recorded.",
            "recusada com sucesso.": "successfully declined.",
            "Deseja Sair ?": "Do You Want To Leave ?",
            "Todas as alterações serão perdidas.": "All changes will be lost."
        }
    },
    ES_ES: {
        "translation": {
            "Editar Perfil": "Editar Perfil",
            "Dados Pessoais": "Datos Personales",
            "Nome Completo": "Nombre Completo",
            "CPF": "CPF",
            "E-mail Profissional": "Correo Electrónico Profesional",
            "empresa@empresa.com.br": "empresa@empresa.com",
            "Telefone (Inválido)": "Teléfono (Inválido)",
            "Telefone inválido.": "Teléfono inválido.",
            "Telefone": "Teléfono",
            "Idioma da Plataforma": "Idioma de la Plataforma",
            "Voltar": "Volver",
            "Salvar": "Ahorrar",
            "Incluir": "Incluir",
            "Confirmar": "Confirmar",
            "Alterar Senha": "Cambiar Contraseña",
            "Senha Atual": "Contraseña Actual",
            "Insira sua Senha Atual": "Introduce tu Contraseña Actual",
            "Nova Senha": "Nueva Contraseña",
            "Insira sua Nova Senha": "Introduce tu Nueva Contraseña",
            "Confirme sua Nova Senha": "Confirma tu Nueva Contraseña",
            "Mínimo 200px por 200px, nos": "Mínimo 200px por 200px, en",
            "formatos .png e .jpg": "formatos .png y .jpg",
            "Trocar Foto": "Intercambiar Foto",
            "Portal da Loja": "Portal de la Tienda",
            "Cliente": "Cliente",
            "Item": "Artículo",
            "Embalagem": "Embalaje",
            "Q. Saldo": "Cant. Saldo",
            "V. Saldo": "V. Saldo",
            "Inventário": "Inventario",
            "Lançamento RMI": "Lanzamiento RMI",
            "Solicitação de Compra": "Solicitud de Compra",
            "Compra por Sugestão": "Compra por Sugerencia",
            "Perdas": "Pérdidas",
            "Movimentação": "Movimiento",
            "Transferência de Eventos": "Transferencia de Eventos",
            "Contagem": "Conteo",
            "Empresa": "Empresa",
            "Entradas": "Entradas",
            "Produção": "Producción",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Buscar en Tabla [origen, número, proveedor, etc]",
            "Dados retornados": "Datos recividos",
            "Nenhum dado retornado": "No se devolvieron datos",
            "Deseja Cancelar ?": "Quieres cancelar ?",
            "As informações adicionadas serão perdidas.": "La información agregada se perderá.",
            "Centro de Custo Conta": "Cuenta del Centro de Costos",
            "Centro de custo já escolhido": "Centro de costos ya elegido",
            "Conta": "Cuenta",
            "% Rateio": "% Reparto",
            "V. Rateio": "Valor del Reparto",
            "NFS-e": "NFS-e",
            "Autorização": "Autorización",
            "[autorização]": "[autorización]",
            "Destinatário": "Destinatario",
            "[destinatário]": "[destinatario]",
            "Prestador de Serviço": "Proveedor de Servicios",
            "[prestador de serviço]": "[proveedor de servicios]",
            "Situação": "Situación",
            "Agência": "Agencia",
            "Serviço": "Servicio",
            "V. Serviço": "Valor del Servicio",
            "V. Imposto Retido": "Valor del Impuesto Retenido",
            "Recebimento de NFS-e Nota Fiscal de Serviço": "Recibo de factura de servicio NFS-e",
            "Conferência de Entrada": "Revisión de Entrada",
            "D. Vencimento": "Fecha de Vencimiento",
            "Condições de Pagamento": "Condiciones de Pago",
            "Descrição": "Descripción",
            "Código de Barras/Chave Pix": "Código de Barras/Clave Pix",
            "Rateio por Centro de Custo": "Reparto por Centro de Costos",
            "Resumo": "Resumen",
            "A realizar:": "A realizar:",
            "Acumulado": "Acumulado",
            "Diferença": "Diferencia",
            "% Realizada:": "% Realizado:",
            "Recusar Pagamento": "Rechazar Pago",
            "Confirmar Recebimento": "Confirmar Recibo",
            "Confirmar Recebimento Sem Conferência": "Confirmar Recibo Sin Verificar",
            "V. Unitário": "V.Unitario",
            "Q. Pedido": "Cant. Orden",
            "Q. Atendido": "Cant. Respondido",
            "Pedido de Compra:": "Orden de Compra:",
            "Pedido": "Pedido",
            "Empresa": "Empresa",
            "Fornecedor": "Proveedor",
            "D. Entrega": "Fecha de Entrega",
            "Observação": "Observación",
            "Item Porcionado": "Artículo Porcionado",
            "UM": "UM",
            "Quantidade": "Cantidad",
            "Peso (Kg)": "Peso (Kg)",
            "Peso Médio (Kg)": "Peso Medio (Kg)",
            "Preencha todos os campos obrigatórios (*)": "Rellene todos los campos obligatorios (*)",
            "Pelo menos 1 item na grade deve ter quantidade e peso informado.": "Al menos un artículo en la cuadrícula debe tener cantidad y peso informado.",
            "Existe(m) item(ns) informado(s) na grade sem peso ou quantidade, informe os dois campos ou informe zero para a quantidade e peso.": "Hay artículo(s) en la cuadrícula sin peso o cantidad, proporcione ambos campos o establezca cero para cantidad y peso.",
            "Ordem de Porcionamento Criada com Sucesso": "Orden de Porcionamiento Creada con Éxito",
            "O peso total dos itens produzidos não pode ser maior que o peso do item a porcionar.": "El peso total de los artículos producidos no puede ser mayor que el peso del artículo a porcionar.",
            "N°:": "N°:",
            "Serie:": "Serie:",
            "Falha ao criar OPO": "Fallo al crear PO",
            "Dados Retornados!": "Datos Devueltos!",
            "Erro ao executar a operação.": "Error al ejecutar la operación.",
            "Abertura do Porcionamento": "Apertura de Porcionamiento",
            "Usuário:": "Usuario:",
            "Setor:": "Sector:",
            "Empresa:": "Empresa:",
            "Entidade:": "Entidad:",
            "Item a ser Porcionado": "Artículo a ser Porcionado",
            "Un. Medida": "Unidad de Medida",
            "Refugo/Perdas (Kg)": "Residuos/Pérdidas (Kg)",
            "Lote": "Lote",
            "Dias de Validade": "Días de validez",
            "Validade": "Validez",
            "Cancelar": "Cancelar",
            "Finalizar": "Finalizar",
            "Item da Nota": "Artículo de la Factura",
            "Cód. Fab DANFE": "Cód. Fab DANFE",
            "Q. Caixa": "Cant. Caja",
            "Q. Solicitada": "Cant. Solicitada",
            "Q. Pedida": "Cant. Pedida",
            "Q. Atendida": "Cant. Servida",
            "V. Total": "V. Total",
            "D. Emissão": "F. Emisión",
            "D. Entrada": "F. Entrada",
            "V. Pedido": "V. solicitado",
            "Total": "Total",
            "SIM": "SÍ",
            "NÃO": "NO",
            "DANFE": "DANFE",
            "Pedidos": "Órdenes",
            "Itens": "Artículos",
            "Destinatário": "Destinatario",
            "D. Entrada": "F. Entrada",
            "D. Lançamento": "F. Lanzamiento",
            "Recebimento sem Conferência": "Recepción sin Verificación",
            "Confirmar itens com conferência": "Confirmar ítems con verificación",
            "Observação DANFE": "Observación DANFE",
            "Motivo Autorização": "Motivo de Autorización",
            "Não Conformidade DANFE": "No Conformidad DANFE",
            "Não Conformidade Item": "No Conformidad Artículo",
            "Não Conformidade": "No Conformidad",
            "Recusar DANFE": "Rechazar DANFE",
            "Observação de Não Conformidade": "Observación de No Conformidad",
            "[observação de não conformidade]": "[observación de no conformidad]",
            "Itens da Nota:": "Ítems de la Factura:",
            "Itens do Pedido:": "Ítems del Pedido:",
            "Descrição do Item": "Descripción del Artículo",
            "Q. na Embalagem": "Cant. por Embalaje",
            "Q. Requisitada": "Cant. Solicitada",
            "Q. Estoque": "Cant. en Stock",
            "Série da RMI": "Serie de RMI",
            "RMI - Requisição de Materiais e Insumos": "RMI - Solicitud de Materiales e Insumos",
            "A data não pode ser nula ou posterior à data atual.": "La fecha no puede ser nula o posterior a la fecha actual.",
            "Falha ao confirmar recebimento.": "No se pudo confirmar la recepción.",
            "Requisitante": "Solicitante",
            "Depósito Origem": "Depósito de Origen",
            "Depósito Destino": "Depósito de Destino",
            "D. Requisição": "Fecha de Solicitud",
            "D. Atendimento": "Fecha de Atención",
            "Número RMI": "Número de RMI",
            "Banco": "Banco",
            "Evento": "Evento",
            "RFP Evento": "RFP Evento",
            "Código de Barras ou Linha Digitável": "Código de Barras o Línea Digitable",
            "Ponto de Venda (PDV)": "Punto de Venta (PDV)",
            "Movimento de Perda": "Movimiento de Pérdida",
            "Motivo da Perda": "Motivo de la Pérdida",
            "Adicionar Item": "Agregar Artículo",
            "Gravar RMI": "Grabar RMI",
            "Inserir": "Insertar",
            "AÇÕES": "ACCIONES",
            "Itens da RMI:": "Ítems de RMI:",
            "[000000000]": "[000000000]",
            "[buscar item...]": "[buscar artículo...]",
            "[embalagem...]": "[embalaje...]",
            "[0,00]": "[0,00]",
            "Romaneio de Transferência": "Remisión de Transferencia",
            "Romaneio": "Remisión",
            "[0]": "[0]",
            "D. Transferência": "F. Transferencia",
            "[00/00/0000]": "[00/00/0000]",
            "Origem": "Origen",
            "Número": "Número",
            "Fornecedor - Fantasia": "Proveedor - Nombre de Fantasía",
            "Fornecedor - Razão": "Proveedor - Nombre Corporativo",
            "Data": "Fecha",
            "Valor": "Valor",
            "Valor Boleto": "Valor de la Factura",
            "Série": "Serie",
            "Loja": "Tienda",
            "Pedidos Vinculados": "Pedidos Vinculados",
            "Buscar na tabela [origem, número, fornecedor, etc]...": "Buscar en la tabla [origen, número, proveedor, etc]...",
            "R$ 000,00": "R$ 000,00",
            "Filtro avançado:": "Filtro avanzado:",
            "Número": "Número",
            "D. Inicial": "Fecha de Inicio",
            "D. Final": "Fecha de Finalización",
            "Romaneio": "Lista de Embalaje",
            "Pedido de Compra": "Orden de Compra",
            "Limpar pesquisa": "Limpiar búsqueda",
            "Consultar": "Consultar",
            "Depósito": "Depósito",
            "Família": "Familia",
            "Grande Grupo": "Gran Grupo",
            "Grupo": "Grupo",
            "Sub Grupo": "Subgrupo",
            "Fabricante": "Fabricante",
            "Localizador": "Localizador",
            "Tipo de Item": "Tipo de Artículo",
            "Grupo Inventário": "Grupo de Inventario",
            "Grupo Solicitação": "Grupo de Solicitud",
            "Lista de Produtos": "Lista de Productos",
            "Itens para Produção": "Artículos para Producción",
            "Itens para Compra": "Artículos para Compra",
            "Itens com Estoque Mínimo": "Artículos con Stock Mínimo",
            "Itens com Saldo": "Artículos con Saldo",
            "Tela Cheia": "Pantalla Completa",
            "Fechar Tela": "Cerrar Pantalla",
            "Abrir Nova OPO": "Abrir Nueva OPO",
            "Abertura e Apontamento de Ordem de Porcionamento": "Apertura y Registro de Orden de Porcionamiento",
            "Ordem de Porcionamento": "Orden de Porcionamiento",
            "Programação de Entrada": "Programación de Entrada",
            "Suprimentos": "Suministros",
            "Dados atualizados com sucesso!": "Datos actualizados exitosamente!",
            "Necessário preencher todos os campos para alterar a senha atual.": "Debe completar todos los campos para cambiar la contraseña actual.",
            "A Senha atual informada está incorreta.": "La contraseña actual ingresada es incorrecta.",
            "As senhas não atendem aos requisitos necessários.": "Las contraseñas no cumplen con los requisitos necesarios.",
            "As senhas devem ter entre 8 e 20 caracteres.": "Las contraseñas deben tener entre 8 y 20 caracteres.",
            "As senhas devem conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.": "Las contraseñas deben contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.",
            "As senhas devem coincidir.": "Las contraseñas deben coincidir.",
            "Sair": "Salir",
            "Necessário adicionar pelo menos um item.": "Debes agregar al menos un artículo.",
            "Ao informar o Evento, é necessário informar o campo RFP Evento.": "Al informar el Evento es necesario informar el campo Evento RFP.",
            "Os campos 'Não Conformidade DANFE' e 'Observação DANFE' devem ser informados.": "Se deben ingresar los campos 'No Conformidad DANFE' y 'Observación DANFE'.",
            "Q. Recebida": "Cant. Recibida",
            "Ordem": "Orden",
            "Há itens sem conferência na tabela.": "Hay elementos sin marcar en la tabla.",
            "Nenhum item selecionado.": "No hay elementos seleccionados.",
            "O campo 'Q. Recebida' é obrigatório.": "El campo 'Cantidad recibida' es obligatorio.",
            "Não é necessário selecionar 'Não Conformidade Item' quando não há diferença.": "No es necesario seleccionar 'Artículo No Conforme' cuando no hay diferencia.",
            "O campo 'Não Conformidade Item' é obrigatório quando há diferença.": "El campo 'No conformidad del artículo' es obligatorio cuando hay una diferencia.",
            "Item Conferido.": "Artículo marcado.",
            "Ação realizada com com sucesso!": "Acción realizada con éxito!",
            "Conferência dos Itens": "Conferencia de artículo",
            "[digite para buscar as opções...]": "[escriba para buscar opciones...]",
            "[digite...]": "[escribe...]",
            "[selecione...]": "[seleccionar...]",
            "Gerar Solicitação": "Generar Solicitud",
            "Tipo de Solicitação": "Tipo de Solicitud",
            "Origem de Compra": "Origen de compra",
            "Dias para Entrega": "Días de Entrega",
            "Solicitação": "Solicitud",
            "D. Solicitação": "D. Solicitud",
            "V. Ult Compra": "Compra V. Ult",
            "Estoque Min.": "Stock Mín.",
            "Saldo Dispo.": "Saldo Dispo.",
            "Emb. Padrão": "Emb. estándar",
            "Itens configurados para pedido de compra automático": "Artículos configurados para orden de compra automática",
            "D. Inventário (Saldo Até)": "D. Inventario (Saldo Hasta)",
            "Nr. Localizadores": "Nr. Localizadores",
            "Nr. Inventário": "Nr. de inventario",
            "Contagem por Embalagem": "Conteo por paquete",
            "Atualizar Data": "Actualizar Fecha",
            "Selecione ao menos um item.": "Seleccione al menos un elemento.",
            "Clique em Confirmar para continuar.": "Haga clic en Confirmar para continuar.",
            "Deseja prosseguir com a ação ?": "Quieres continuar con la acción ?",
            "Preencha todos os campos para atualizar o item.": "Complete todos los campos para actualizar el artículo.",
            "Descrição do Evento": "Descripción del evento",
            "Tipo de Movimento": "Tipo de Movimiento",
            "Q. Transferir": "Cant. de Transferencia",
            "V. Embalagem": "V. Embalaje",
            "Período para Análise": "Período de Análisis",
            "Sugestão de Dias": "Sugerencia de Días",
            "Análise": "Análisis",
            "Compra": "Compra",
            "Variação": "Variación",
            "Opções de Cálculo": "Opciones de cálculo",
            "Saldo Atual": "Saldo Actual",
            "Q. Solicitação": "Cant. solicitud",
            "Q. Pedidos": "Cant. Pedidos",
            "Calcular Sugestão": "Calcular Sugerencia",
            "Q. Sugerida (Emb. Compra)": "Cant. sugerida (Paquete de compra)",
            "Q. Compra (Emb. Compra)": "Cant. de Compra (Paquete de compra)",
            "Ativo": "Activo",
            "Pendente": "Pendiente",
            "Há items não preenchidos na tabela.": "Hay elementos sin completar en la tabla.",
            "Transferir": "Transferir",
            "Tipo de Movimento de Perda": "Tipo de movimiento de pérdida",
            "Q. Perdida": "Cant. perdida",
            "Q. Aplicada": "Cant. aplicada",
            "Q. Total": "Cant. Total",
            "Selecionar": "Seleccionar",
            "Selecionar Tudo": "Seleccionar Todo",
            "Excluir Insumos": "Eliminar Entradas",
            "Recarregar Insumos": "Recargar Entradas", 
            "Lançar Perda": "Publicar Pérdida",
            "Tipo de Relatório": "Tipo de informe",
            "Relatório": "informe",
            "Itens Para Contagem": "Elementos Para Contar",
            "Itens Para Contagem Por Estrutura": "Elementos para contar por estructura",
            "Padrão": "Predeterminado",
            "Visão Simplificada": "Vista simplificada",
            "Gerar Entrada": "Generar Entrada",
            "Lançamento": "Lanzamiento",
            "Nr. Entrada": "Nr. Entrada",
            "Nr. Documento": "Nr. Documento",
            "Usuário Lançamento": "Usuario Lanzamiento",
            "Recebido Por": "Recebido Por",
            "Gerar Inventário": "Generar Inventario",
            "Dias Análise": "Días Análisis",
            "Dias Compra": "Días Compra",
            "Responsável": "Responsable",
            "Movimentação de Itens": "Movimiento de artículo",
            "Dados da RMI": "Datos RMI",
            "Controle de Entradas": "control de entradas",
            "Embalagem Aceita Decimal": "El embalaje acepta decimales",
            "Período de Análise": "Período de Análisis",
            "Dias p/ Programação de Estoque": "Días de Programación de Stock",
            "Dias p/ Análise de Consumo": "Días para Análisis de Consumo",
            "Consumo Médio": "Consumo Medio",
            "Consumo Pico": "Consumo Máximo",
            "Previsão de Consumo": "Previsión de Consumo",
            "Calculada": "Calculado",
            "Estoque Futuro": "Acciones Futuras",
            "Vendida": "Vendido",
            "Sugestão": "Sugerencia",
            "Detalhes": "Detalles",
            "Memória de Cálculo": "Memoria de Cálculo",
            "Nr. Ordem": "Nr. Orden",
            "Depósito do Consumo de Insumos": "Almacén de Consumo de Insumos",
            "Depósito de Destino da Produção": "Almacén de Destino de Producción",
            "V. Custo Médio": "V. Costo Promedio",
            "V. Custo Produção": "V. Costo de Producción",
            "Tempo": "Tiempo",
            "D. Validade": "D. Validez",
            "Q. Receita": "Cant. Ingresos",
            "Q. Produção": "Cant. de Producción",
            "Total Produção": "Producción Total",
            "Produção Teórica": "Producción Teórica",
            "Q. Baixa Estoque": "Cant. Reducción Stock",
            "Q. Baixa Teórica": "Cant. Reducción Teórica",
            "Q. Apontada": "Cant. Reportada",
            "V. Custo": "V. Costo",
            "V. Custo Total": "V. Costo Total",
            "Gerar Ordem": "Generar Orden",
            "Nr. Contagem": "Nr. Conteo",
            "D. Contagem": "D. Conteo",
            "Descrição Contagem": "Descripción Conteo",
            "Gerar Contagem": "Generar Conteo",
            "Após a confirmação de recebimento desse romaneio, a operação não poderá ser estornada. Confirma o recebimento do romaneio ?": "Después de confirmar la recepción de este albarán, la operación no se puede revertir. Confirman la recepción del albarán ?",
            "Todos os items devem ser de produção.": "Todos los artículos deben ser de producción.",
            "Todos os items devem ser de compra.": "Todos los artículos deben ser de comprarse.",
            "O campo observação precisa ser preenchido.": "El campo de observación necesita ser llenado.",
            "Já existe um registro para este item e embalagem na tabela.": "Ya existe un registro para este artículo y embalaje en la tabla.",
            "Item adicionado com sucesso.": "Elemento agregado exitosamente.",
            "Q. Contada": "Cant. Contada",
            "Necessário validar todos os itens.": "Necesita validar todos los elementos.",
            "Solicitação gerada com sucesso, n.:": "Solicitud generada exitosamente, n.:",
            "Calculando...": "Calculando...",
            "gravado com sucesso.": "guardado exitosamente.",
            "recusada com sucesso.": "rechazado exitosamente.",
            "Deseja Sair ?": "Desea Salir ?",
            "Todas as alterações serão perdidas.": "Todos los cambios se perderán."
        }
    }

}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "PT_BR", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;