import React from "react";

import Accordion from "../../../components/visual/Accordion";
import dayjs from "dayjs";
import DialogAlert from "../../../components/alerts/DialogAlert";
import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainButton from "../../../components/inputs/MainButton";
import MainCheckBoxInput from "../../../components/inputs/MainCheckBoxInput";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";
import SnackbarAlert from "../../../components/alerts/SnackbarAlert";

import CountReport from "../../../components/reports/CountReport";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { checkSendDate, defaultRequest } from "../../../utils/request/request";
import { getDeposit, getLocator } from "../../../utils/request/requestSelects";
import { withTranslation } from "react-i18next";


class Count extends React.Component {
    constructor(props) {
        super(props)

        const { t } = props

        const hasNewTableList = Array.isArray(this.props.newTableList) && this.props.newTableList.length > 0

        this.firstFieldRef = React.createRef()

        this.state = {
            isLoading: true,
            isLoadingTable: false,
            isReportOpen: false,

            tableList: [],
            tableColumns: [
                ['cd_item', t('Item')],
                ['ds_item', t('Descrição do Item')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
            ],
            tableTotalSize: 0,

            depositList: [],
            locatorList: [],
            reportTypeList: [
                { 'value': 0, 'label': t('Itens Para Contagem') },
                { 'value': 1, 'label': t('Itens Para Contagem Por Estrutura') }
            ],

            company: hasNewTableList ? this.props.newTableList[0].cd_empresa : null,
            deposit: hasNewTableList ? this.props.newTableList[0].cd_deposito : null,
            locator: null,
            countDate: dayjs(),
            countNumber: '',

            countByPackage: false,

            isDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchDeposit()
        this.fetchLocator()
        this.setState({
            tableList: this.props.newTableList,
            tableTotalSize: this.props.newTableList.length,
        })
    }

    fetchDeposit = async () => {
        try {
            const depositData = await getDeposit(this.state.company, this.props.user.entidade)
            this.setState({ depositList: depositData, isLoading: false }, () => {
                if (this.firstFieldRef.current) {
                    this.firstFieldRef.current.focus()
                }
            })


        } catch (error) {
            console.log('Error fetching deposit:', error)
            this.setState({ depositList: [], isLoading: false })
        }

    }

    fetchLocator = async () => {
        try {
            const locatorData = await getLocator(this.props.user.entidade)
            this.setState({ locatorList: locatorData, isLoading: false })


        } catch (error) {
            console.log('Error fetching locator:', error)
            this.setState({ locatorList: [], isLoading: false })
        }
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    sendCount = async () => {
        this.setState({ isDialogOpen: false, isLoading: true })

        if (!this.state.countDate || !this.state.locator) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            this.setState({ isLoading: false })
            return
        }

        let response = await checkSendDate(this.state.countDate, this.state.company, this.props.user.entidade)

        if (!response || !response.status) {
            this.setState({
                alertMessage: response.message ?? '',
                alertType: response.msg_type === 1 ? 'error' : 'warning',
                showAlert: true,

                isLoading: false,
            })
            if (response.msg_type === 1) {
                return
            }
        }

        let config = {
            endpoint: `everlojas/contagem?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'nm_contagem': this.state.countDescription,
            'id_localizador': this.state.locator,
            'cd_empresa': this.state.company,
            'cd_deposito': this.state.deposit,
            'dt_contagem': this.state.countDate.format('YYYY-MM-DD'),
            'cd_gruposolicitacao': 1,
            'cd_usuario': this.props.user.data.cd_usuario,
            'itens': (this.state.tableList && this.state.tableList.length > 0) ?
                this.state.tableList.map(item => {
                    return {
                        'id_item': item.id_item,
                        'qt_contada': 0,
                        'id_embalagem': item.id_embalagem
                    }
                }) : []
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                this.setState({
                    isLoading: false,
                    isDisabled: true,
                    isConfirmationOpen: true,
                    countNumber: r.data.nr_contagem,
                })
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : "Erro ao executar a operação."
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }


    render() {
        const { t } = this.props
        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isDialogOpen}
                            title={t('Deseja prosseguir com a ação ?')}
                            body={t('Clique em Confirmar para continuar.')}
                            onConfirm={this.sendCount}                              // ao clicar em "confirmar"
                            onClose={() => this.setState({ isDialogOpen: false })}  // ao clicar em "voltar"  
                        />

                        <DialogAlert
                            {...this.props}
                            type="confirm"
                            isOpen={this.state.isConfirmationOpen}
                            title={`${t('Contagem')}: ${this.state.countNumber}, ${t('gravado com sucesso.')}`}
                            body={t('')}
                            onConfirm={() => this.setState({ isConfirmationOpen: false })}
                        />

                        <DialogAlert
                            {...this.props}
                            isOpen={this.state.isCloseOpen}
                            title={t('Deseja Sair ?')}
                            body={t('Todas as alterações serão perdidas.')}
                            onClose={() => this.setState({ isCloseOpen: false })}
                            onConfirm={() => this.props.toggleVisiblePage('isCountVisible')}
                        />

                        {this.state.isReportOpen ?
                            <CountReport
                                {...this.state}
                                {...this.props}
                                columns={this.state.tableColumns}
                                rows={this.state.tableList}
                                onClose={() => this.setState({ isReportOpen: false })}
                            /> : <></>}

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>
                                        {t('Contagem')}:
                                    </Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.setState({ isCloseOpen: true })}>
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Accordion
                                        {...this.props}
                                        startOpen
                                        title={`${t('Itens')}:`}
                                        content={
                                            <>
                                                <EditableTable
                                                    {...this.props}
                                                    id='table_count'
                                                    rowId='id_item'
                                                    data={this.state.tableList}
                                                    columns={this.state.tableColumns}
                                                    totalSize={this.state.tableTotalSize}
                                                    onPageChange={() => { }}
                                                    onRowDoubleClick={() => { }}
                                                    onEditRow={() => { }}
                                                    isLoading={this.state.isLoadingTable}
                                                />
                                            </>
                                        }
                                    />

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '41% 39% 20%' }, margin: { md: '10px 0 0 0', lg: '10px 40px 0 0' } }}>
                                        <MainSelectField
                                            {...this.props}
                                            id='company'
                                            value={this.state.company}
                                            label={t('Empresa')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.props.user?.data?.empresas?.map(item => ({ label: `${item.cd_empresa} - ${item.fantasia}`, value: item.cd_empresa }))}
                                            disabled={true}
                                        />
                                        <MainSelectField
                                            {...this.props}
                                            id='deposit'
                                            value={this.state.deposit}
                                            label={t('Depósito')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))}
                                            disabled={true}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='countNumber'
                                            value={this.state.countNumber}
                                            label={t('Nr. Contagem')}
                                            placeholder={t('[digite...]')}
                                            disabled={true}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '14% 26% 20% 40%' }, margin: { md: '10px 0 0 0', lg: '10px 60px 0 0' } }}>
                                        <MainDateTimeInput
                                            required
                                            {...this.props}
                                            id='countDate'
                                            value={this.state.countDate}
                                            label={t('D. Contagem')}
                                            placeholder={t('[00/00/0000]')}
                                            handleChange={this.handleChangeText}
                                            type='date'
                                            ref={this.firstFieldRef}
                                        />
                                        <MainCheckBoxInput
                                            {...this.props}
                                            sx={{ marginTop: '10px' }}
                                            id='countByPackage'
                                            value={this.state.countByPackage}
                                            label={t('Contagem por Embalagem')}
                                            handleChange={this.handleChangeText}
                                        />
                                        <MainSelectField
                                            required
                                            {...this.props}
                                            id='locator'
                                            value={this.state.locator}
                                            label={t('Localizador')}
                                            placeholder={t('[selecione...]')}
                                            optionsList={this.state.locatorList.map(item => ({ label: item.ds_localizador, value: item.id_localizador }))}
                                            handleChange={this.handleChangeText}
                                        />
                                        <MainTextField
                                            {...this.props}
                                            id='countDescription'
                                            value={this.state.countDescription}
                                            label={t('Descrição Contagem')}
                                            placeholder={t('[digite...]')}
                                            handleChange={this.handleChangeText}
                                        />
                                    </Box>

                                    {this.state.isDisabled ?
                                        (
                                            <Box sx={{ display: 'grid', gap: '20px', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '25% 3% 15%' }, margin: '10px 0 20px 0' }}>
                                                <MainSelectField
                                                    {...this.props}
                                                    id='reportType'
                                                    value={this.state.reportType}
                                                    label={t('Tipo de Relatório')}
                                                    placeholder={t('[selecione...]')}
                                                    optionsList={this.state.reportTypeList}
                                                    handleChange={this.handleChangeText}
                                                />
                                                <Button className='main-icon-button' sx={{ marginTop: '20px !important', margin: 'auto', height: '40px' }} onClick={() => this.setState({ isReportOpen: true })}>
                                                    <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                                </Button>
                                                <MainButton
                                                    {...this.props}
                                                    sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                    onButtonClick={() => this.setState({ isDialogOpen: true })}
                                                    title={t('Gerar Contagem')}
                                                    disabled={this.state.isDisabled}
                                                />
                                            </Box>
                                        )
                                        :
                                        (
                                            <Box sx={{ display: 'grid', justifyContent: 'end', gridTemplateColumns: { md: '100%', lg: '15%' }, margin: '10px 0 20px 0' }}>
                                                <MainButton
                                                    {...this.props}
                                                    sx={{ background: this.props.colors.orangeAccent[400], marginTop: '20px' }}
                                                    onButtonClick={() => this.setState({ isDialogOpen: true })}
                                                    title={t('Gerar Contagem')}
                                                    disabled={this.state.isDisabled}
                                                />
                                            </Box>
                                        )
                                    }
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(Count);


const right = keyframes` from {right: -100%; } to {right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}