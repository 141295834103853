import React from "react";

import { Box } from "@mui/material";


class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        height: '20vh',
                        width: '20vw',
                        backgroundColor: 'white',
                        borderRadius: '10px'
                    }}
                >
                </Box>
            </Box>
        )
    }
}

export default Dashboard