import React from 'react';

import { Box, Typography, Button } from "@mui/material";
import { withTranslation } from 'react-i18next';

// icons
import avatar from '../../data/avatar.png';
import DeleteIcon from '@mui/icons-material/Delete';


class MainImageUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            image: null,
            imageBase64: props.defaultImageBase64 || ''
        }
    }

    componentDidMount() {
        if (this.props.defaultImageBase64) {
            this.setState({ image: `data:image/jpeg;base64,${this.props.defaultImageBase64}` })
        }
    }

    handleImageChange = (event) => {
        const reader = new FileReader()
        const file = event.target.files[0]

        reader.onloadend = () => {
            const hex = reader.result.toString().split(',')[1]
            this.setState({
                image: reader.result,
                imageBase64: hex
            }, () => {
                if (this.state.image !== avatar) {
                    this.props.onImageChange(this.state.imageBase64)
                } else {
                    this.props.onImageChange('')
                }
            })
        }
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    handleDeleteImage = () => {
        this.setState({ image: null, imageBase64: '' }, () => {
            this.props.onImageChange(this.state.imageBase64)
        })
    }

    render() {
        const { t } = this.props

        return (
            <Box className="container">
                <Box className="innerContainer">
                    <img src={this.state.image ?? avatar} alt="Uploaded" className="image" />
                    <Typography className="typography" sx={{paddingTop: '10px'}}>
                        {t('Mínimo 200px por 200px, nos')}<br /> {' ' + t('formatos .png e .jpg')}
                    </Typography>
                </Box>

                <Box className="buttonRow">
                    <Button onClick={this.handleDeleteImage}>
                        <DeleteIcon sx={{fontSize: '25px'}} className="deleteIcon"/>
                    </Button>

                    <label style={{ cursor: 'pointer' }} htmlFor="fileInput" className='photoLabel'>
                        <Button className="photoButton">
                            {t('Trocar Foto')}
                        </Button>
                    </label>

                    <input
                        type="file"
                        id="fileInput"
                        onChange={this.handleImageChange}
                        style={{ display: 'none' }}
                    />
                </Box>
            </Box>
        );
    }
}

export default withTranslation()(MainImageUpload);