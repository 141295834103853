import React from 'react';
import { TabsContext } from './TabsContext'; // Importe seu contexto de abas

import Dashboard from './scenes/default/Dashboard';
import EntryScheduling from './scenes/entryScheduling/EntryScheduling';
import PortionOrder from './scenes/portioning/PortionOrder';
import Supplies from './scenes/supplies/Supplies';


class TabContent extends React.Component {
    static contextType = TabsContext;

    render() {
        const { tabs, activeTab } = this.context

        const components = {
            '0': { component: <Dashboard {...this.props} /> },
            '1': { component: <EntryScheduling {...this.props} /> },
            '2': { component: <Supplies {...this.props} /> },
            '3': { component: <PortionOrder {...this.props} /> },
        }

        return (
            <>
                <div className="tab-contents">
                    {tabs.map(tab => (
                        <div
                            key={tab.key}
                            style={{ display: tab.key === activeTab ? 'block' : 'none' }}
                        >
                            {components[tab.key].component}
                        </div>
                    ))}
                </div>
            </>
        )
    }
}

export default TabContent