import React from "react";

import i18n from "../../i18n";
import MainButton from "../../components/inputs/MainButton";
import MainTextField from "../../components/inputs/MainTextField";
import SnackbarAlert from "../../components/alerts/SnackbarAlert";

import { Box, Button, Typography } from "@mui/material";
import { defaultRequest } from "../../utils/request/request";
import { getSettings } from "../../utils/request/requestSelects";

import backgroundImg from "../../data/login/login_background.png";
import logo from "../../data/login/everlojas.png";
import mini_logo from "../../data/login/af_acom_logo_versao_principal.png";


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: '',
            username: '',
            password: '',

            isDisabled: false,

            recoverPassword: false
        }
    }

    handleChangeRecoverPassword = () => {
        this.setState({
            recoverPassword: false,
            alertMessage: 'E-mail para recuperação enviado com sucesso',
            alertType: 'success',
            showAlert: true,
        })
    }

    handleChangeText = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            this.login()
        }
    }

    login = async () => {
        this.setState({ isDisabled: true })
        if (!this.state.entity || !this.state.username || !this.state.password) {
            this.setState({
                alertMessage: 'Preencha todos os dados do Login',
                alertType: 'error',
                showAlert: true,

                isDisabled: false
            })
            return
        }

        let config = {
            endpoint: 'ads/usuario/login/',
            method: 'get'
        }
        let form = {
            senha: this.state.password,
            'x-Entidade': this.state.entity,
            login: this.state.username
        }

        const r = await defaultRequest(config, form)

        if (r.status && (r.data.cd_empresa || r.data.empresas)) {
            let configs
            try {
                configs = await getSettings(this.state.entity)
            } catch (error) {
                this.setState({
                    alertMessage: 'Falha no login. Não foi possível obter as configurações.',
                    alertType: 'error',
                    showAlert: true,

                    isDisabled: false,
                })
                return  
            }

            const data = {
                'entidade': this.state.entity,
                'login': this.state.username,
                'data': r.data,
                'configs': configs
            }

            try {
                await this.props.getUserImg(data)
            } catch (error) {
                console.error("Erro ao buscar imagem do usuário:", error)   // Caso ocorra erro, mostra um log no console, mas prossegue com o login
            }

            this.props.loggedIn(data)   // Independente do resultado de `getUserImg`, executa `logIn`
            i18n.changeLanguage(r.data.cd_idioma)   // seta a linguagem do sistema
        } else {
            this.setState({
                alertMessage: 'Falha no login. Por favor, confira seus dados.',
                alertType: 'error',
                showAlert: true,

                isDisabled: false,
                isLoadingTable: false,
            })
        }
    }


    render() {
        return (
            <>
                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}
                <Box
                    display='grid'
                    height='100vh'
                    width='100vw'
                    sx={{
                        backgroundImage: `url(${backgroundImg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        backgroundRepeat: 'no-repeat'
                    }}
                >

                    <Box sx={{ margin: 'auto' }}>

                        <Box
                            id='login-box'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',

                                backgroundColor: this.props.colors.custom['white'],
                                borderRadius: '20px',
                                padding: '30px 40px 10px 40px'
                            }}
                        >

                            <Box component="img" src={logo} sx={{
                                width: '80%',
                                height: 'auto',
                                margin: 'auto',
                                marginBottom: '15px',
                                objectFit: 'contain',
                                backgroundPosition: 'top center',
                            }} />

                            {!this.state.recoverPassword ?
                                (
                                    <>
                                        <Typography sx={{ fontSize: '22px', fontWeight: 'bold', color: this.props.colors.blueAccent[100], margin: '0 auto' }}>EverLoja</Typography>
                                        <Typography sx={{ fontSize: '15px', fontWeight: '500', color: this.props.colors.blueAccent[100], margin: '0 auto' }}>Gestão de Loja em um só Lugar</Typography>

                                        <Typography sx={{ fontSize: '13px', fontWeight: '200', color: this.props.colors.grey[800], margin: '20px auto 0 auto' }}>Utilize o mesmo acesso usado no ERP EVEREST</Typography>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                            <MainTextField
                                                {...this.props}
                                                id='entity'
                                                value={this.state.entity || ''}
                                                placeholder='Entidade...'
                                                handleChange={this.handleChangeText}
                                                onKeyUp={this.handleKeyUp}
                                            />
                                            <MainTextField
                                                {...this.props}
                                                id='username'
                                                value={this.state.username || ''}
                                                placeholder='Usuário...'
                                                handleChange={this.handleChangeText}
                                                onKeyUp={this.handleKeyUp}
                                            />
                                            <Box>
                                                <MainTextField
                                                    {...this.props}
                                                    id='password'
                                                    value={this.state.password || ''}
                                                    placeholder='Senha...'
                                                    handleChange={this.handleChangeText}
                                                    onKeyUp={this.handleKeyUp}
                                                    type='password'
                                                />
                                                <Button onClick={() => this.setState({ recoverPassword: true })} sx={{ fontSize: '11px', textDecoration: 'underline', color: this.props.colors.blueAccent[100], marginBottom: '10px', width: '300px' }}>Recuperar Senha</Button>
                                            </Box>
                                        </Box>

                                        <MainButton title='Acessar' onButtonClick={this.login} sx={{ background: this.props.colors.blueAccent[100] }} disabled={this.state.isDisabled}/>

                                    </>
                                )
                                : (
                                    <RecoverPassword {...this.props} onChangeRecoverPassword={this.handleChangeRecoverPassword} returnToLogin={() => this.setState({ recoverPassword: false })} />
                                )}

                            <Typography sx={{ fontSize: '13px', margin: '10px auto', textDecoration: 'underline' }}>v 3.00</Typography>
                            <Typography sx={{ fontSize: '10px', margin: '0 auto' }}>Desenvolvido por</Typography>
                            <img src={mini_logo} style={{height: '24px', width: '80px',  margin: '0 auto'}} />

                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
}

export default Login


class RecoverPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: '',
            email: '',

            recoverPassword: false
        }
    }

    handleChangeText = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    recoverPassword = () => {
        if (!this.state.entity ||
            !this.state.email
        ) {
            this.setState({
                alertMessage: 'Preencha todos os dados',
                alertType: 'error',
                showAlert: true
            })
            return
        }
        let config = {
            endpoint: 'ads/usuario/senha/recuperar',
            method: 'get'
        }
        let form = {
            email: this.state.email,
            'x-Entidade': this.state.entity,
        }
        defaultRequest(config, form).then((r) => {
            if (r.data?.mensagem) {
                this.props.onChangeRecoverPassword()
            } else {
                this.setState({
                    alertMessage: 'Falha ao enviar e-mail de recuperação',
                    alertType: 'error',
                    showAlert: true,
                })
            }
        })
    }


    render() {
        return (
            <>
                {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: this.props.colors.blueAccent[100], margin: 'auto' }}>Recuperar Senha</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px' }}>
                    <MainTextField
                        {...this.props}
                        id='entity'
                        value={this.state.entity || ''}
                        placeholder='Insira a entidade...'
                        handleChange={this.handleChangeText}
                    />
                    <MainTextField
                        {...this.props}
                        sx={{ width: '300px' }}
                        id='email'
                        value={this.state.email || ''}
                        placeholder='Insira o e-mail cadastrado...'
                        handleChange={this.handleChangeText}
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }} >
                        <Button
                            onClick={this.props.returnToLogin}
                            sx={{
                                border: `1px solid ${this.props.colors.grey[800]}`,
                                color: this.props.colors.grey[500],
                                borderRadius: '20px',
                                backgroundColor: 'transparent',
                                textTransform: 'none',
                                ':hover': { backgroundColor: this.props.colors.primary[400] }
                            }}
                        >
                            Login
                        </Button>

                        <Button
                            onClick={this.recoverPassword}
                            sx={{
                                color: this.props.colors.custom['white'],
                                borderRadius: '20px',
                                textTransform: 'none',
                                backgroundColor: this.props.colors.blueAccent[100],
                                ':hover': { backgroundColor: this.props.colors.blueAccent[200] }
                            }}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box >
            </>
        )
    }

}