import React, { Component } from "react";

import EditableTable from "../../../components/tables/EditableTable";
import LoadingGif from "../../../components/visual/LoadingGif";
import MainDateTimeInput from "../../../components/inputs/MainDateTimeInput";
import MainSelectField from "../../../components/inputs/MainSelectField";
import MainTextField from "../../../components/inputs/MainTextField";

import { Box, Button, Divider, Typography, keyframes } from "@mui/material";
import { defaultRequest } from "../../../utils/request/request";
import { withTranslation } from "react-i18next";

const right = keyframes` from {right: -100%; } to { right: 0; }`

const styles = {
    outerBox: {
        background: '#00000080',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '1000'
    },
    middleBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    innerBox: (props, right) => ({
        background: props.colors.custom['white'],
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '5px 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        right: '0',
        left: '0',
        margin: 'auto',
        animation: `${right} 0.4s`,
    })
}


class PedidoCompra extends React.Component {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            screenId: 'ProgramacaoEntrada_PedidoCompra',
            isLoading: true,
            isLoadingTable: true,

            tableList: [],
            tableColumns: [
                ['id_pedidocompraitem', t('Item')],
                ['ds_item', t('Descrição')],
                ['ds_embalagem', t('Embalagem')],
                ['sg_unidademedida', t('UM')],
                ['qt_item', t('Q. na Embalagem')],
                ['vl_unitario', t('V. Unitário')],
                ['qt_pedido', t('Q. Pedido')],
                ['qt_atendido', t('Q. Atendido')],
                ['vl_total', t('V. Total')],
            ],
            tableTotalSize: '',
        }
    }

    componentDidMount() {
        this.onPedidoCompraTableChange()
    }

    getSituation = () => {
        let config = {
            endpoint: `gco/atributo/${this.state.data.at_situacao}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const language = this.props.user.data.cd_idioma.toLowerCase()
                this.setState({
                    status: r.data[language],

                    isLoading: false,
                    isLoadingTable: false,
                })
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTable: false,
                })
            }
        })
    }

    onPedidoCompraTableChange = (page = 1) => {
        let config = {
            endpoint: `adc/pedidocompra/${this.props.lineId}`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': page,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const company = this.props.user?.data?.empresas.find(item => item.cd_empresa = r.data.cd_empresa).fantasia

                this.setState({
                    data: r.data,
                    tableList: r.data.itens,
                    tableTotalSize: r.data.itens.length,

                    company: company
                }, () => this.getSituation())
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingTable: false,
                })
            }
        })
    }

    // Necessário adicionar position: 'relative' na Box por fora fo  filtro
    render() {
        const { t } = this.props

        return (
            <Box sx={styles.outerBox}>
                <Box sx={styles.middleBox}>
                    <Box sx={styles.innerBox(this.props, right)}>

                        {this.state.isLoading ?
                            <LoadingGif />
                            :
                            <>

                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' } }}>
                                    <Typography sx={{ color: this.props.colors.blueAccent[100], fontWeight: '600', fontSize: '16px', whiteSpace: 'nowrap' }}>{t('Pedido de Compra:')}</Typography>

                                    <Divider sx={{ flexGrow: 1, margin: '0 10px' }} />

                                    <Button sx={{ minWidth: '0', borderRadius: '50%', margin: '0' }} onClick={() => this.props.toggleVisiblePage('isCOMPRAVisible')} >
                                        <i style={{ fontSize: '20px', margin: '3px', color: this.props.colors.orangeAccent[400] }} className="fa-solid fa-xmark"></i>
                                    </Button>
                                </Box>

                                <Box sx={{ margin: { xs: '10px 0', lg: '10px 40px 0 0' } }}>
                                    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: { md: '100%', lg: '8% 21% 25% 13% 13% 12% 3%' }, marginRight: { md: '0', lg: '60px' } }}>
                                        <MainTextField {...this.props} id='nr_pedidocompra' value={this.state.data.nr_pedidocompra} label={t('Pedido')} placeholder={t('[000]')} type='number' disabled={true} />
                                        <MainTextField {...this.props} id='cd_empresa' value={this.state.company} label={t('Empresa')} placeholder={t('[digite...]')} disabled={true} />
                                        <MainTextField {...this.props} id='razao' value={this.state.data.razao} label={t('Fornecedor')} placeholder={t('[digite...]')} disabled={true} />
                                        <MainDateTimeInput {...this.props} id='dt_pedido' value={this.state.data.dt_pedido} label={t('D. Emissão')} placeholder={t('[00/00/0000]')} type='date' disabled={true} />
                                        <MainDateTimeInput {...this.props} id='dt_entrega' value={this.state.data.dt_entrega} label={t('D. Entrega')} placeholder={t('[00/00/0000]')} type='date' disabled={true} />
                                        <MainTextField {...this.props} id='status' value={this.state.status} label={t('Situação')} placeholder={t('[digite...]')} disabled={true} />

                                        <Button className='main-icon-button' sx={{ marginTop: '18px' }} onClick={() => console.log('imprimir')}>
                                            <i style={{ margin: '5px', fontSize: '20px' }} className="fa-solid fa-print"></i>
                                        </Button>
                                    </Box>

                                    <EditableTable
                                        {...this.props}
                                        id='id_pedidocompraitem'
                                        height='40vh'
                                        data={this.state.tableList}
                                        columns={this.state.tableColumns}
                                        rowId='id_pedidocompraitem'
                                        totalSize={this.state.tableTotalSize}
                                        onPageChange={this.onPedidoCompraTableChange}
                                        onRowDoubleClick={() => { }}
                                        isLoading={this.state.isLoadingTable}
                                        extraColumnsConfig={
                                            {
                                                'qt_item': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                                'vl_unitario': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                    'prefix': 'R$'
                                                },
                                                'vl_total': {
                                                    'type': 'number',
                                                    'maxDecimals': '2',
                                                    'prefix': 'R$'
                                                },
                                                'qt_pedido': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                                'qt_atendido': {
                                                    'type': 'number',
                                                    'maxDecimals': '4',
                                                },
                                            }
                                        }
                                    />

                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: '20px' }}>
                                        <MainTextField {...this.props} id='observacao' value={this.state.data.observacao} label={t('Observação')} placeholder={t('[observação]')} multiline={true} minRows='3' disabled={true} />
                                    </Box>
                                </Box>

                            </>
                        }

                    </Box>
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(PedidoCompra);