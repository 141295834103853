import React from "react";

import LoadingGif from "../../../../components/visual/LoadingGif";
import MainSelectField from "../../../../components/inputs/MainSelectField";
import MainDateTimeInput from "../../../../components/inputs/MainDateTimeInput";
import MainTextField from "../../../../components/inputs/MainTextField";
import MainButton from "../../../../components/inputs/MainButton";
import SnackbarAlert from "../../../../components/alerts/SnackbarAlert";

// icons
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { Box, Divider, Grid, keyframes, Typography } from "@mui/material";
import { defaultRequest } from "../../../../utils/request/request";
import { getDeposit } from "../../../../utils/request/requestSelects";


class EventPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isSendingBarcode: false,

            barcode: '',
            event: '',
            eventList: [],
            data: {},
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.event && prevProps.event !== this.props.event) {
            this.fetchEventRFP()
        }

        if (prevProps.event !== this.props.event && !this.props.event) {
            this.props.setList('eventRFPList', [])
        }

        if (prevState.barcode !== this.state.barcode && !this.state.barcode) {
            this.setState({
                data: {
                    cd_agencia: '',
                    nm_banco: '',
                    cd_banco: '',
                    nr_agencia: '',
                    nr_conta: '',
                    vl_documento: '',
                    dt_vencimento: '',
                }
            })
        }
    }

    convertBarcode = () => {
        this.setState({ isSendingBarcode: true })

        if (!this.state.barcode) {
            this.setState({
                isSendingBarcode: false,
            })
            return
        }

        let config = {
            endpoint: `sis/convertercodigobarras`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'str_linha_entrada': this.state.barcode
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                const formatedData = {
                    ...r.data,
                    vl_documento: r.data.vl_documento.toFixed(4),
                }
                this.setState({
                    data: formatedData,
                    isSendingBarcode: false,
                })
            } else {
                this.setState({
                    isSendingBarcode: false,
                })
            }
        })
    }

    fetchData = async () => {
        try {
            const eventData = this.fetchEvent()

            const [eventList] = await Promise.all([eventData])

            this.setState({               
                eventList: Array.isArray(eventList) ? eventList : [],
                isLoading: false
            }, () => this.props.setList('deposit',this.props.depositList[0].cd_deposito))
        } catch (error) {
            console.error('Erro ao retornar data:', error)
            this.setState({
                eventList: [],
                isLoading: false
            })
        }
    }

    fetchEvent = async () => {
        let config = {
            endpoint: `componente/proposta`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'cd_empresa': this.props.data.cd_empresa,
            'at_situacao_lista': 891,
        }
        try {
            const r = await defaultRequest(config, form)
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                return r.data
            } else {
                return []
            }
        } catch (error) {
            console.error('Erro ao retornar o evento:', error)
            return []
        }
    }

    fetchEventRFP = () => {
        let config = {
            endpoint: `componente/propostarfp`,
            method: 'get'
        }
        let form = {
            'x-Entidade': this.props.user.entidade,
            'x-Pagina': 1,
            'id_proposta': this.props.event,
        }
        defaultRequest(config, form).then((r) => {
            if (r.status && r.data && Object.keys(r.data).length > 0) {
                this.props.setList('eventRFPList', r.data)
            } else {
                this.props.setList('eventRFPList', [])
            }
        })
    }

    handleChangeText = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleEnterKeyUp = (event) => {
        if (event.key === "Enter") {
            this.convertBarcode()
        }
    }

    sendBarcode = () => {
        const { t } = this.props

        this.setState({ isLoading: true })

        let config = {
            endpoint: `everlojas/danferecebidadocumentocodigobarras?x-Entidade=${this.props.user.entidade}`,
            method: 'post'
        }
        let form = {
            'id_danferecebida': this.props.data.id_danferecebida,
            'cd_codigo_barra': this.state.data.cd_codigo_barra,
            'cd_linha_digitavel': this.state.barcode,
            'cd_banco': this.state.data.cd_banco,
            'nr_agencia': this.state.data.cd_agencia,
            'nr_conta': this.state.data.nr_conta,
            'cd_carteira': this.state.data.cd_carteira,
            'vl_documento': this.state.data.vl_documento,
            'fator_vencimento': this.state.data.fator_vencimento,
            'dt_vencimento': this.state.data.dt_vencimento_ret,
            'dg_verificador': this.state.data.dg_verificador,
            'nosso_numero': this.state.data.nosso_numero,
            'cd_moeda': this.state.data.cd_moeda,
            'campo_livre': this.state.data.campo_livre
        }
        defaultRequest(config, form).then((r) => {
            if (r.status) {
                return true
            } else {
                const cleanMessage = (typeof r.data === 'string') ? r.data.replace(/<\/?[^>]+(>|$)/g, "") : t("Erro ao executar a operação.")
                this.showAlert(cleanMessage, 'error')
                this.setState({ isLoading: false })
                return false
            }
        })
    }

    showAlert = (message, type) => {
        const { t } = this.props

        this.setState({
            alertMessage: t(message),
            alertType: type,
            showAlert: true,
        })
    }

    verifyRequest = async () => {
        if (!this.props.deposit) {
            this.showAlert('Preencha todos os campos obrigatórios (*)', 'error')
            return
        }

        if (this.props.event && !this.props.eventRFP) {
            this.showAlert('Ao informar o Evento, é necessário informar o campo RFP Evento.', 'error')
            return
        }

        const isDataEmpty = Object.values(this.state.data).every(
            (value) => value === '' || value === null
        )

        try {
            if (this.state.barcode && !isDataEmpty) {
                const barcodeResult = await this.sendBarcode()
                if (!barcodeResult) {
                    return
                }
            }

            if (this.props.event) {
                const stockMovementResult = await this.props.sendStockMovement()
                if (!stockMovementResult.success) {
                    this.showAlert(stockMovementResult.message, 'error')
                    return
                }

                await this.props.closePopup()
                await this.props.sendDanfe()
            } else {
                await this.props.closePopup()
                await this.props.sendDanfe()
            }

        } catch (error) {
            this.showAlert('Algo deu errado', 'error')
            this.setState({ isLoading: false })
        }
    }


    render() {
        const { t } = this.props

        return (
            <Box style={styles.popUpOverlay}>
                <Box style={styles.popUpContainer(this.props, this.state)}>

                    {this.state.showAlert ? <SnackbarAlert {...this.props} alertType={this.state.alertType} open={true} message={this.state.alertMessage} onClose={() => this.setState({ showAlert: false, alertMessage: '' })} /> : <></>}

                    {this.state.isLoading ?
                        <LoadingGif />
                        :
                        <>
                            <Divider sx={{ flexGrow: 1, borderStyle: 'dashed', borderWidth: '1px' }} />

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Recebimento de DANFE</Typography>
                                <Typography sx={{ fontSize: '14px' }}>Será registrado entrada dessa mercadoria no depósito informado.</Typography>
                            </Box>

                            <Grid container columnSpacing={3} rowSpacing={2} sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                                <Grid item md={12} lg={4}><MainSelectField {...this.props} id='deposit' value={this.props.deposit} label={t('Depósito')} placeholder={t('[selecione...]')} optionsList={this.props.depositList.map(item => ({ label: item.ds_deposito, value: item.cd_deposito }))} handleChange={this.props.handleChangeText} required={true} /></Grid>
                                <Grid item md={12} lg={4}><MainSelectField {...this.props} id='event' value={this.props.event} label={t('Evento')} placeholder={t('[selecione...]')} optionsList={this.state.eventList.map(item => ({ label: item.ds_proposta, value: item.id_proposta }))} handleChange={this.props.handleChangeText} disabled={this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false} /></Grid>
                                <Grid item md={12} lg={4}><MainSelectField {...this.props} id='eventRFP' value={this.props.eventRFP} label={t('RFP Evento')} placeholder={t('[selecione...]')} optionsList={this.props.eventRFPList.map(item => ({ label: `${item.nr_propostarfp} - ${item.ds_rfpatividade} - ${item.ds_rfptipo}`, value: item.nr_propostarfp }))} handleChange={this.props.handleChangeText} disabled={this.props.user.configs[0].fl_utiliza_gestao_eventos === "N" ? true : false} /></Grid>
                            </Grid>

                            <Box sx={{ display: 'flex', position: 'relative', marginTop: '20px' }}>
                                <MainTextField {...this.props} id='barcode' value={this.state.barcode} label={t('Código de Barras ou Linha Digitável')} placeholder={t('[digite...]')} handleChange={this.handleChangeText} onKeyUp={this.handleEnterKeyUp} />
                                {this.state.isSendingBarcode
                                    ? <Box component="i" className="fa-solid fa-spinner" sx={{ fontSize: '22px', position: 'absolute', right: '10px', top: '30px', animation: `${spin} 2s linear infinite` }} />
                                    : <KeyboardReturnIcon onClick={this.convertBarcode} style={{ fontSize: '30px', position: 'absolute', margin: 'auto', right: '10px', top: '25px', color: this.props.colors.grey[500] }} />
                                }
                            </Box>

                            <Box sx={{ display: 'grid', justifyContent: 'space-between', gap: '10px', gridTemplateColumns: { md: '1fr', lg: '1fr 2fr 1fr 1fr 1fr 1fr' }, marginTop: '20px' }} >
                                <MainTextField {...this.props} id='cd_banco' value={this.state.data.cd_banco} label={t('Banco')} placeholder={t('[000000]')} disabled={true} />
                                <MainTextField {...this.props} id='nm_banco' value={this.state.data.nm_banco} label={t('Descrição')} placeholder={t('[digite...]')} disabled={true} />
                                <MainTextField {...this.props} id='cd_agencia' value={this.state.data.cd_agencia} label={t('Agência')} placeholder={t('[000000]')} disabled={true} />
                                <MainTextField {...this.props} id='nr_conta' value={this.state.data.nr_conta} label={t('Conta')} placeholder={t('[000000]')} disabled={true} />
                                <MainTextField {...this.props} id='vl_documento' value={this.state.data.vl_documento} label={t('Valor Boleto')} placeholder={t('[000000]')} type='number' maxDecimal={4} disabled={true} />
                                <MainDateTimeInput {...this.props} id='dueDate' value={this.state.data.dt_vencimento_ret} label={t('D. Vencimento')} placeholder={t('[00/00/0000]')} type='date' disabled={true} />
                            </Box>


                            <Box sx={{ margin: '20px 0', display: 'flex', gap: '10px', justifyContent: 'end' }}>
                                <MainButton {...this.props} onButtonClick={this.props.closePopup} title={t('Voltar')} sx={{ border: `1px solid ${this.props.colors.grey[500]}`, textColor: this.props.colors.grey[500], width: '150px' }} />
                                <MainButton {...this.props} onButtonClick={this.verifyRequest} title={t('Confirmar')} sx={{ background: this.props.colors.greenAccent[200], width: '150px' }} />
                            </Box>

                            <Divider sx={{ flexGrow: 1, borderStyle: 'dashed', borderWidth: '1px' }} />
                        </>
                    }

                </Box>
            </Box>
        )
    }
}

export default EventPopup;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const styles = {
    popUpOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popUpContainer: (props, state) => ({
        backgroundColor: 'white',
        borderRadius: '10px',
        border: `1px solid ${props.colors.orangeAccent[400]}`,
        padding: '20px',
        width: '65%',
        height: state.isLoading ? '45vh' : '',
        maxHeight: '90vh',
        overflowY: 'auto',
    }),
}